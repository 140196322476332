import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../Avatar/styles.css";
import Modal from "react-modal";
import Tabs from "../Tab/Tabs";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import { updateSelectedStudentsPoints } from "../../features/students/studentsThunks";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editableToast, correctToast, wrongToast } from "../ToastUtils";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

//Add sounds when points interaction outside component to render it only once
const audioCorrect = new Audio("./audio/correct.wav");
const audioFail = new Audio("./audio/fail.mp3");

function MultiSelectPopup({ isOpen, onClose, students, onStudentsUpdate }) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isPointsButtonDisabled, setIsPointsButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const arrayPositives = useSelector((state) => state.behaviours.positives);
  const arrayNegatives = useSelector((state) => state.behaviours.negatives);

  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [customData, setCustomData] = useState({ text: "", points: 0 });

  const handleCustomTextChange = (e) => {
    setCustomData({ ...customData, text: e.target.value });
  };

  const handleCustomPointsChange = (e) => {
    setCustomData({ ...customData, points: parseInt(e.target.value, 10) });
  };

  const openCustomModal = () => setIsCustomModalOpen(true);
  const closeCustomModal = () => setIsCustomModalOpen(false);

  const { t } = useTranslation();

  //update selected students points
  async function updatePoints(pointsToAdd, actionName) {
    setIsLoading(true);

    dispatch(
      updateSelectedStudentsPoints(
        userEmail,
        selectedStudents,
        pointsToAdd,
        actionName
      )
    );

    setIsLoading(false);
  }

  const updatePointsButtonStatus = () => {
    setIsPointsButtonDisabled(selectedStudents.length === 0);
  };
  useEffect(() => {
    updatePointsButtonStatus();
  }, [selectedStudents]);

  useEffect(() => {
    updatePointsButtonStatus();
  }, []);

  const toggleStudentSelection = (student) => {
    if (selectedStudents.some((s) => s.id === student.id)) {
      setSelectedStudents((prev) => prev.filter((s) => s !== student));
    } else {
      setSelectedStudents((prev) => [...prev, student]);
    }
  };

  const handleOpenPointsModal = () => {
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function openModal() {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleAllStudents = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students);
    }
  };

  const correct = () => {
    audioCorrect.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const fail = () => {
    audioFail.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const handleCustomPositive = (e) => {
    e.preventDefault();
    correct();
    editableToast("👍" + customData.text);
    updatePoints(customData.points, customData.text);
    closeCustomModal();
  };

  const handleCustomNegative = (e) => {
    e.preventDefault();
    fail();
    editableToast("👎" + customData.text);
    updatePoints(-customData.points, customData.text);
    closeCustomModal();
  };

  return (
    <>
      <div className={`popupMS ${isOpen ? "open" : ""}`}>
        <button
          className="close"
          onClick={() => {
            setSelectedStudents([]);
            onClose();
          }}
        >
          {t("close")}
        </button>
        <button onClick={toggleAllStudents}>
          {selectedStudents.length === students.length
            ? t("disSelectAll")
            : t("selectAll")}
        </button>
        <ul>
          {students.map((student) => {
            if (student.id === 0) return null;

            return (
              <li key={student.id} className="student-card">
                <label className="student-checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedStudents.some((s) => s.id === student.id)}
                    onChange={() => toggleStudentSelection(student)}
                    className="hidden-checkbox"
                  />
                  <div
                    className={`student-info ${
                      selectedStudents.some((s) => s.id === student.id)
                        ? "selected"
                        : ""
                    }`}
                  >
                    <span className="student-name">{student.name}</span>
                    <span className="student-surname">{student.surname}</span>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>

        <button
          className="points"
          onClick={openModal}
          disabled={isPointsButtonDisabled}
        >
          {t("points")}
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
      >
        <div className="container">
          <Tabs>
            <div label={`👍 ${t("positives")}`}>
              <ul>
                {arrayPositives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() => {
                        updatePoints(data.points, data.name);
                        correct();
                        editableToast("👍" + data.name);
                      }}
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        <span
                          className="simpleCircle"
                          style={{ backgroundColor: "green" }}
                        >
                          {" "}
                          +{data.points}
                        </span>
                      )}
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "green" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomPositive}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("positives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div label={`👎 ${t("negatives")}`}>
              <ul>
                {arrayNegatives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() => {
                        updatePoints(-data.points, data.name);
                        fail();
                        editableToast("👎" + data.name);
                      }}
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        <span
                          className="simpleCircle"
                          style={{ backgroundColor: "red" }}
                        >
                          {" "}
                          -{data.points}
                        </span>
                      )}
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "red" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomNegative}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("negatives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </Tabs>
        </div>
        <button className="closeButton" onClick={closeModal}>
          {t("close")}
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default MultiSelectPopup;
