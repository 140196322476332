import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";
import { useNavigate } from "react-router-dom";

import firebaseApp from "../credentials";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setStudents } from "../features/students/studentsSlice";
import { setGroups } from "../features/groups/groupsSlice";

const firestore = getFirestore(firebaseApp);

const EditClassroom = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  useEffect(() => {
    async function fetchStudents() {
      const fetchedStudents = students;
    }
    fetchStudents();
  }, [dispatch]);

  async function onDelete(idToBeDeleted) {
    if (idToBeDeleted === 0) {
      return;
    }

    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const docSnap = await getDoc(docuRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();

      // Paso 1: Elimina todas las referencias al estudiante que se va a borrar en cada grupo
      const updatedGroups = userData.groups.map((group) => {
        return {
          ...group,
          students: group.students.filter((id) => id !== idToBeDeleted),
        };
      });

      // Actualiza la base de datos para los grupos
      await updateDoc(docuRef, { groups: updatedGroups });

      // Creamos un mapeo para los IDs cambiados
      const idChangesMap = {};

      // Paso 2: Actualiza la lista de estudiantes y sus IDs
      const newArrayStudents = students
        .filter((studentsObject) => studentsObject.id !== idToBeDeleted)
        .map((studentsObject, index) => {
          // Crear el mapeo de ID antiguo a ID nuevo
          idChangesMap[studentsObject.id] = index === 0 ? 0 : index;

          return {
            ...studentsObject,
            id: index === 0 ? 0 : index,
          };
        });

      // Actualiza la base de datos para los estudiantes
      await updateDoc(docuRef, { students: newArrayStudents });

      dispatch(setStudents(newArrayStudents));
      // Paso 3: Actualiza la base de datos para los grupos para reflejar los nuevos IDs
      const reIndexedGroups = updatedGroups.map((group) => {
        return {
          ...group,
          students: group.students.map((studentId) => {
            return idChangesMap.hasOwnProperty(studentId)
              ? idChangesMap[studentId]
              : studentId;
          }),
        };
      });

      // Actualiza la base de datos para los grupos reindexados
      await updateDoc(docuRef, { groups: reIndexedGroups });
      dispatch(setGroups(reIndexedGroups));
    }
  }

  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("studentName")}</th>
              <th style={{ textAlign: "center" }}>{t("studentSurname")}</th>
              <th style={{ textAlign: "center" }}>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(students).map((id, index) => {
              const student = students[id];

              if (student.id === 0) {
                return null; // No render this row
              }

              return (
                <tr key={id}>
                  <th scope="row">{index}</th>
                  <td>{student.name}</td>
                  <td>{student.surname}</td>
                  <td>
                    <Link to={`/update/${index}`}>
                      <button className="btn btn-edit">Editar</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(student.id)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EditClassroom;
