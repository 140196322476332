import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function useRequireAuth(redirectTo = '/game') {
    const userEmail = useSelector((state) => state.auth.userEmail);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userEmail) {
            navigate(redirectTo);
        }
    }, [userEmail, navigate, redirectTo]);
}

export default useRequireAuth;