import { getFirestore, doc, updateDoc } from "firebase/firestore";
import firebaseApp from "../../credentials";

import {
  setPergaminos,
  addPergamino,
  updatePergamino,
  deletePergamino,
  setSentences,
  addSentence,
  updateSentence,
  deleteSentence,
} from "./toolsSlice";

const firestore = getFirestore(firebaseApp);

//Pergamino

export const addPergaminoRedux =
  (userEmail, pergaminoData) => async (dispatch, getState) => {
    const lastPergamino = getState().tools.pergaminos.slice(-1)[0];
    const newPergamino = {
      id: lastPergamino ? lastPergamino.id + 1 : 1,
      ...pergaminoData,
    };

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const currentPergaminos = getState().tools.pergaminos;
    // Añade el nuevo positivo a la lista
    const newPergaminosArray = [...currentPergaminos, newPergamino];

    await updateDoc(docuRef, { pergaminos: newPergaminosArray });

    // Despachar acción para actualizar el estado en el store
    dispatch(addPergamino(newPergamino));
  };

//Delete tools

export const deletePergaminoThunk =
  (userEmail, id, currentPergaminos) => async (dispatch) => {
    const updatedPergaminos = currentPergaminos.filter(
      (pergamino) => pergamino.id !== id
    );
    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { pergaminos: updatedPergaminos });
    // Despachar la acción para actualizar el estado en Redux
    dispatch(deletePergamino(id));
  };

export const deleteAllPergaminosThunk = (userEmail) => async (dispatch) => {
  const defaultPergamino = {
    id: 0,
    text: "Edita",
  };
  // Actualizar la base de datos

  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, {
    pergaminos: [defaultPergamino],
  });

  // Despachar la acción para actualizar el estado en Redux
  dispatch(setPergaminos([defaultPergamino]));
};

//Update tools

export const updatePergaminoThunk =
  (userEmail, index, data) => async (dispatch, getState) => {
    // Actualiza el estado en Redux
    dispatch(updatePergamino({ index, data }));

    // Obtiene el estado actualizado
    const updatedState = getState();

    // Actualiza Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { pergaminos: updatedState.tools.pergaminos });
  };


  // Daily Sentences
  //Add Tools

export const addSentenceThunk =
(userEmail, dailySentenceData) => async (dispatch, getState) => {
  const lastDailySentences = getState().tools.dailySentences.slice(-1)[0];
  const newDailySentences = {
    id: lastDailySentences ? lastDailySentences.id + 1 : 1,
    ...dailySentenceData,
  };

  // Actualizar la base de datos
  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  const currentDailySentences = getState().tools.dailySentences;
  // Añade el nuevo positivo a la lista
  const newDailySentencesArray = [...currentDailySentences, newDailySentences];

  await updateDoc(docuRef, { dailySentences: newDailySentencesArray });

  // Despachar acción para actualizar el estado en el store
  dispatch(addSentence(newDailySentences));
};

//Delete tools

export const deleteSentenceThunk =
(userEmail, id, currentDailySentences) => async (dispatch) => {
  const updatedDailySentences = currentDailySentences.filter(
    (dailySentence) => dailySentence.id !== id
  );
  // Actualizar la base de datos
  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, { dailySentences: updatedDailySentences });
  // Despachar la acción para actualizar el estado en Redux
  dispatch(deleteSentence(id));
};

export const deleteAllSentencesThunk = (userEmail) => async (dispatch) => {
const defaulDailySentence = {
  id: 0,
  text: "Edita",
};
// Actualizar la base de datos

const docuRef = doc(firestore, `usuarios/${userEmail}`);
await updateDoc(docuRef, {
  dailySentences: [defaulDailySentence],
});

// Despachar la acción para actualizar el estado en Redux
dispatch(setSentences([defaulDailySentence]));
};

//Update tools

export const updateSentenceThunk =
(userEmail, index, data) => async (dispatch, getState) => {
  // Actualiza el estado en Redux
  dispatch(updateSentence({ index, data }));

  // Obtiene el estado actualizado
  const updatedState = getState();

  // Actualiza Firebase
  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, { dailySentences: updatedState.tools.dailySentences });
};