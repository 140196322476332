import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import {
  deletePergaminoThunk,
  deleteAllPergaminosThunk,
} from "../features/tools/toolsThunks";

const EditPergaminoList = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const pergaminos = useSelector((state) => state.tools.pergaminos);

  async function onDelete(idToBeDeleted) {
    dispatch(deletePergaminoThunk(userEmail, idToBeDeleted, pergaminos));
  }

  async function deleteAllRules() {
    dispatch(deleteAllPergaminosThunk(userEmail));
  }

  return (
    <>
      <Header />
      <div style={{ marginTop: "50px" }}>
        <h2 className="editTitle">{t("pergam")}</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("pergam")}</th>
              <th style={{ textAlign: "center" }}>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(pergaminos).map((id, index) => {
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{pergaminos[id].text}</td>
                  <td>
                    <Link to={`/update-pergam/${pergaminos[id].id}`}>
                      <button className="btn btn-edit">Editar</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(pergaminos[id].id)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button className="button-error" onClick={deleteAllRules}>
          {t("deleteAll")}
        </button>
        <Link className="btn btn-edit" to="/pergamino">
          {t("goBack")}
        </Link>
      </div>
    </>
  );
};

export default EditPergaminoList;
