import React from "react";
import Header from "../Components/Header/Header";
import StudentGroupHeader from "../Components/StudentGroupHeader";
import BusinessGallery from "./BusinessGallery";

function InfoBusiness() {
  return (
    <div>
      <Header />
      <StudentGroupHeader />
      <BusinessGallery />
    </div>
  );
}

export default InfoBusiness;
