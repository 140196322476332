import React, { useState, useEffect } from "react";
import "./LoginStyles.css";
import { useTranslation } from "react-i18next";
import CookieBanner from "../Components/CookieBanner/CookieBanner";

import firebaseApp from "../credentials";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";

import { useDispatch } from "react-redux";
import { login } from "../features/auth/authSlice";

const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

function Login() {
  const [isRegistering, setisRegistering] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(login(user.email));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  async function submitHandler(e) {
    e.preventDefault();
    const email = e.target.formBasicEmail.value;
    const pass = e.target.formBasicPassword.value;

    if (isRegistering) {
      // Registro de usuario
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          pass
        );
        const user = userCredential.user;

        // Realizar alguna acción después del registro exitoso
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        setShowErrorMessage(errorMessage);
      }
    } else if (showResetPasswordForm) {
      // Envío de correo electrónico de recuperación de contraseña
      handleResetPassword(e);
    } else {
      // Inicio de sesión de usuario
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          pass
        );
        const user = userCredential.user;
        // Realizar alguna acción después del inicio de sesión exitoso
      } catch (error) {
        const errorCode = error.code;
        if (errorCode === "auth/wrong-password") {
          const errorMessage = t("wrongPassword");
          setShowErrorMessage(errorMessage);
        } else if (errorCode === "auth/user-not-found") {
          // Mostrar opción para recuperar contraseña
          setShowResetPasswordForm(true);
        } else {
          const errorMessage = error.message;
          setShowErrorMessage(errorMessage);
        }
      }
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    const email = e.target.resetEmail.value;

    try {
      await sendPasswordResetEmail(auth, email);
      setShowErrorMessage("");
      setShowSuccessMessage(true);
      setShowResetPasswordForm(false);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowErrorMessage(errorMessage);
    }
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const showResetPasswordFormHandler = () => {
    setShowResetPasswordForm(true);
    setShowSuccessMessage(false);
  };

  return (
    <>
      <div className="login-page">
        <h1>{isRegistering ? t("register") : t("login")}</h1>

        <h2>1. {t("languageTitle")}</h2>
        <p>
          <span onClick={() => handleLanguageChange("cat")}>
            <img src="/images/cat.svg" alt="cat" width={32} />
            &nbsp;
          </span>

          <span onClick={() => handleLanguageChange("es")}>
            <img src="/images/es.svg" alt="es" width={32} />
          </span>
        </p>
        <h2>2- {t("fillForm")}</h2>
        {showErrorMessage ? (
          <p className="errorWarning">{showErrorMessage}</p>
        ) : (
          ""
        )}
        {showSuccessMessage && ( // Mostrar mensaje de éxito si es true
          <p className="successMessage">{t("passResetSent")}</p>
        )}
        <div className="form">
          <form className="login-form" onSubmit={submitHandler}>
            <input type="text" placeholder="email" id="formBasicEmail" />
            <input
              type="password"
              placeholder="password"
              id="formBasicPassword"
            />
            <button>{isRegistering ? t("register") : t("login")}</button>
          </form>
          <button
            type="submit"
            onClick={() => signInWithPopup(auth, googleProvider)}
          >
            {t("googleAccess")}
          </button>
          <button
            className="registerBtn"
            onClick={() => setisRegistering(!isRegistering)}
          >
            {isRegistering ? t("haveAccount") : t("notHaveAccount")}
          </button>
          <p style={{fontSize:"13px"}}><i>{isRegistering ? t("contactThroughMail") : "" }</i></p>
          {!isRegistering && !showResetPasswordForm && (
            <a href="#" onClick={showResetPasswordFormHandler}>
              {t("forgotPassword")}
            </a>
          )}
          {showResetPasswordForm && (
            <form
              className="reset-password-form"
              onSubmit={handleResetPassword}
            >
              <input type="text" placeholder="email" id="resetEmail" />
              <button>{t("resetPassword")}</button>
            </form>
          )}
        </div>
      </div>
      <CookieBanner />
    </>
  );
}

export default Login;
