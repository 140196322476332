const starterPergamino = [
  {
    id: 0,
    text: "Els reis de la pista: Tres persones voluntàries surten a ballar una cançó",
  },
  {
    id: 1,
    text: "De Padawan a Jedi: Algú aleatori es prepara una classe de 5 minuts per al proper dia del que vulgui",
  },
  { id: 2, text: "L'hora còmica: qui vulgui pot explicar un acudit" },
  { id: 3, text: "The chair is lava: Tota la classe estarà 30 minuts dempeus" },
  {
    id: 4,
    text: "Dumbledore s’ha emportat totes les llums: Feu una classe a les fosques",
  },
  {
    id: 5,
    text: "Aprendre és tant important com llegir. 30 minuts de classe i la resta, de lectura",
  },
  {
    id: 6,
    text: "Un/a voluntari/a ha de ballar la cançó que algun company/a aleatori hagi escollit",
  },
  {
    id: 7,
    text: "Temps de cinema: Tota la classe haurà de fer un resum/ressenya d’alguna pel·lícula que hagi vist",
  },
  {
    id: 8,
    text: "Ring ring ring, t’ha tocat la bonoloto. Tota la classe guanya 2 punts.",
  },
  {
    id: 9,
    text: "Obrint el bagul dels records… El professor escull una activitat per repassar el que s’ha fet durant el curs",
  },
  {
    id: 10,
    text: "Un huracà ha passat i la veu se’ns ha emportat. Durant la següent hora només podeu parlar amb signes!",
  },
  {
    id: 11,
    text: "Hola, historiadors! Avui heu d’escriure la biografia/descripció d’algú (mínim 5 línies)",
  },
  {
    id: 12,
    text: "Chiquito de la calzada jooooor... algú voluntari ha d’explicar alguna història graciosa/monòleg",
  },
  {
    id: 13,
    text: "La voz kids: La classe cantarà el karaoke que el professor digui",
  },
  {
    id: 14,
    text: "El monstre de les sabates ha passat per la classe. Durant la següent hora, tots sense sabates!",
  },
  {
    id: 15,
    text: "Temps de teatre. Algú aleatori ha de representar amb mímica el títol d'una sèrie o pel·lícula i la classe ho ha d'endevinar",
  },
  {
    id: 16,
    text: "El monstre de les vocals ha vingut. El pròxim cop que es passi llista, no es poden pronunciar les vocals dels noms",
  },
  {
    id: 17,
    text: "Apadrineu un docent. Algú aleatori passa llista en comptes del professor",
  },
  {
    id: 18,
    text: "La informació està en els detalls… Tens 30 segons per dibuixar un objecte petit de la classe",
  },
  {
    id: 19,
    text: "Toca tornar a connectar amb la natura! Tothom 15 minuts assegut a terra",
  },
  {
    id: 20,
    text: "Mala sort! Heu de fer un exercici extra de deures o una divisió",
  },
  {
    id: 21,
    text: "Una aurora boreal ha passat per damunt de l’escola, tots tenim 5 minuts per relaxar-nos i observar-la",
  },
  {
    id: 22,
    text: "Director/a d’orquestra a la classe! Algú aleatori escull una cançó i tota la classe l’ha de cantar o ballar",
  },
  {
    id: 23,
    text: "Deixem-nos portar pels sons. Dibuixem la música que el docent ens posi",
  },
  {
    id: 24,
    text: "Tres persones aleatòries han de sortir a explicar un conte o un acudit",
  },
  {
    id: 25,
    text: "Batedora de llocs: tota la classe es canvia de lloc… ningú pot quedar-se on està!",
  },
  {
    id: 26,
    text: "Ha arribat l’hipnotitzador… algú aleatori haurà d’actuar com una gallina durant la pròxima classe",
  },
  {
    id: 27,
    text: "“Al agua patos”. 2 persones aleatòries poden anar a beure aigua en qualsevol moment durant tot el dia",
  },
  {
    id: 28,
    text: "Virtualitzem-nos! Tota la classe pot dibuixar l’avatar que li agradaria tenir en un videojoc",
  },
  {
    id: 29,
    text: "El primer i últim de la llista trien una cançó per escoltar a classe",
  },
  {
    id: 30,
    text: "Temps era temps… El mestre ha d’explicar alguna anècdota divertida",
  },
  { id: 31, text: "És el teu dia de sort, avui no hi ha deures per ningú!" },
  {
    id: 32,
    text: "Dalí i Picasso et necessiten! Fes un dibuix a la pissarra, després la classe haurà d'endevinar què és",
  },
  {
    id: 33,
    text: "Reptes matemàtics… La primera persona en fer bé les 3 multiplicacions que el docent posarà a la pissarra, pot escollir una cançó",
  },
  {
    id: 34,
    text: "Escape room verbal… Heu de solucionar l’enigma que el docent us proposarà!",
  },
  {
    id: 35,
    text: "Els astres s’han alineat… 5 minuts més de pati per tothom!",
  },
  {
    id: 36,
    text: "La bruixa Maduixa us ha llençat un encanteri! Durant 30 minuts ningú pot separar els llavis!",
  },
  { id: 37, text: "Matemàstic! 5 divisions i/o multiplicacions per a tothom!" },
  {
    id: 38,
    text: "Tempesta de neutrons! Tots a ballar el buggy-buggy durant 20 segons!",
  },
  {
    id: 39,
    text: "Algú ha vist el fantasma de l’òpera.. Demostrem-li què bé sabem cantar la seva cançó!",
  },
  {
    id: 40,
    text: "Payday, lucky day... Algú aleatori obté 10 punts d’experiència!",
  },
  {
    id: 41,
    text: "La protectora d'animals necessita la vostra ajuda! Algú aleatori acaba d'aconseguir una mascota Bits",
  },
  {
    id: 42,
    text: "El rei de la simetria acaba de regalar als nombres parells de la llista 1 punt extra!",
  },
  {
    id: 43,
    text: "La reina de la simetria acaba de regalar als nombres imparells de la llista 1 punt extra!",
  },
  {
    id: 44,
    text: "El monstre de les vocals ha fet de les seves... 2 punts per a tots els que tinguin la vocal A, E o I al seu nom!",
  },
  {
    id: 45,
    text: "Tris, tras, trus. El mag Farlomeu donarà a algú aleatori... 5 punts!",
  },
];
export default starterPergamino;
