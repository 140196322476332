import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import "./HomeStyles.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import firebaseApp from "../credentials";
import { getAuth } from "firebase/auth";

import { logout, login, setOriginalEmail } from "../features/auth/authSlice";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editableToast,
  wrongToast,
  correctToast,
} from "../Components/ToastUtils";

import { clearData } from "../features/students/studentsSlice";

import { useTranslation } from "react-i18next";

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export default function Home() {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const savedClassrooms = useSelector((state) => state.invites.savedClassrooms);

  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const positiveConductImg =
    language === "es"
      ? "../../images/landing_points_es.png"
      : "../../images/landing_points_cat.png";
  const toolsLanding =
    language === "es"
      ? "../../images/landing_tools_es.png"
      : "../../images/landing_tools_cat.png";

  const setupCustom =
    language === "es"
      ? "../../images/landing_setup_es.png"
      : "../../images/landing_setup_cat.png";

  async function accessClass(professorEmail) {
    const userDocRef = doc(db, "usuarios", professorEmail);

    try {
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const professorData = docSnap.data();
        if (professorData.emails && professorData.emails.includes(userEmail)) {
          dispatch(clearData());
          dispatch(logout());
          dispatch(setOriginalEmail(userEmail));
          dispatch(login(professorEmail));
          correctToast(
            i18n.t("grantedAccess", { professorEmail: professorEmail })
          );
        } else {
          wrongToast(
            i18n.t("rejectedAccess", { professorEmail: professorEmail })
          );
        }
      } else {
        // El documento del profesor no existe
        wrongToast(
          i18n.t("rejectedAccess", { professorEmail: professorEmail })
        );
      }
    } catch (error) {
      wrongToast(i18n.t("rejectedAccess", { professorEmail: professorEmail }));
    }
  }

  return (
    <>
      <Header />
      {savedClassrooms ? (
        <div className="classroom-container">
          {savedClassrooms &&
            savedClassrooms.map((classroom) => (
              <div key={classroom.id} className="classroom-card">
                <h3>{classroom.name}</h3>
                <button onClick={() => accessClass(classroom.email)}>
                  {t("access")}
                </button>
              </div>
            ))}
        </div>
      ) : (
        ""
      )}

      <main>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column">
              <h1 className="title">{t("mainTitle")}</h1>
              <p className="subtitle">{t("firstP")}</p>
            </div>
            <div className="column" id="chibiClassroom">
              <div className="upAndDown">
                <img
                  src="../../images/chibi_classroom.png"
                  alt="clase classbits"
                  width="500px"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container-cards">
            <div className="cardDescription">
              <Link to={userEmail ? "/chooseChibit" : "/game"}>
                {t("createChibi")}
                <img
                  src="../../images/chibi_potter.png"
                  alt="chibi potter classbits"
                  className="imgHomeSelectors"
                />
              </Link>
              <div className="steps">1</div>
            </div>
            <div className="cardDescription">
              <Link to="/settings">
                {t("setupClass")}
                <img
                  src="../../images/chibi_ironman.png"
                  alt="iron man classbits"
                  className="imgHomeSelectors"
                />
              </Link>
              <div className="steps">2</div>
            </div>
            <div className="chibi_playing">
              <img
                src="../../images/chibi_playing.png"
                alt="chibi playing classbits"
                height="250"
              />
            </div>
          </div>
        </div>
        <div className="container cta">
          <div className="center">
            <blockquote>{t("quote")}</blockquote>
          </div>
        </div>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column" id="chibi_programmer">
              <div className="upAndDown">
                <img
                  src={positiveConductImg}
                  alt="Sumar puntos por conductas positivas"
                  width="500px"
                />
              </div>
            </div>
            <div className="column">
              <h2 className="titleH2">{t("positiveReinforcementTitle")}</h2>
              <p className="subtitle">{t("positiveReinforcementP")}</p>
            </div>
          </div>
        </div>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column">
              <h2 className="titleH2">{t("toolsT")}</h2>
              <p className="subtitle">{t("toolsP")}</p>
            </div>
            <div className="column" id="chibiCustom">
              <div className="paddingHome">
                <img
                  src={toolsLanding}
                  alt="herramientas classbits"
                  width="500px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column" id="chibi_programmer">
              <div className="upAndDown">
                <img
                  src="../../images/chibi_programmer.png"
                  alt="Programador chibi"
                  width="500px"
                />
              </div>
            </div>
            <div className="column">
              <p className="titleH2">{t("privacyT")}</p>
              <p className="subtitle">{t("privacyP")}</p>
            </div>
          </div>
        </div>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column">
              <p className="titleH2">{t("explanationT")}</p>
              <p className="subtitle">{t("explanationP")}</p>
            </div>
            <div className="column" id="chibiCustom">
              <div className="paddingHome">
                <img src={setupCustom} alt="customable setup" width="500px" />
              </div>
            </div>
          </div>
        </div>
        <div className="paddingHome">
          <div className="row rowHome">
            <div className="column" id="landing_lootboxes">
              <div className="upAndDown">
                <img
                  src="../../images/landing_lootboxes.png"
                  alt="Programador chibi"
                  width="500px"
                />
              </div>
            </div>
            <div className="column">
              <p className="titleH2">{t("attendanceT")}</p>
              <p className="subtitle">{t("attendanceP")}</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
}
