import "./styles.css";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Tabs from "../Tab/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateStudentPoints,
  updateAllStudentPoints,
} from "../../features/students/studentsThunks";

import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  correctToast,
  wrongToast,
  exchangeToast,
  editableToast,
} from "../ToastUtils";
import { Link } from "react-router-dom";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

//Add sounds when points interaction outside component to render it only once
const audioCorrect = new Audio("./audio/correct.wav");
const audioFail = new Audio("./audio/fail.mp3");
const audioExchange = new Audio("./audio/exchange.wav");

function Avatar(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const arrayPositives = useSelector((state) => state.behaviours.positives);
  const arrayNegatives = useSelector((state) => state.behaviours.negatives);
  const arrayExchanges = useSelector((state) => state.behaviours.exchanges);
  const students = useSelector((state) => state.students) || [];

  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [customData, setCustomData] = useState({ text: "", points: 0 });

  const handleCustomTextChange = (e) => {
    setCustomData({ ...customData, text: e.target.value });
  };

  const handleCustomPointsChange = (e) => {
    setCustomData({ ...customData, points: parseInt(e.target.value, 10) });
  };

  const openCustomModal = () => setIsCustomModalOpen(true);
  const closeCustomModal = () => setIsCustomModalOpen(false);

  const handleUpdatePoints = (studentId, count, actionName) => {
    dispatch(updateStudentPoints(userEmail, studentId, count, actionName));
  };

  const [refresh, setRefresh] = useState(0);

  const { t } = useTranslation();

  function refreshPage() {
    setRefresh(refresh + 1);
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    closeCustomModal();
  }

  //Specify that the localstorage key equals the data id
  const key = props.id;

  const [count, setCount] = useState(() => {
    const persistedValue = props.points;
    return persistedValue !== null ? persistedValue : 0;
  });
  useEffect(() => setCount(props.points), [props.points]);

  useEffect(() => {
    handleUpdatePoints();
  }, []);

  const handleUpdateAllPoints = (amount, actionName) => {
    dispatch(updateAllStudentPoints(userEmail, amount, actionName));
  };

  //Functions to play sounds

  const correct = () => {
    audioCorrect.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const fail = () => {
    audioFail.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const exchange = () => {
    audioExchange.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  let totalPoints = students
    .filter((student) => student.id !== 0) // Excluye "Toda la clase"
    .reduce((accumulator, student) => accumulator + student.points, 0);

  const handleCustomPositive = (e) => {
    e.preventDefault();

    if (props.id === 0) {
      handleUpdateAllPoints(customData.points, customData.text);
      setCount(count + customData.points);
      correct();
      editableToast("👍" + customData.text);
    } else {
      setCount(count + customData.points);
      correct();
      editableToast("👍" + customData.text);
      handleUpdatePoints(props.id, customData.points, customData.text);
    }
    closeCustomModal();
  };

  const handleCustomNegative = (e) => {
    e.preventDefault();

    if (props.id === 0) {
      handleUpdateAllPoints(-customData.points, customData.text);
      setCount(count - customData.points);
      fail();
      editableToast("👎" + customData.text);
    } else {
      setCount(count - customData.points);
      fail();
      editableToast("👎" + customData.text);
      handleUpdatePoints(props.id, -customData.points, customData.text);
    }
    closeCustomModal();
  };

  const handleCustomExchange = (e) => {
    e.preventDefault();
    setCount(count - customData.points);
    exchange();
    editableToast("🛒" + customData.text);
    handleUpdatePoints(props.id, -customData.points, customData.text);
    closeCustomModal();
  };

  return (
    <>
      <div className="avatarContainer">
        <li className="avatarPointer" id={props.id} onClick={openModal}>
          <span
            className={
              count > 0
                ? props.id === 0
                  ? "totalCircle"
                  : "circleGreen"
                : count < 0
                ? props.id === 0
                  ? "totalCircle"
                  : "circleRed"
                : props.id === 0
                ? "totalCircle"
                : "circle"
            }
          >
            {props.id === 0 ? totalPoints : ""}
            {isLoading ? (
              <div className="loader"></div>
            ) : props.id === 0 ? (
              ""
            ) : (
              count
            )}
          </span>
          <img src={props.img} className="imgAvatarRender" alt="Avatar" />
          <span className="name">{props.name}</span>
          <span className="surname">{props.surname}</span>
          {props.pet === "" ? (
            ""
          ) : (
            <img className="pet" src={props.pet} alt="pet" />
          )}
        </li>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          {props.name} {props.surname}
        </h2>
        {props.id === 0 ? (
          ""
        ) : (
          <Link className="records-link" to={`/student-profile/${props.id}`}>
            {t("profile")} {props.name}
          </Link>
        )}
        <div className="container">
          <span className={props.id === 0 ? "hidenCircle" : "counter"}>
            {isLoading ? <div className="loader"></div> : count}
          </span>

          <img src={props.img} className="avatarImage" alt="Avatar" />
          {props.pet === "" ? (
            ""
          ) : (
            <img className="petModal" src={props.pet} alt="pet" />
          )}

          <Tabs>
            <div label={`👍 ${t("positives")}`}>
              <ul>
                {arrayPositives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() =>
                        props.id === 0
                          ? (handleUpdateAllPoints(data.points, data.name),
                            setCount(
                              count + data.points,
                              correct(),
                              editableToast("👍" + data.name)
                            ))
                          : (setCount(count + data.points),
                            correct(),
                            editableToast("👍" + data.name),
                            handleUpdatePoints(
                              props.id,
                              data.points,
                              data.name
                            ))
                      }
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        <span
                          className="simpleCircle"
                          style={{ backgroundColor: "green" }}
                        >
                          {" "}
                          +{data.points}
                        </span>
                      )}
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "green" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomPositive}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("positives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div label={`👎 ${t("negatives")}`}>
              <ul>
                {arrayNegatives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() =>
                        props.id === 0
                          ? (handleUpdateAllPoints(-data.points, data.name),
                            setCount(count - data.points),
                            fail(),
                            editableToast("👎" + data.name))
                          : (setCount(count - data.points),
                            handleUpdatePoints(
                              props.id,
                              -data.points,
                              data.name
                            ),
                            fail(),
                            editableToast("👎" + data.name))
                      }
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      {isLoading ? (
                        <div className="loader"></div>
                      ) : (
                        <span
                          className="simpleCircle"
                          style={{ backgroundColor: "red" }}
                        >
                          {" "}
                          -{data.points}
                        </span>
                      )}
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "red" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomNegative}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("negatives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
            {props.id === 0 ? (
              <div label=""></div>
            ) : (
              <div label={`♻️ ${t("exchange")}`}>
                <ul>
                  {arrayExchanges.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="cardDescriptionModal"
                        onClick={() =>
                          props.id === 0
                            ? (handleUpdateAllPoints(-data.points),
                              setCount(
                                count - data.points,
                                exchange(),
                                editableToast("🛒" + data.name)
                              ))
                            : setCount(
                                count - data.points,
                                handleUpdatePoints(
                                  props.id,
                                  -data.points,
                                  data.name
                                ),
                                exchange(),
                                editableToast("🛒" + data.name)
                              )
                        }
                      >
                        {data.emoji}&nbsp;
                        {data.name}
                        <span
                          className="simpleCircle"
                          style={{ backgroundColor: "blue" }}
                        >
                          {" "}
                          -{data.points}
                        </span>
                      </div>
                    );
                  })}
                  <div
                    key={1000}
                    className="cardDescriptionModal"
                    onClick={() => openCustomModal()}
                  >
                    ➕&nbsp; {t("custom")}&nbsp;➕
                    <span
                      className="simpleCircle"
                      style={{ backgroundColor: "blue" }}
                    >
                      ?
                    </span>
                  </div>
                </ul>
                {isCustomModalOpen && (
                  <div className="customModal">
                    <form onSubmit={handleCustomExchange}>
                      <input
                        type="text"
                        value={customData.text}
                        onChange={handleCustomTextChange}
                        placeholder={t("exchange")}
                        required
                      />
                      <input
                        type="number"
                        min="1"
                        value={customData.points}
                        onChange={handleCustomPointsChange}
                        placeholder={t("points")}
                        required
                      />
                      <button type="submit" className="btn-success btn">
                        {t("save")}
                      </button>
                      <button
                        type="button"
                        className="btn-delete btn"
                        onClick={closeCustomModal}
                      >
                        {t("close")}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}
          </Tabs>
        </div>
        <button className="closeButton" onClick={closeModal}>
          {t("close")}
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default Avatar;
