const Achievements = [
  {
    id: 1,
    name: "bag",
    img: "../../images/achievements/bag.png",
  },
  {
    id: 2,
    name: "piggy",
    img: "../../images/achievements/piggy.png",
  },
  {
    id: 3,
    name: "brokenpiggy",
    img: "../../images/achievements/brokenpiggy.png",
  },
  {
    id: 4,
    name: "peace",
    img: "../../images/achievements/peace.png",
  },
  {
    id: 5,
    name: "money",
    img: "../../images/achievements/money.png",
  },
  {
    id: 6,
    name: "goldenbars",
    img: "../../images/achievements/goldenbars.png",
  },
  {
    id: 7,
    name: "one_diamond",
    img: "../../images/achievements/one_diamond.png",
  },
  {
    id: 8,
    name: "two_diamonds",
    img: "../../images/achievements/two_diamonds.png",
  },
  {
    id: 9,
    name: "three_diamonds",
    img: "../../images/achievements/three_diamonds.png",
  },
  {
    id: 10,
    name: "lots_of_diamonds",
    img: "../../images/achievements/lots_of_diamonds.png",
  },
  // {
  //   id: 7,
  //   name: "champion",
  //   img: "../../images/achievements/champion.png",
  // },
  // {
  //   id: 8,
  //   name: "diamond",
  //   img: "../../images/achievements/diamond.png",
  // },
  // {
  //   id: 9,
  //   name: "like",
  //   img: "../../images/achievements/like.png",
  // },
  // {
  //   id: 10,
  //   name: "lock",
  //   img: "../../images/achievements/lock.png",
  // },
];

export default Achievements;
