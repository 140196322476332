const Business = [
  {
    id: 1,
    name: "supermarket",
    img: "../../images/business/supermarket.png",
    cost: 80,
    minReturn: 7,
    maxReturn: 12,
  },
  {
    id: 2,
    name: "coffeeshop",
    img: "../../images/business/coffee-shop.png",
    cost: 60,
    minReturn: 7,
    maxReturn: 9,
  },
  {
    id: 3,
    name: "bakery",
    img: "../../images/business/bakery.png",
    cost: 50,
    minReturn: 6,
    maxReturn: 8,
  },
  {
    id: 4,
    name: "fruitstore",
    img: "../../images/business/fruit-store.png",
    cost: 40,
    minReturn: 5,
    maxReturn: 7,
  },
  {
    id: 5,
    name: "laundry",
    img: "../../images/business/laundry.png",
    cost: 30,
    minReturn: 3,
    maxReturn: 5,
  },
  {
    id: 6,
    name: "carworkshop",
    img: "../../images/business/car-workshop.png",
    cost: 50,
    minReturn: 6,
    maxReturn: 8,
  },
  {
    id: 7,
    name: "pharmacy",
    img: "../../images/business/pharmacy.png",
    cost: 70,
    minReturn: 7,
    maxReturn: 11,
  },
  {
    id: 8,
    name: "gym",
    img: "../../images/business/gym.png",
    cost: 50,
    minReturn: 6,
    maxReturn: 8,
  },
  {
    id: 9,
    name: "beautysalon",
    img: "../../images/business/beauty-salon.png",
    cost: 50,
    minReturn: 6,
    maxReturn: 8,
  },
  {
    id: 10,
    name: "hardwarestore",
    img: "../../images/business/hardware-store.png",
    cost: 55,
    minReturn: 6,
    maxReturn: 9,
  },
  {
    id: 11,
    name: "clothesstore",
    img: "../../images/business/clothes-store.png",
    cost: 60,
    minReturn: 7,
    maxReturn: 9,
  },
  {
    id: 12,
    name: "bookshop",
    img: "../../images/business/bookshop.png",
    cost: 35,
    minReturn: 4,
    maxReturn: 6,
  },
  {
    id: 13,
    name: "hairsalon",
    img: "../../images/business/hair-salon.png",
    cost: 30,
    minReturn: 3,
    maxReturn: 5,
  },
  {
    id: 14,
    name: "restaurant",
    img: "../../images/business/restaurant.png",
    cost: 75,
    minReturn: 7,
    maxReturn: 12,
  },
  {
    id: 15,
    name: "optics",
    img: "../../images/business/optics.png",
    cost: 50,
    minReturn: 6,
    maxReturn: 8,
  },
  {
    id: 16,
    name: "florist",
    img: "../../images/business/florist.png",
    cost: 25,
    minReturn: 2,
    maxReturn: 4,
  },
  {
    id: 17,
    name: "toyshop",
    img: "../../images/business/toyshop.png",
    cost: 40,
    minReturn: 4,
    maxReturn: 8,
  },
  {
    id: 18,
    name: "cinema",
    img: "../../images/business/cinema.png",
    cost: 120,
    minReturn: 12,
    maxReturn: 22,
  },
  {
    id: 19,
    name: "factory",
    img: "../../images/business/factory.png",
    cost: 150,
    minReturn: 18,
    maxReturn: 25,
  },
  {
    id: 20,
    name: "lab",
    img: "../../images/business/lab.png",
    cost: 180,
    minReturn: 10,
    maxReturn: 40,
  },
  {
    id: 21,
    name: "appliance",
    img: "../../images/business/appliance.png",
    cost: 0,
    minReturn: 5,
    maxReturn: 6,
  },
  {
    id: 22,
    name: "bike_store",
    img: "../../images/business/bike_store.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 8,
  },
  {
    id: 23,
    name: "carwash",
    img: "../../images/business/carwash.png",
    cost: 0,
    minReturn: 3,
    maxReturn: 9,
  },
  {
    id: 24,
    name: "driving_school",
    img: "../../images/business/driving_school.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 6,
  },
  {
    id: 25,
    name: "gas_station",
    img: "../../images/business/gas_station.png",
    cost: 0,
    minReturn: 3,
    maxReturn: 9,
  },
  {
    id: 26,
    name: "solar_power_plant",
    img: "../../images/business/solar_power_plant.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 8,
  },
  {
    id: 27,
    name: "marketplace",
    img: "../../images/business/marketplace.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 9,
  },
  {
    id: 28,
    name: "massage",
    img: "../../images/business/massage.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 7,
  },
  {
    id: 29,
    name: "shoe_store",
    img: "../../images/business/shoe_store.png",
    cost: 0,
    minReturn: 4,
    maxReturn: 7,
  },
  {
    id: 30,
    name: "hotel",
    img: "../../images/business/hotel.png",
    cost: 0,
    minReturn: 10,
    maxReturn: 14,
  },
];

export default Business;
