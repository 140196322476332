import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { deleteGroupThunk } from "../features/groups/groupsThunks";
import { useTranslation } from "react-i18next";

const EditGroups = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const groups = useSelector((state) => state.groups.groups);

  async function onDelete(idToBeDeleted) {
    dispatch(deleteGroupThunk(idToBeDeleted, groups));
  }

  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("studentName")}</th>
              <th style={{ textAlign: "center" }}>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groups).map((id, index) => {
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{groups[id].name}</td>
                  <td>
                    <Link to={`/updateGroup/${groups[id].id}`}>
                      <button className="btn btn-edit">Editar</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(groups[id].id)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EditGroups;
