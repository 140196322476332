import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invites: [],
  savedClassrooms: [],
  emails: [],
};

const inviteSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {
    setEmails: (state, action) => {
      state.emails = action.payload;
    },
    setSavedClassrooms: (state, action) => {
      state.savedClassrooms = action.payload;
    },
    addEmail: (state, action) => {
      state.emails.push(action.payload);
    },
    addSavedClassroom: (state, action) => {
      state.savedClassrooms.push(action.payload);
    },
    deleteEmail: (state, action) => {
      state.emails = state.emails.filter((email) => email !== action.payload);
    },
    deleteSavedClassroom: (state, action) => {
      state.savedClassrooms = state.savedClassrooms.filter(
        (savedClassroom) => savedClassroom.id !== action.payload
      );
    },
  },
});

export const {
  setEmails,
  addEmail,
  deleteEmail,
  setSavedClassrooms,
  addSavedClassroom,
  deleteSavedClassroom,
} = inviteSlice.actions;

export default inviteSlice.reducer;
