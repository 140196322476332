import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import StudentGroupHeader from "./StudentGroupHeader";
import { Link } from "react-router-dom";

import BusinessGallery from "../routes/BusinessGallery";
import Business from "../data/Business";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function RenderBusiness() {
  const students = useSelector((state) => state.students) || [];
  const { t } = useTranslation();
  const [isAnimated, setIsAnimated] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const lastPointsAddedDateStr = useSelector(
    (state) => state.settings.lastPointsAddedDate
  );

  const studentsWithBusiness = students.filter(
    (student) => student.businesses && student.businesses.length > 0
  );

  function updateProgressBars() {
    const currentDate = new Date();
    const lastPointsAddedDate = new Date(lastPointsAddedDateStr);
    
    const differenceInTime = currentDate - lastPointsAddedDate;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    let progressPercentage = (differenceInDays / 7) * 100;
  
    // Asegurarse de que el porcentaje no sea mayor que 100 y redondearlo
    progressPercentage = Math.min(Math.floor(progressPercentage), 100);
  
    setProgressWidth(progressPercentage);
  }
  

  useEffect(() => {
    if (!isAnimated) {
      setTimeout(() => {
        setIsAnimated(true);
        updateProgressBars();
      }, 500);
    }
  }, [isAnimated]);

  return (
    <>
      <Header />
      <StudentGroupHeader />
      <div className="business-wrapper">
        <h2>{t("next_payment")}</h2>
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progressWidth}%` }}>
            {progressWidth}%
          </div>
        </div>
        {studentsWithBusiness.length > 0 ? (
  <div className="business-container">
    <ul className="business-list">
      {studentsWithBusiness.map((student) => {
        const studentBusinesses = student.businesses.map((businessId) =>
          Business.find((business) => business.id === businessId)
        );

        return studentBusinesses.map((business) => (
          <li key={business.id} className="business-item-render">
            <div className="business-box">
              <img
                src={business.img}
                alt={business.name}
                className="business-image"
              />
              <p className="business-name">{`${t("propertyOf")}`} {t(`${business.name}`)} {t(`${student.name}`)}</p>
              <img className="business-avatar" src={student.img} />
            </div>
          </li>
        ));
      })}
    </ul>
  </div>
) : (
  <BusinessGallery />
)}

      </div>
      <div className="centerBtn">
        <button className="custom-btn btn-4">
          <Link to="/business-info" style={{ color: "white" }}>
            + {t("information")}
          </Link>
        </button>
      </div>
    </>
  );
}

export default RenderBusiness;
