import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AddEdit.css";
import Select from "react-select"; // Importa el componente Select de react-select
import { useNavigate } from "react-router-dom";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";

import firebaseApp from "../credentials";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { updateStudent } from "../features/students/studentsSlice";

import "react-toastify/dist/ReactToastify.css";
import { correctToast, wrongToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

import { updateStudentPoints } from "../features/students/studentsThunks";
import Business from "../data/Business";

const firestore = getFirestore(firebaseApp);

var idStudent = "";

const AddBusiness = () => {
  const { id } = useParams();
  idStudent = id;

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const { t } = useTranslation();

  //Card styles for business
  const cardClasses = [
    "business1",
    "business2",
    "business3",
    "business4",
    "business5",
  ];

  //fn to force re-rendering
  const navigate = useNavigate();

  async function formId(e) {
    e.preventDefault();
    const formElement = document.getElementById("formId");
    const formData = new FormData(formElement);

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    const businessName = formData.get("business");
    const purchasedBusiness = Business.find((b) => b.name === businessName);
    const businessId = purchasedBusiness.id;

    const studentData = students[idStudent];
    let updatedStudentBusinesses = studentData.businesses || [];

    if (businessId) {
      updatedStudentBusinesses = [...updatedStudentBusinesses, businessId];
    }

    const updatedStudent = {
      ...studentData,
      businesses: updatedStudentBusinesses,
    };

    const docSnap = await getDoc(docuRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      if (docData.students && docData.students[idStudent]) {
        docData.students[idStudent] = updatedStudent;

        await updateDoc(docuRef, docData);
        dispatch(updateStudent({ id: idStudent, updatedStudent }));
      }
    }
    dispatch(
      updateStudentPoints(
        userEmail,
        idStudent,
        -purchasedBusiness.cost,
        t("businessPurchased")
      )
    );
    correctToast(t("businessAddedMsg"));
    setSelectedBusiness(null);
  }

  const removeBusiness = async () => {
    if (!selectedBusiness) {
      wrongToast(t("noBusinessSelected"));
      return;
    }

    const purchasedBusiness = Business.find(
      (b) => b.name === selectedBusiness.value
    );
    if (!purchasedBusiness) return;

    const businessId = purchasedBusiness.id;
    const businessCost = purchasedBusiness.cost;

    const studentData = students[idStudent];
    const index = studentData.businesses.indexOf(businessId);

    if (index === -1) {
      wrongToast(t("noBusinessToSell"));
      return;
    }

    if (index !== -1) {
      // Crear una nueva array eliminando solo la primera instancia del negocio
      const updatedBusinesses = [
        ...studentData.businesses.slice(0, index),
        ...studentData.businesses.slice(index + 1),
      ];

      const updatedStudent = {
        ...studentData,
        businesses: updatedBusinesses,
      };

      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      const docSnap = await getDoc(docuRef);
      if (docSnap.exists()) {
        const docData = docSnap.data();
        if (docData.students && docData.students[idStudent]) {
          docData.students[idStudent] = updatedStudent;
          await updateDoc(docuRef, docData);
          dispatch(updateStudent({ id: idStudent, updatedStudent }));

          // Devuelve los puntos al estudiante
          //Primero calculamos de forma aleatoria el coste que le retornaremos, que variará 10% arriba o abajo
          let min = Math.ceil(businessCost * 0.9);
          let max = Math.floor(businessCost * 1.1);
          let businessCostReturn = Math.floor(
            Math.random() * (max - min + 1) + min
          );

          dispatch(
            updateStudentPoints(
              userEmail,
              idStudent,
              businessCostReturn,
              t("businessReturnedPointsMsg")
            )
          );

          correctToast(t("businessRemovedMsg"));
          setSelectedBusiness(null);
        }
      }
    }
  };

  const businessOptions = Business.map((business) => ({
    value: business.name,
    label: t(`${business.name}`),
    img: business.img,
    cost: business.cost,
    min: business.minReturn,
    max: business.maxReturn,
    id: business.id,
  }));

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const handleImageClick = (businessValue) => {
    const businessOption = businessOptions.find(
      (b) => b.value === businessValue
    );
    setSelectedBusiness(businessOption);
    topFunction();
  };

  return (
    <>
      <div>
        <Header />
        <h2 style={{ textAlign: "center" }}>
          {students[id] ? students[id].name + " " + students[id].surname : ""}{" "}
          {t("buyBusiness")}
        </h2>
        <h3 style={{ textAlign: "center" }}>
          {t("points")}: {students[id] ? students[id].points : ""}
        </h3>
        <form
          onSubmit={formId}
          id="formId"
          style={{
            margin: "auto",
            padding: "15px",
            maxWidth: "400px",
            alignContent: "center",
          }}
        >
          <label htmlFor="businessInput">{t("business")}</label>
          <Select
            id="businessInput"
            name="business"
            options={businessOptions}
            value={selectedBusiness}
            onChange={setSelectedBusiness}
            getOptionLabel={(option) => (
              <div>
                <img
                  src={option.img}
                  alt=""
                  style={{ width: "150px", marginRight: "10px" }}
                />
              </div>
            )}
            placeholder="Click"
          />

          <input type="submit" value="Guardar" />
          <button
            className="button-error"
            type="button"
            onClick={removeBusiness}
          >
            {t("removeBusiness")}
          </button>
        </form>
      </div>
      <h2 style={{ textAlign: "center" }}>{t("subtitleAddBusiness")}</h2>
      <div className="container">
        <ul className="image-gallery">
          {businessOptions.slice(0, 20).map((business, index) => (
            <li key={business.value}>
              <div className="wrapper-business">
                <div
                  className={`clash-card ${
                    cardClasses[index % cardClasses.length]
                  }`}
                >
                  <div
                    className={`clash-card__image clash-card__image--${
                      cardClasses[index % cardClasses.length]
                    }`}
                  >
                    <img
                      src={business.img}
                      alt="business"
                      onClick={() => handleImageClick(business.value)}
                    />
                  </div>
                  <div
                    className={`clash-card__level clash-card__level--${
                      cardClasses[index % cardClasses.length]
                    }`}
                  >
                    {t("investment")}
                  </div>
                  <div class="clash-card__unit-name">{business.label}</div>
                  <div class="clash-card__unit-description">
                    {/* {t(`business_description_${business.id}`)} */}
                  </div>

                  <div
                    class={`clash-card__unit-stats clash-card__unit-stats--${
                      cardClasses[index % cardClasses.length]
                    } clearfix`}
                  >
                    <div class="one-third">
                      <div class="stat">{business.cost}</div>
                      <div class="stat-value">{t("cost")}</div>
                    </div>

                    <div class="one-third">
                      <div class="stat">{business.min}</div>
                      <div class="stat-value">{t("return_min")}</div>
                    </div>

                    <div class="one-third no-border">
                      <div class="stat">{business.max}</div>
                      <div class="stat-value">{t("return_max")}</div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
          {businessOptions.slice(20, 30).map((business, index) => (
            <li key={business.value}>
              <div className="wrapper-business">
                <div className={`clash-card gray-scale`}>
                  <div className={`clash-card__image clash-card__image--gray`}>
                    <img
                      src={business.img}
                      alt="business"
                      style={{ filter: "grayscale(100%)" }} // Aplica escala de grises
                      // Eliminamos el onClick para que no sea clicable
                    />
                  </div>
                  <div className={`clash-card__level clash-card__level--gray`}>
                    {t("investment")}
                  </div>
                  <div className="clash-card__unit-name">{business.label}</div>
                  <div className="clash-card__unit-description">
                    {/* {t(`business_description_${business.id}`)} */}
                  </div>

                  <div
                    className={`clash-card__unit-stats clash-card__unit-stats--gray clearfix`}
                  >
                    <div className="one-third">
                      <div className="stat">{business.cost}</div>
                      <div className="stat-value" style={{ color: "black" }}>
                        {t("lootboxes")}
                      </div>
                    </div>

                    <div className="one-third">
                      <div className="stat" style={{ color: "black" }}>
                        {business.min}
                      </div>
                      <div className="stat-value" style={{ color: "black" }}>
                        {t("return_min")}
                      </div>
                    </div>

                    <div className="one-third no-border">
                      <div className="stat" style={{ color: "black" }}>
                        {business.max}
                      </div>
                      <div className="stat-value" style={{ color: "black" }}>
                        {t("return_max")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddBusiness;
