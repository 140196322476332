import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    newsletter: false,
    petReward: false,
    accountType: "",
    lastPointsAddedDate: null,
  },
  reducers: {
    updateSettings: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    addAccountType: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateSettings, addAccountType } = settingsSlice.actions;

export default settingsSlice.reducer;
