import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { Link } from "react-router-dom";

function StudentRecords() {
  const { t } = useTranslation();
  const { id } = useParams();
  const student = useSelector((state) => state.students[id]);

  const [filter, setFilter] = useState({
    date: "",
    description: "",
    type: "",
    amount: "",
  });

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const filteredRecords =
    student && student.records
      ? student.records.filter((record) => {
          return (
            (filter.date === "" || record.date.includes(filter.date)) &&
            (filter.description === "" ||
              record.description.includes(filter.description)) &&
            (filter.type === "" || record.type === filter.type) &&
            (filter.amount === "" || record.amount === Number(filter.amount))
          );
        })
      : [];

  return (
    <>
      <Header />
      <div className="records-container">
        <h2>
          {t("record")} {student ? `${student.name} ${student.surname}` : ""}
        </h2>
        <p>
        <Link className="btn btn-edit" to="/game">
          {t("goBack")}
        </Link>
      </p>
        <div className="filter-container">
          <input
            type="text"
            className="filter-input"
            name="date"
            placeholder={t("date")}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            className="filter-input"
            name="description"
            placeholder={t("description")}
            onChange={handleFilterChange}
          />

          <select
            className="filter-input"
            name="type"
            onChange={handleFilterChange}
          >
            <option value="">{t("all")}</option>
            <option value="+">{t("earnedPoints")}</option>
            <option value="-">{t("lostPoints")}</option>
          </select>
        </div>
        {filteredRecords.map((record, index) => (
          <div key={index} className="record-item">
            <p className="record-header">
              {record.date} - {record.description} {record.type}{" "}
              {Math.abs(record.amount)}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}

export default StudentRecords;
