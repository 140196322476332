import React, { useEffect } from "react";

import Header from "../Components/Header/Header";
import "./ChooseChibit.css";
import { useTranslation } from "react-i18next";
import AddClassroom from "./AddClassroom";

import useRequireAuth from "../hooks/useRequireAuth";

const ChooseChibit = () => {
  const { t } = useTranslation();
  useRequireAuth();

  return (
    <>
      <Header />
      <div style={{ padding: 20 }}>
        <div className="rowH">
          <div className="columnH">
            <p className="title">{t("chooseChibit")}</p>
            <p className="subtitle">
              {t("subtitleChooseChibit")}{" "}
              <a href="https://classbits.net/tutorial">Tutorial de Classbits</a>
            </p>
          </div>
        </div>
      </div>
      <AddClassroom />
    </>
  );
};

export default ChooseChibit;
