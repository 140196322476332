import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedStudentsDiamonds } from "../../features/students/studentsThunks"; // Asume que tienes una acción para actualizar los diamantes
import "../MultiSelectPopup/styles.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../ToastUtils";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function AttendancePopup({ isOpen, onClose, students }) {
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const { t } = useTranslation();

  // Actualización de diamantes para estudiantes seleccionados
  async function updateAttendance() {
    setIsLoading(true);
    const diamondsToAdd = 1; // Agregar un diamante por asistencia
    const actionName = t("havingAttended");

    dispatch(
      updateSelectedStudentsDiamonds(
        userEmail,
        selectedStudents,
        diamondsToAdd,
        actionName
      )
    );

    setIsLoading(false);
    correctToast(t("attendanceRecorded"));
    onClose();
  }

  const updateButtonStatus = () => {
    setIsButtonDisabled(selectedStudents.length === 0);
  };

  useEffect(() => {
    updateButtonStatus();
  }, [selectedStudents]);

  const toggleStudentSelection = (student) => {
    if (selectedStudents.some((s) => s.id === student.id)) {
      setSelectedStudents((prev) => prev.filter((s) => s.id !== student.id));
    } else {
      setSelectedStudents((prev) => [...prev, student]);
    }
  };

  const toggleAllStudents = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students);
    }
  };

  return (
    <>
      <div className={`popupMS ${isOpen ? "open" : ""}`}>
        <button
          className="close"
          onClick={() => {
            setSelectedStudents([]);
            onClose();
          }}
        >
          {t("close")}
        </button>
        <button onClick={toggleAllStudents}>
          {selectedStudents.length === students.length
            ? t("disSelectAll")
            : t("selectAll")}
        </button>
        <ul>
          {students.map((student) => {
            if (student.id === 0) return null;

            return (
              <li key={student.id} className="student-card">
                <label className="student-checkbox-label">
                  <input
                    type="checkbox"
                    checked={selectedStudents.some((s) => s.id === student.id)}
                    onChange={() => toggleStudentSelection(student)}
                    className="hidden-checkbox"
                  />
                  <div className="student-info">
                    <span className="student-name">{student.name}</span>
                    <span className="student-surname">{student.surname}</span>
                    <img height="30px" width="30px" src={student.img} />
                  </div>
                </label>
              </li>
            );
          })}
        </ul>

        <button
          className="points"
          onClick={updateAttendance}
          disabled={isButtonDisabled}
        >
          {t("takeAttendance")}
        </button>
      </div>

      <ToastContainer />
    </>
  );
}

export default AttendancePopup;
