import "./styles.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import firebaseApp from "../../credentials";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { login, logout } from "../../features/auth/authSlice";

const auth = getAuth(firebaseApp);

function Header() {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const originalEmail = useSelector((state) => state.auth.originalEmail);
  
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        // Comprueba si el usuario actual es un invitado antes de actualizar el estado
        if (!userEmail || userEmail === usuarioFirebase.email) {
          dispatch(login(usuarioFirebase.email));
        }
      } else {
        dispatch(logout());
      }
    });

    // Limpiar el listener cuando el componente se desmonte
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <header>
      <nav>
        <Link to="/">{t("home")}</Link>
        {userEmail ? (
          <Link to="/game">ClassBits</Link>
        ) : (
          <Link to="/game">{t("access")}</Link>
        )}
        {userEmail ? <Link to="/tools">{t("tools")}</Link> : ""}
        {originalEmail ? "" : userEmail ? <Link to="/settings">{t("settings")}</Link> : ""}
        {userEmail ? <Link to="/profile">{t("profile")}</Link> : ""}
        <span onClick={() => handleLanguageChange("cat")}>
          <img src="/images/cat.svg" alt="cat" width={32} />
          &nbsp;&nbsp;
        </span>

        <span onClick={() => handleLanguageChange("es")}>
          <img src="/images/es.svg" alt="es" width={32} />
        </span>
      </nav>
    </header>
  );
}
export default Header;
