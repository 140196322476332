import "../Avatar/styles.css";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Tabs from "../Tab/Tabs";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  subtractPointFromGroup,
  addPointToGroup,
} from "../../features/groups/groupsThunks";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editableToast, correctToast, wrongToast } from "../ToastUtils";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

//Add sounds when points interaction outside component to render only once
let audioCorrect = new Audio("./audio/correct.wav");
let audioFail = new Audio("./audio/fail.mp3");

function AvatarGroups(props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [groupStudents, setGroupStudents] = useState([]);

  const dispatch = useDispatch();
  const students = useSelector((state) => state.students);
  const groups = useSelector((state) => state.groups.groups);
  const arrayPositives = useSelector((state) => state.behaviours.positives);
  const arrayNegatives = useSelector((state) => state.behaviours.negatives);

  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [customData, setCustomData] = useState({ text: "", points: 0 });

  const handleCustomTextChange = (e) => {
    setCustomData({ ...customData, text: e.target.value });
  };

  const handleCustomPointsChange = (e) => {
    setCustomData({ ...customData, points: parseInt(e.target.value, 10) });
  };

  const openCustomModal = () => setIsCustomModalOpen(true);
  const closeCustomModal = () => setIsCustomModalOpen(false);

  const { t } = useTranslation();

  // New useEffect to find students belonging to the group
  useEffect(() => {
    const group = groups.find((group) => group.id === props.id);
    if (group) {
      const studentsUE = students.filter((student) =>
        group.students.includes(student.id)
      );
      setGroupStudents(studentsUE);
    }
  }, [groups, students]);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  //Reproduce sound after interaction (positive or  negative)
  const correct = () => {
    audioCorrect.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const fail = () => {
    audioFail.play().catch((error) => {
      console.error("Error al reproducir audio:", error);
    });
  };

  const handleSubtractPoint = (groupId, points, actionName) => {
    dispatch(subtractPointFromGroup(groupId, points, actionName));
  };

  const handleAddPoint = (groupId, points, actionName) => {
    dispatch(addPointToGroup(groupId, points, actionName));
  };

  const handleCustomPositive = (e) => {
    e.preventDefault();
    correct();
    editableToast("👍" + customData.text);
    handleAddPoint(props.id, customData.points, customData.text);
    closeCustomModal();
  };

  const handleCustomNegative = (e) => {
    e.preventDefault();
    fail();
    editableToast("👎" + customData.text);
    handleSubtractPoint(props.id, -customData.points, customData.text);
    closeCustomModal();
  };

  return (
    <>
      <div className="avatarContainer">
        <li className="avatarPointer" id={props.id} onClick={openModal}>
          <img src={props.img} className="imgAvatarRender" alt="Avatar" />
          <span className="name">{props.name}</span>
        </li>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{props.name}</h2>
        <div className="container">
          <figure className="groupStudentsAvatar">
            <img src={props.img} className="avatarImage" alt="Avatar" />
            <figcaption>
              {groupStudents.length > 0
                ? groupStudents.map((student) => student.name).join(", ")
                : "Ningún estudiante"}
            </figcaption>
          </figure>

          <Tabs>
            <div label={`👍 ${t("positives")}`}>
              <ul>
                {arrayPositives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() => (
                        handleAddPoint(props.id, data.points, data.name),
                        correct(),
                        editableToast("👍" + data.name)
                      )}
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      <span
                        className="simpleCircle"
                        style={{ backgroundColor: "green" }}
                      >
                        {" "}
                        +{data.points}
                      </span>
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "green" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomPositive}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("positives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div label={`👎 ${t("negatives")}`}>
              <ul>
                {arrayNegatives.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="cardDescriptionModal"
                      onClick={() => (
                        handleSubtractPoint(props.id, data.points, data.name),
                        fail(),
                        editableToast("👎" + data.name)
                      )}
                    >
                      {data.emoji}&nbsp;
                      {data.name}
                      <span
                        className="simpleCircle"
                        style={{ backgroundColor: "red" }}
                      >
                        {" "}
                        -{data.points}
                      </span>
                    </div>
                  );
                })}
                <div
                  key={1000}
                  className="cardDescriptionModal"
                  onClick={() => openCustomModal()}
                >
                  ➕&nbsp; {t("custom")}&nbsp;➕
                  <span
                    className="simpleCircle"
                    style={{ backgroundColor: "red" }}
                  >
                    ?
                  </span>
                </div>
              </ul>
              {isCustomModalOpen && (
                <div className="customModal">
                  <form onSubmit={handleCustomNegative}>
                    <input
                      type="text"
                      value={customData.text}
                      onChange={handleCustomTextChange}
                      placeholder={t("negatives")}
                      required
                    />
                    <input
                      type="number"
                      min="1"
                      value={customData.points}
                      onChange={handleCustomPointsChange}
                      placeholder={t("points")}
                      required
                    />
                    <button type="submit" className="btn-success btn">
                      {t("save")}
                    </button>
                    <button
                      type="button"
                      className="btn-delete btn"
                      onClick={closeCustomModal}
                    >
                      {t("close")}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </Tabs>
        </div>
        <button className="closeButton" onClick={closeModal}>
          {t("close")}
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default AvatarGroups;
