// behavioursSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  positives: [],
  negatives: [],
  exchanges: [],
};

const behavioursSlice = createSlice({
  name: "behaviours",
  initialState,
  reducers: {
    setPositives: (state, action) => {
      state.positives = action.payload;
    },
    addPositive: (state, action) => {
      state.positives.push(action.payload);
    },
    updatePositive: (state, action) => {
      const { index, data } = action.payload;
      state.positives[index] = data;
    },
    deletePositive: (state, action) => {
      state.positives = state.positives.filter(
        (positive) => positive.id !== action.payload
      );
    },
    deleteAllPositives: (state) => {
      state.positives = [];
    },
    setNegatives: (state, action) => {
      state.negatives = action.payload;
    },
    addNegative: (state, action) => {
      state.negatives.push(action.payload);
    },
    updateNegative: (state, action) => {
      const { index, data } = action.payload;
      state.negatives[index] = data;
    },
    deleteNegative: (state, action) => {
      state.negatives = state.negatives.filter(
        (negative) => negative.id !== action.payload
      );
    },
    deleteAllNegatives: (state) => {
      state.negatives = [];
    },
    setExchanges: (state, action) => {
      state.exchanges = action.payload;
    },
    addExchange: (state, action) => {
      state.exchanges.push(action.payload);
    },
    updateExchange: (state, action) => {
      const { index, data } = action.payload;
      state.exchanges[index] = data;
    },
    deleteExchange: (state, action) => {
      state.exchanges = state.exchanges.filter(
        (exchange) => exchange.id !== action.payload
      );
    },
    deleteAllExchanges: (state) => {
      state.exchanges = [];
    },
  },
});

export const {
  setPositives,
  addPositive,
  updatePositive,
  deletePositive,
  deleteAllPositives,
  setNegatives,
  addNegative,
  updateNegative,
  deleteNegative,
  deleteAllNegatives,
  setExchanges,
  addExchange,
  updateExchange,
  deleteExchange,
  deleteAllExchanges,
} = behavioursSlice.actions;

export default behavioursSlice.reducer;
