import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";

export default function ThankYou() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="thank-you-container">
        <h1>🎉 {t("thanksForSubscribingTitle")} 🎉</h1>
        <p>{t("thanksForSubscribingText")}</p>
        <h2>💌 {t("afterSubscribingTitle")}</h2>
        <p>{t("afterSubscribingText")}</p>
      </div>
    </>
  );
}
