const starterStudents = [
  {
    id: 0,
    name: "Tota la classe",
    surname: "",
    img: "../images/chibi_classroom.png",
    pet: "",
    points: 0,
    group: 0,
    diamonds: 0,
  },
];

export default starterStudents;
