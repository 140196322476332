import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import "./EditClassroom.css";
import { useTranslation } from "react-i18next";

import firebaseApp from "../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { addPoints, subtractPoints } from "../features/students/studentsSlice";
import { updateStudentPoints } from "../features/students/studentsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast, wrongToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const firestore = getFirestore(firebaseApp);

const ExchangePoints = () => {
  const { t } = useTranslation();
  let audioExchange = new Audio("./audio/sent.mp3");

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  useEffect(() => {
    async function fetchStudents() {
      for (let i = 0; i < students.length; i++) {
        const sel = document.createElement("option");
        sel.innerHTML = `${students[i].name} ${students[i].surname}`;
        sel.value = students[i].id;
        document.getElementById("sender").appendChild(sel);

        const receiverSel = document.createElement("option");
        receiverSel.innerHTML = `${students[i].name} ${students[i].surname}`;
        receiverSel.value = students[i].id;
        document.getElementById("receiver").appendChild(receiverSel);
      }
    }

    fetchStudents();
  }, []);

  const exchangeSound = () => {
    audioExchange.play();
  };

  const resetForm = () => {
    document.getElementById("sender").value = "";
    document.getElementById("receiver").value = "";
    document.getElementById("points").value = "";
  };

  const exchangePoints = async () => {
    const senderId = parseInt(document.getElementById("sender").value);
    const receiverId = parseInt(document.getElementById("receiver").value);

    const rawPoints = document.getElementById("points").value.trim();
    if (!rawPoints.match(/^\d+$/)) {
      console.error("Valor de puntos no válido.");
      return;
    }
    const points = parseInt(rawPoints);

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    const senderStudentIndex = students.findIndex(
      (student) => student.id === senderId
    );
    const receiverStudentIndex = students.findIndex(
      (student) => student.id === receiverId
    );

    if (senderStudentIndex !== -1 && receiverStudentIndex !== -1) {
      dispatch(
        updateStudentPoints(
          userEmail,
          senderId,
          -points,
          t("senderPoints") + " " +  students[receiverId].name
        )
      );
      dispatch(
        updateStudentPoints(
          userEmail,
          receiverId,
          points,
          students[senderId].name + " " + t("receiverPoints")

        )
      );

      exchangeSound();
      correctToast(t("successfulExchangePointsMsg"));
      resetForm();
    } else {
      wrongToast(t("wrongExchangePointsMsg"));
    }
  };

  return (
    <>
      <Header />
      <div className="editTitle">
        <h2>{t("transferPointsTitle")}</h2>
        <div>
          <select id="sender">
            <option value="">{t("selectSender")}</option>
          </select>

          <input type="number" min="0" id="points" />

          <select id="receiver">
            <option value="">{t("selectReceiver")}</option>
          </select>
          <button className="btn btn-edit" onClick={exchangePoints}>
            {t("btnTransfer")}
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ExchangePoints;
