import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../Components/Header/Header";
import StudentGroupHeader from "../Components/StudentGroupHeader";
import "./react-select-custom.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateGroup } from "../features/groups/groupsSlice";

import "react-toastify/dist/ReactToastify.css";
import { correctToast, wrongToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

import firebaseApp from "../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore(firebaseApp);

function UpdateGroup() {
  const { t } = useTranslation();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [groupName, setGroupName] = useState("");

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);
  const groups = useSelector((state) => state.groups.groups);

  const { id } = useParams();
  const idGroup = parseInt(id);

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/editGroups");
  }

  useEffect(() => {
    // Find the group that matches the id from the URL
    const group = groups.find((group) => group.id === idGroup);
    // Set the form data based on this group
    if (group) {
      setGroupName(group.name);
      setSelectedStudents(
        group.students.map((studentId) => ({
          value: studentId,
          label: students.find((student) => student.id === studentId).name,
        }))
      );
    }
  }, [students]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedStudents(selectedOptions || []);
  };

  const handleNameChange = (event) => {
    setGroupName(event.target.value);
  };

  async function editGroupForm(e) {
    e.preventDefault();

    const selectedStudentIds = selectedStudents.map((option) => option.value);
    const groupIndex = groups.findIndex((group) => group.id === idGroup);

    const updatedGroup = {
      id: idGroup,
      name: groupName,
      students: selectedStudentIds,
    };

    const updatedGroups = [...groups];
    updatedGroups[groupIndex] = updatedGroup;

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    try {
      await updateDoc(docuRef, { groups: updatedGroups });

      // Despachar la actualización en Redux
      dispatch(updateGroup({ id: idGroup, updatedGroup }));

      correctToast(t("editGroupMsg"));
      setTimeout(() => updateReRender(), 500);
    } catch (error) {
      // Maneja cualquier error aquí
      wrongToast("Error updating group:", error);
    }
  }

  const options = students.map((student) => ({
    value: student.id,
    label: student.name,
  }));

  return (
    <>
      <Header />
      <StudentGroupHeader />
      <h2 style={{ textAlign: "center" }}>
        Editar{" "}
        {groups.find((group) => group.id === idGroup)
          ? groups.find((group) => group.id === idGroup).name
          : ""}
      </h2>
      <form
        onSubmit={editGroupForm}
        id="editGroupForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlFor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlFor="nameInput">{t("groupName")}</label>
        <input
          type="text"
          id="nameInput"
          name="name"
          required
          onChange={handleNameChange}
          value={groupName}
        />

        <label htmlFor="students">{t("students")}</label>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              height: "200px", // Ajusta la altura del input
            }),
          }}
          isMulti
          name="students"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleSelectChange}
          value={selectedStudents}
        />
        <input type="submit" value="Guardar" />
      </form>
      <ToastContainer />
    </>
  );
}

export default UpdateGroup;
