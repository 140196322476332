import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function CountdownTimer() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const audioRef = useRef(new Audio('./audio/alarm.mp3'));

  const { t } = useTranslation();


  useEffect(() => {
    let countdownInterval;

    if (isRunning) {
      countdownInterval = setInterval(() => {
        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(countdownInterval);
          setIsBlinking(true);
          audioRef.current.play();
        } else {
          if (seconds === 0) {
            if (minutes === 0) {
              setHours((prevHours) => prevHours - 1);
              setMinutes(59);
            } else {
              setMinutes((prevMinutes) => prevMinutes - 1);
            }
            setSeconds(59);
          } else {
            setSeconds((prevSeconds) => prevSeconds - 1);
          }
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [hours, minutes, seconds, isRunning]);

  const startCountdown = () => {
    setIsRunning(true);
  };

  const stopCountdown = () => {
    setIsRunning(false);
    setIsBlinking(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  const resetCountdown = () => {
    setIsRunning(false);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
    setIsBlinking(false);
  };

  return (
    <div className="countdown-container">
      <div className="countdown-inputs">
        <div>
          <span>{t("hours")}:</span>
          <input
            type="number"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
          />
        </div>
        <div>
          <span>{t("minutes")}:</span>
          <input
            type="number"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
          />
        </div>
        <div>
          <span>{t("seconds")}:</span>
          <input
            type="number"
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
          />
        </div>
      </div>
      <div className="countdown-controls">
        <button onClick={startCountdown}>{t("timeDownStart")}</button>
        <button onClick={stopCountdown}>Stop</button>
        <button onClick={resetCountdown}>Reset</button>
      </div>
      <div className={`countdown-display${isBlinking ? "-alert" : ""}`}>
        <p>
        {t("timeDownRemaining")}: {String(hours).padStart(2, "0")}:
          {String(minutes).padStart(2, "0")}:
          {String(seconds).padStart(2, "0")}
        </p>
      </div>
    </div>
  );
}

export default CountdownTimer;
