const starterPositivesEs = [
  {
    id: 0,
    name: "Ser honesto",
    emoji: "⚖️",
    points: 1,
  },
  {
    id: 1,
    name: "Sacar notable alto",
    emoji: "⬆️",
    points: 1,
  },
  {
    id: 2,
    name: "Hacer muy bien el encargo",
    emoji: "🎩",
    points: 1,
  },
  {
    id: 3,
    name: "Participa",
    emoji: "🙋‍♂️",
    points: 1,
  },
  {
    id: 4,
    name: "Pergamino del destino",
    emoji: "📜",
    points: 1,
  },
  {
    id: 5,
    name: "Se esfuerza mucho",
    emoji: "💪",
    points: 1,
  },
  {
    id: 6,
    name: "Ayuda a los compañeros",
    emoji: "🧑‍🤝‍🧑",
    points: 1,
  },
  {
    id: 7,
    name: "Sacar un excelente",
    emoji: "💯",
    points: 3,
  },
];

export default starterPositivesEs;
