import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

function Tutorial() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {/* Render of articles images with a link to them */}
      {/* Tutoriales? */}
      <h2>Curso Tutorial Classbits</h2>
      <div class="iframe-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/7D30Bwl-GWY?si=IqaLY317GfrGnlYK"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/KpK_6pLwCeY?si=LzWwlfJtEyG3fhpO"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vWDQlZRfH2Q?si=xH8ETcVtg3hQqZB4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/1GaUbD_3z_M?si=HeHOh7cH_0KcQvRZ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/YHxHNvDCWQc?si=lhtgpSCuZin0xYRb"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <Footer />
    </>
  );
}
export default Tutorial;
