import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AddEdit.css";

import Header from "../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSentenceThunk } from "../features/tools/toolsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const EditSentence = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const dailySentences = useSelector((state) => state.tools.dailySentences) || [];

  const [text, setText] = useState("");

  const { id } = useParams();
  const idSettings = parseInt(id);

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/edit-sentences");
  }

  useEffect(() => {
    async function fetchSettings() {
      // Find the setting that matches the id from the URL
      const setting = dailySentences.find((setting) => setting.id === idSettings);
      // Set the form data based on this setting
      if (setting) {
        setText(setting.text);
      }
    }
    fetchSettings();
  }, []);

  useEffect(() => {
    const setting = dailySentences.find((setting) => setting.id === idSettings);
    if (setting) {
      setText(setting.text);
    }
  }, [idSettings, dailySentences]);

  async function editStudentForm(e) {
    e.preventDefault();

    const data = {
      id: idSettings,
      text: text,
    };

    const index = dailySentences.findIndex(
      (dailySentence) => dailySentence.id === idSettings
    );

    if (index !== -1) {
      dispatch(updateSentenceThunk(userEmail, index, data));
      correctToast(t("editSentence"));
      setTimeout(() => navigate("/edit-sentences"), 1000);
    }
  }

  const dailySentence = dailySentences.find((p) => p.id === idSettings);

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>
        Editar {dailySentence ? dailySentence.text : ""}
      </h2>
      <form
        onSubmit={editStudentForm}
        id="addStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlfor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlfor="nameInput">{t("sentence")}</label>
        <textarea
          className="textAreaForm"
          id="nameInput"
          name="name"
          required
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <input type="submit" value="Guardar" />
      </form>
      <Link className="btn btn-edit" to="/edit-sentences">
        {t("goBack")}
      </Link>
      <ToastContainer />
    </div>
  );
};

export default EditSentence;
