import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    addGroup: (state, action) => {
      state.groups.push(action.payload);
    },
    updateGroup: (state, action) => {
      const { id, updatedGroup } = action.payload;
      const groupIndex = state.groups.findIndex((group) => group.id === id);
      if (groupIndex > -1) {
        state.groups[groupIndex] = updatedGroup;
      }
    },
    deleteGroup: (state, action) => {
      state.groups = state.groups.filter(
        (group) => group.id !== action.payload
      );
    },
  },
});

export const { setGroups, addGroup, deleteGroup, updateGroup } =
  groupsSlice.actions;

export default groupsSlice.reducer;
