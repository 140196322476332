import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultToastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const correctToast = (message) => {
  toast.success(message, defaultToastOptions);
};

export const wrongToast = (message) => {
  toast.error(message, defaultToastOptions);
};

export const exchangeToast = (message) => {
  toast(message, defaultToastOptions);
};

export const editableToast = (message) => {
  toast(message, defaultToastOptions);
};

