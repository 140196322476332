import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./styles.css";
import { useTranslation } from "react-i18next";

const ToDo = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const create = () => {
      const inputText = document.getElementById("input-area");
      const landing = document.getElementById("ul");

      if (inputText.value === "") {
        return;
      }

      const node = document.createElement("li");
      node.innerText = inputText.value;
      node.classList.add("li-todo");

      const checkBox = document.createElement("input");
      checkBox.setAttribute("type", "checkbox");
      node.appendChild(checkBox);
      checkBox.classList.add("checkbox-todo");

      const deleteButton = document.createElement("span");
      deleteButton.setAttribute("type", "button");
      deleteButton.innerText = " ❌";
      node.appendChild(deleteButton);
      deleteButton.classList.add("checkbox-todo");

      const remove = () => {
        landing.removeChild(node);
      };
      deleteButton.onclick = remove;

      let index = 0;
      const lineThrough = () => {
        index += 1;
        if (index >= 2) {
          index = 0;
        }
        if (index === 1) {
          node.style.textDecoration = "line-through";
        } else if (index === 0) {
          node.style.textDecoration = "none";
        }
      };
      checkBox.onchange = lineThrough;

      landing.appendChild(node);
      inputText.value = "";
    };

    const pressEnter = (event) => {
      const keyCode = event.keyCode || event.which;
      if (keyCode === 13) {
        create();
      }
    };

    const submit = document.getElementById("submit");
    const inputText = document.getElementById("input-area");

    submit.addEventListener("click", create);
    inputText.addEventListener("keydown", pressEnter);

    return () => {
      submit.removeEventListener("click", create);
      inputText.removeEventListener("keydown", pressEnter);
    };
  }, []);

  return (
    <>
      <Header />
      <div id="wrapper-todo">
        <div id="condition">{t("writeToDos")}</div>
        <input type="text" id="input-area" maxLength="50" />
        <input type="button" id="submit" value="Enviar" />
        <div id="outcome-area">
          <ul id="ul"></ul>
        </div>
      </div>
    </>
  );
};

export default ToDo;
