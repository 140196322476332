import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./StudentProfile.css";

import Header from "../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import firebaseApp from "../credentials";
import Modal from "react-modal";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Business from "../data/Business";
import { useTranslation } from "react-i18next";
import Achievements from "../data/Achievements";
import { updateStudent } from "../features/students/studentsSlice";
import Lootbox from "../Components/lootbox/Lootbox";

const firestore = getFirestore(firebaseApp);

const StudentProfile = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students) || [];

  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (achievement) => {
    setSelectedAchievement(achievement);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // Función para obtener los detalles del negocio a partir de su ID
  const getBusinessDetails = (businessId) => {
    return Business.find((business) => business.id === businessId);
  };

  const { id } = useParams();
  const idSettings = parseInt(id);

  const previousStudentId = parseInt(id) - 1;
  const nextStudentId = parseInt(id) + 1;

  const currentStudent = students[id];

  const hasAchievement = (achievementId) => {
    return currentStudent && currentStudent.achievements
      ? currentStudent.achievements.includes(achievementId)
      : false;
  };

  async function updateStudentAchievements(studentId, achievementsToAdd) {
    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    const docSnap = await getDoc(docuRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      if (docData.students && docData.students[studentId]) {
        let updatedAchievements =
          docData.students[studentId].achievements || [];
        let newAchievementsCount = 0;

        achievementsToAdd.forEach((achievementId) => {
          if (!updatedAchievements.includes(achievementId)) {
            updatedAchievements.push(achievementId);
            newAchievementsCount++;
          }
        });

        if (newAchievementsCount > 0) {
          docData.students[studentId].achievements = updatedAchievements;

          await updateDoc(docuRef, docData);
          dispatch(
            updateStudent({
              id: studentId,
              updatedStudent: docData.students[studentId],
            })
          );
        }
        return newAchievementsCount;
      }
    }
    return 0;
  }

  const prevStudentRef = useRef();

  useEffect(() => {
    const prevStudent = prevStudentRef.current;
    prevStudentRef.current = currentStudent;

    if (
      currentStudent &&
      (!prevStudent ||
        prevStudent.points !== currentStudent.points ||
        prevStudent.pet !== currentStudent.pet ||
        (prevStudent.businesses &&
          currentStudent.businesses &&
          prevStudent.businesses.length !== currentStudent.businesses.length))
    ) {
      const achievementsToAdd = [];

      // Comprobar puntos y añadir logros según corresponda
      if (currentStudent.points >= 20) achievementsToAdd.push(1);
      if (currentStudent.points >= 40) achievementsToAdd.push(2);
      if (currentStudent.points >= 55) achievementsToAdd.push(3);

      // Comprobar si tiene una mascota (pet no es un string vacío)
      if (currentStudent.pet && currentStudent.pet.trim() !== "") {
        achievementsToAdd.push(4); // ID del logro para tener una mascota
      }

      // Comprobar negocios
      const businessCount = currentStudent.businesses
        ? currentStudent.businesses.length
        : 0;
      if (businessCount > 0) achievementsToAdd.push(5);
      if (businessCount >= 3) achievementsToAdd.push(6);

      //Attendance achievements
      if (currentStudent.diamonds >= 10) achievementsToAdd.push(7);
      if (currentStudent.diamonds >= 20) achievementsToAdd.push(8);
      if (currentStudent.diamonds >= 40) achievementsToAdd.push(9);
      if (currentStudent.diamonds >= 60) achievementsToAdd.push(10);

      // Actualizar logros del estudiante
      if (achievementsToAdd.length > 0) {
        updateStudentAchievements(currentStudent.id, achievementsToAdd).then(
          (newAchievementsCount) => {
            if (newAchievementsCount > 0) {
              correctToast(t("achievementUnlocked"));
            }
          }
        );
      }
    }
  }, [currentStudent]);

  const [openTabs, setOpenTabs] = useState({
    tab1: true,
    tab2: true,
    tab3: true,
    tab4: true,
  });

  const toggleAccordion = (tabName) => {
    setOpenTabs((prevOpenTabs) => ({
      ...prevOpenTabs,
      [tabName]: !prevOpenTabs[tabName],
    }));
  };
  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>
        {t("profile")} de {currentStudent.name}
      </h2>
      <div className="flex-wrapper">
        <div className="row">
          <div className="column-student-profile">
            <div className="student-profile-data">
              <div className="wrapper-business">
                <div className="clash - card - business1">
                  <div className="clash-card__image-avatar clash-card__image--avatar">
                    <img src={currentStudent.img} alt="business" />
                  </div>
                  <div className="clash-card__level clash-card__level--business1">
                    {t("profile")}
                  </div>
                  <div class="clash-card__unit-name">
                    {t(`${currentStudent.name}`)}{" "}
                    {t(`${currentStudent.surname}`)}
                  </div>
                  <div class="clash-card__unit-description"></div>
                  <div class="clash-card__unit-stats clash-card__unit-stats--business1 clearfix">
                    <div class="one-third">
                      <div class="stat">{currentStudent.points}</div>
                      <div class="stat-value">🟢 {t("points")}</div>
                    </div>

                    <div class="one-third">
                      <div class="stat">
                        {currentStudent.diamonds
                          ? currentStudent.diamonds
                          : "0"}
                      </div>
                      <div class="stat-value">💎 {t("diamonds")}</div>
                    </div>

                    <div class="one-third no-border">
                      {currentStudent.pet ? (
                        <div class="stat">{t("yes")}</div>
                      ) : (
                        <div class="stat">{t("no")}</div>
                      )}
                      <div class="stat-value">🐶 {t("pet")}</div>
                    </div>
                  </div>
                </div>
              </div>

              <p>
                <Link to={`/records/${id}`}>
                  <button className="custom-button btn-1">{t("record")}</button>
                </Link>
              </p>
              <p>
                <Link
                  className="records-link"
                  to={
                    previousStudentId >= 0
                      ? `/student-profile/${previousStudentId}`
                      : ""
                  }
                >
                  {t("previousStudent")}
                </Link>
                &nbsp;
                <Link
                  className="records-link"
                  to={
                    nextStudentId < students.length
                      ? `/student-profile/${nextStudentId}`
                      : ""
                  }
                >
                  {t("nextStudent")}
                </Link>
              </p>
            </div>
          </div>
          <div className="double-column">
            <div className="accordion">
              {/* Achievement accordion */}
              <div className="accordion-item">
                <button
                  className="accordion-button"
                  onClick={() => toggleAccordion("tab1")}
                >
                  {t("achievements")}
                </button>
                <div
                  className="accordion-content"
                  style={{ display: openTabs.tab1 ? "block" : "none" }}
                >
                  <div className="achievement-gallery-profile">
                    {Achievements.map((achievement) => (
                      <div
                        key={achievement.id}
                        className={`achievement-item ${
                          hasAchievement(achievement.id) ? "" : "gs"
                        }`}
                        title={t(`achievement${achievement.name}`)}
                        onClick={() => openModal(achievement)}
                      >
                        <img
                          className="achievement-image-profile"
                          src={achievement.img}
                          alt={achievement.name}
                        />
                        <figcaption>{t(`title${achievement.name}`)}</figcaption>
                      </div>
                    ))}
                    {selectedAchievement && (
                      <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        style={modalStyles}
                        contentLabel={`Detalle del logro ${selectedAchievement.name}`}
                      >
                        <h2>{t(`title${selectedAchievement.name}`)}</h2>
                        <p>{t(`achievement${selectedAchievement.name}`)}</p>
                      </Modal>
                    )}
                  </div>
                </div>
              </div>

              {/* Pet accordion */}
              <div className="accordion-item">
                <button
                  className="accordion-button"
                  onClick={() => toggleAccordion("tab2")}
                >
                  {t("pet")}
                </button>
                <div
                  className="accordion-content"
                  style={{ display: openTabs.tab2 ? "block" : "none" }}
                >
                  {currentStudent.pet ? (
                    <div>
                      <p>
                        <img src={currentStudent.pet} alt="pet" width="20%" />
                      </p>
                      <Link to={`/addPet/${students[id].id}?sell`}>
                        <button
                          className="custom-btn btn-11"
                          style={{ width: 230 }}
                        >
                          {t("removePet")}
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <Link to={`/addPet/${students[id].id}?buy`}>
                      <button
                        className="custom-btn btn-11"
                        style={{ width: 230 }}
                      >
                        {t("buy")}
                      </button>
                    </Link>
                  )}
                </div>
              </div>

              {/* Business accordion */}
              <div className="accordion-item">
                <button
                  className="accordion-button"
                  onClick={() => toggleAccordion("tab3")}
                >
                  {t("business")}
                </button>
                <div
                  className="accordion-content"
                  style={{ display: openTabs.tab3 ? "block" : "none" }}
                >
                  {currentStudent &&
                  currentStudent.businesses &&
                  currentStudent.businesses.length > 0 ? (
                    <div className="business-gallery-profile">
                      {currentStudent.businesses.map((businessId) => {
                        const business = getBusinessDetails(businessId);
                        return business ? (
                          <div className="business-item" key={businessId}>
                            <figure>
                              <img
                                className="business-image-profile"
                                src={business.img}
                                alt={business.name}
                              />
                              <figcaption>{t(`${business.name}`)}</figcaption>
                            </figure>
                          </div>
                        ) : null;
                      })}
                    </div>
                  ) : (
                    <p>
                      <Link to="/business-info">ℹ️</Link> - {t("noBusiness")}
                    </p>
                  )}
                  <Link to={`/add-business/${students[id].id}`}>
                    <button
                      className="custom-btn btn-11"
                      style={{ width: 230 }}
                    >
                      {t("buySell")}
                    </button>
                  </Link>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  className="accordion-button"
                  onClick={() => toggleAccordion("tab4")}
                >
                  {t("lootboxes")}
                </button>
                <div
                  className="accordion-content"
                  style={{ display: openTabs.tab4 ? "block" : "none" }}
                >
                  <div className="lootbox-row">
                    <Lootbox type="common" />
                    <Lootbox type="epic" />
                    <Lootbox type="legendary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>
        <Link className="btn btn-edit" to="/game">
          {t("goBack")}
        </Link>
      </p>
      <ToastContainer />
    </div>
  );
};

export default StudentProfile;
