import React from "react";
import Header from "../Header/Header";
import AvatarGroups from "../AvatarGroups/AvatarGroups";
import StudentGroupHeader from "../StudentGroupHeader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const RenderGroups = ({ correoUsuario }) => {
  const { t } = useTranslation();

  const students = useSelector((state) => state.students);
  const groups = useSelector((state) => state.groups.groups);

  return (
    <div className="App">
      <Header />
      <StudentGroupHeader />

      <div className="apps">
        <ul>
          {groups && groups.length > 0 ? (
            groups.map((group, index) => {
              return (
                <div key={index}>
                  <AvatarGroups
                    id={group.id}
                    name={group.name}
                    img={`/images/groups/${group.id}.png`}
                    arrayGroups={groups}
                    students={students}
                  />
                </div>
              );
            })
          ) : (
            <Link to="/settings">{t("createGroups")}</Link>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RenderGroups;
