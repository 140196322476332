const starterDailySentence = [
    { id: 1, text: "La vida comença cada cinc minuts" },
    { id: 2, text: "Trobaràs significat a la vida si el crees" },
    { id: 3, text: "On una porta es tanca, una altra s'obre" },
    { id: 4, text: "Els meus errors van ser llavors" },
    { id: 5, text: "Si la vida et dóna una llimona, fes llimonada" },
    { id: 6, text: "La felicitat no és una cosa feta, prové de les teves pròpies accions" },
    { id: 7, text: "La gent positiva canvia el món, mentre que la negativa el manté com està" },
    { id: 8, text: "Una actitud fortament positiva crearà més miracles que una de negativa" },
    { id: 9, text: "Tot pot tenir bellesa, inclús el més horrible" },
    { id: 10, text: "Ningú que hagi donat el millor de si mateix ho ha lamentat" },
    { id: 11, text: "Enamora't de la teva existència" },
    { id: 12, text: "Tota persona té capacitat per canviar-se a si mateixa" },
    { id: 13, text: "Hi ha una força motriu més poderosa que el vapor, l'electricitat i l'energia atòmica: la voluntat" },
    { id: 14, text: "No ets mai vell/a per tenir una altra meta o un altre somni" },
    { id: 15, text: "Siguem realistes i fem el que és impossible" },
    { id: 16, text: "El triomf veritable sorgeix de les cendres de l'error" },
    { id: 17, text: "No ploris perquè es va acabar, somriu perquè va passar" },
    { id: 18, text: "L'optimisme és la fe que condueix a l'èxit; res no es pot fer sense esperança" },
    { id: 19, text: "La paciència és amarga, però el seu fruit és dolç" },
    { id: 20, text: "Cada problema té a les mans un regal per a tu" },
    { id: 21, text: "Fins i tot la nit més fosca acabarà amb la sortida del Sol" },
    { id: 22, text: "Si mires el Sol, no veuràs les ombres" },
    { id: 23, text: "La felicitat acostuma a entrar per una porta que no sabies que havies deixat oberta" },
    { id: 24, text: "L'aprenentatge és un regal fins i tot quan el dolor és el teu mestre" },
    { id: 25, text: "Sóc optimista. No sembla gaire útil ser qualsevol altra cosa" },
    { id: 26, text: "Tothom pensem a canviar el món, però ningú no pensa a canviar-se a si mateix" },
    { id: 27, text: "Les persones canvien quan s'adonen del potencial que tenen per canviar la realitat" },
    { id: 28, text: "Cada dia em miro al mirall i em pregunto: 'Si avui fos l'últim dia de la meva vida, voldria fer el que faré avui?' Si la resposta és 'No' durant massa dies consecutius, sé que he de canviar alguna cosa" },
    { id: 29, text: "Riu cada dia i no hauràs desaprofitat ni un sol moment de la teva vida" },
    { id: 30, text: "Si exageréssim les nostres alegries com fem amb les nostres penes, els nostres problemes perdrien importància" },
    { id: 31, text: "El que no et mata et fa més fort" },
    { id: 32, text: "La saviesa més veritable és una resolta determinació" },
    { id: 33, text: "El fracàs és una bona oportunitat per començar de nou amb més intel·ligència" },
    { id: 34, text: "Tots els nostres somnis es poden tornar realitat si tenim la valentia de perseguir-los" },
    { id: 35, text: "La millor manera de predir el futur és creant-lo" },
    { id: 36, text: "Posseir menys claus permet obrir més portes" },
    { id: 37, text: "Una actitud sana és contagiosa. Deixa que els altres se n'impregnin" },
    { id: 38, text: "L'optimisme perpetu és un multiplicador de forces" },
    { id: 39, text: "La millor manera d'oblidar els mals moments de la vida és aprendre a recordar les coses bones" },
    { id: 40, text: "Quan una porta es tanca, moltes més s'obren" },
    { id: 41, text: "Sempre sembla impossible fins que es fa" },
    { id: 42, text: "La correcció fa molt, però la valentia fa més" },
    { id: 43, text: "Creu que la vida mereix ser viscuda i la creença ajudarà a crear el fet" },
    { id: 44, text: "Si pots somiar-ho, pots fer-ho" },
    { id: 45, text: "Agraeixo a tothom que em va dir que no podia. Gràcies a ells ho estic fent per mi mateix" },
    { id: 46, text: "L'entusiasme mou el món" },
    { id: 47, text: "El poder de la imaginació ens torna infinits" },
    { id: 48, text: "Sense pluja no hi hauria arc de Sant Martí" },
    { id: 49, text: "Dins la dificultat jau l'oportunitat" },
    { id: 50, text: "Un cop has triat l'opció de l'esperança, qualsevol cosa és possible" },
    { id: 51, text: "D'una petita llavor pot néixer un poderós arbre" },
  { id: 52, text: "A la vida hi ha tantes ocasions especials com de vegades triem celebrar-les" },
  { id: 53, text: "La vida no té més limitacions que les que hom es posa a si mateix" },
  { id: 54, text: "Qui és feliç farà altres feliços" },
  { id: 55, text: "De les nostres dificultats neixen miracles" },
  { id: 56, text: "Guanyar no ho és tot, però voler guanyar sí" },
  { id: 57, text: "Creu que pots i ja hauràs fet mig camí" },
  { id: 58, text: "No és mai tard per ser qui podries haver estat" },
  { id: 59, text: "La lentitud no és important, l'important és mantenir-se en el camí" },
  { id: 60, text: "Somiar és una manera de fer plans" },
  { id: 61, text: "Aprendre és un regal. Fins i tot el dolor és un mestre" },
  { id: 62, text: "Si pots canviar la teva ment, pots canviar el món" },
  { id: 63, text: "La diferència entre guanyar i perdre sol ser no donar-se per vençut" },
  { id: 64, text: "No importa la situació, recorda't aquesta idea: 'tinc opcions'" },
  { id: 65, text: "La vida no t'està passant. La vida t'està responent" },
  { id: 66, text: "Quan el camí sembli impossible, engega el motor" },
  { id: 67, text: "L'única discapacitat a la vida és la mala actitud" },
  { id: 68, text: "Fes que el teu optimisme es torni realitat" },
  { id: 69, text: "Aprèn a somriure en qualsevol situació. Pren-t'ho com una oportunitat per expressar la teva fortalesa" },
  { id: 70, text: "No podem controlar el vent, però sí ajustar les espelmes" },
  { id: 71, text: "El meu optimisme porta botes pesades i és sorollós" },
  { id: 72, text: "El pessimisme porta a la debilitat i l'optimisme al poder" },
  { id: 73, text: "La vida canvia molt ràpidament, i de manera positiva, si ho permets" },
  { id: 74, text: "El més important és mantenir la positivitat" },
  { id: 75, text: "L'única diferència entre un dia dolent i un de bo és la teva actitud" },
  { id: 76, text: "El pensament positiu et deixarà fer servir les habilitats que tens, i això és fantàstic" },
  { id: 77, text: "Tenir una actitud positiva és preguntar-se com es pot fer alguna cosa, més que dir que no es pot fer" },
  { id: 78, text: "Quan penses en positiu, les coses passen" },
  { id: 79, text: "No importa a qui coneguis al llarg de la teva vida, n'aprendràs alguna cosa, ja sigui positiu o negatiu" },
  { id: 80, text: "Mantén una ment positiva i riu-te de tot" },
  { id: 81, text: "El teu cor és ple de llavors esperant germinar" },
  { id: 82, text: "Envolta't de gent positiva" },
  { id: 83, text: "No ploris perquè s'ha acabat, somriu perquè ha passat" },
  { id: 84, text: "El que és negatiu és tan important com el que és positiu" },
  { id: 85, text: "Torna a intentar-ho. Fracassa de nou. Fracassa millor" },
  { id: 86, text: "Escriu al teu cor que cada dia és el millor dia de la teva vida" },
  { id: 87, text: "La felicitat no és l'absència de problemes sinó la capacitat de bregar amb ells" },
  { id: 88, text: "Totes les coses són difícils fins que són fàcils" },
  { id: 89, text: "La vida no té comandament a distància. Aixeca't i canvia-la pels teus propis mitjans" },
  { id: 90, text: "Un món millor no és només possible, sinó que està arribant" },
  { id: 91, text: "El millor tipus de felicitat és l'hàbit que t'apassiona" },
  { id: 92, text: "Aspira a ser la persona a qui més admiris" },
  { id: 93, text: "Som déus en una crisàlide" },
  { id: 94, text: "Mira enrere i somriu davant dels perills passats" },
  { id: 95, text: "L'amor és aquest micro-moment de calidesa i connexió que compartim amb un altre ésser viu" },
  { id: 96, text: "La bona vida és un procés, no un estat" },
  { id: 97, text: "No hi ha fracàs, només èxit inacabat" },
  { id: 98, text: "La felicitat no és allà fora perquè la trobem, i la raó d'això és que és dins nostre" },
  { id: 99, text: "Camino lentament, però mai camí cap enrere" },
  { id: 100, text: "Una bona rialla guareix moltes ferides" },
  { id: 101, text: "No importa l'edat, sempre hi ha alguna cosa bona per superar" },
  { id: 102, text: "La victòria sempre és possible per a qui es nega a rendir-se" },
  { id: 103, text: "Les coses més nobles i belles de la vida no estan fetes perquè escoltem històries sobre elles, per llegir sobre la seva existència o perquè les vegem, sinó que són per ser viscudes" },
  { id: 104, text: "La música és la poesia de l'aire" },
  { id: 105, text: "Les veritables històries d'amor no acaben mai" },
  { id: 106, text: "Un amic és algú amb qui t'atreveixes a ser tu mateix" },
  { id: 107, text: "Si mai no has fallat, mai has viscut" },
  { id: 108, text: "De les dificultats neixen miracles" },
  { id: 109, text: "La vida és com el jazz, millor si és improvisada" },
  { id: 110, text: "Per combatre la frustració cal trobar un equilibri entre allò individual i allò col·lectiu: aprendre a gestionar la nostra atenció ia compartir el món amb els altres" },
  { id: 111, text: "Si dónes llum, la foscor se n'anirà per si mateixa" },
  { id: 112, text: "El món és ple de màgia que espera pacientment que el nostre enginy desperti" },
  { id: 113, text: "Mai no hem fet un material tan resistent com l'ànima resilient" },
  { id: 114, text: "Il·lumina el demà amb l'avui!" },
  { id: 115, text: "En cadascun dels dies que vivim podem decidir canviar la nostra realitat o bé deixar-nos emportar" },
  { id: 116, text: "El pensament està sobrevalorat. Cal pensar menys i sentir més" },
  { id: 117, text: "El dol és necessari en certs moments de la vida. Igual de necessari que saber tornar-li a la nostra existència certs objectius que ens impulsin a continuar" }
  ];
  
  export default starterDailySentence;
  