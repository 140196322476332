import firebaseApp from "../../credentials";
import { updateStudent } from "../students/studentsSlice";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { addRecord } from "../students/studentsSlice";

import { deleteGroup } from "./groupsSlice";

const firestore = getFirestore(firebaseApp);

export const subtractPointFromGroup =
(groupId, points, actionName) => async (dispatch, getState) => {
  try {
    const currentStudents = getState().students;
    const currentGroups = getState().groups;
    const userEmail = getState().auth.userEmail;

    const group = currentGroups.groups.find((g) => g.id === groupId);

    if (group) {
      const studentsToUpdate = currentStudents.filter((student) =>
        group.students.includes(student.id)
      );

      // Prepara una estructura para actualizar Firebase
      let updates = {};

      studentsToUpdate.forEach((student) => {
        const studentPoints = Number(student.points);
        if (!isNaN(studentPoints)) {
          const updatedStudent = {
            ...student,
            points: studentPoints - points,
          };

          // Prepara la nueva entrada del records
          const newHistoryEntry = {
            date: new Date().toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }),
            amount: points,
            type: "-",
            description: actionName
          };

          // Agrega los cambios al objeto de actualizaciones
          updates[`students.${student.id}`] = {
            ...updatedStudent,
            records: [...(student.records || []), newHistoryEntry]
          };

          // Despacha una acción para actualizar el estado local
          dispatch(updateStudent({ id: student.id, updatedStudent }));
          dispatch(addRecord({ studentId: student.id, record: newHistoryEntry }));
        }
      });

      // Actualizar Firebase con todos los estudiantes actualizados
      const docRef = doc(firestore, "usuarios", userEmail);
      await updateDoc(docRef, updates);
    }
  } catch (error) {
    console.error("Error actualizando puntos del grupo en Firebase", error);
  }
};

  

  export const addPointToGroup =
  (groupId, points, actionName) => async (dispatch, getState) => {
    try {
      const currentStudents = getState().students;
      const currentGroups = getState().groups;
      const userEmail = getState().auth.userEmail;

      const group = currentGroups.groups.find((g) => g.id === groupId);

      if (group) {
        const studentsToUpdate = currentStudents.filter((student) =>
          group.students.includes(student.id)
        );

        // Prepara una estructura para actualizar Firebase
        let updates = {};

        studentsToUpdate.forEach((student) => {
          const studentPoints = Number(student.points);
          if (!isNaN(studentPoints)) {
            const updatedStudent = {
              ...student,
              points: studentPoints + points,
            };

            // Prepara la nueva entrada del records
            const newHistoryEntry = {
              date: new Date().toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }),
              amount: points,
              type: "+",
              description: actionName
            };

            // Agrega los cambios al objeto de actualizaciones
            updates[`students.${student.id}`] = {
              ...updatedStudent,
              records: [...(student.records || []), newHistoryEntry]
            };

            // Despacha una acción para actualizar el estado local
            dispatch(updateStudent({ id: student.id, updatedStudent }));
            dispatch(addRecord({ studentId: student.id, record: newHistoryEntry }));
          }
        });

        // Actualizar Firebase con todos los estudiantes actualizados
        const docRef = doc(firestore, "usuarios", userEmail);
        await updateDoc(docRef, updates);
      }
    } catch (error) {
      console.error("Error actualizando puntos del grupo en Firebase", error);
    }
  };


export const deleteGroupThunk =
  (id, currentGroups) => async (dispatch, getState) => {
    const userEmail = getState().auth.userEmail;

    const updatedGroups = currentGroups.filter((group) => group.id !== id);

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { groups: updatedGroups });

    // Despachar la acción para actualizar el estado en Redux
    dispatch(deleteGroup(id));
  };
