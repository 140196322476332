import React from "react";
import "./App.css";
import RenderAvatars from "./Components/RenderAvatars";
import Login from "./routes/Login";
import { useSelector } from "react-redux";

function App() {
  const userEmail = useSelector((state) => state.auth.userEmail);

  return (
    <>
      {userEmail ? (
        <RenderAvatars correoUsuario={userEmail} />
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
