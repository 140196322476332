import React, { useState } from "react";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateAccountType } from "../features/settings/settingsThunks";
import "./AdminPanel.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";

const AdminPanel = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(""); // Nuevo estado para el correo ingresado
  const [accountType, setAccountType] = useState("");
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSelectChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateAccountType(email, accountType));
    correctToast(t("successfulExchangePointsMsg"));
  };

  return (
    <>
      <Header />
      <h1>{t("adminPanel")}</h1>
      <div className="form-container-admin">
        <form onSubmit={handleSubmit}>
          <div className="form-group-admin">
            <label htmlFor="email" className="form-label-admin">
              {t("mail")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-input-admin"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
          <div className="form-group-admin">
            <label htmlFor="opciones" className="form-label-admin">
              {t("accountType")}
            </label>
            <select
              id="opciones"
              name="opciones"
              className="form-select-admin"
              value={accountType}
              onChange={handleSelectChange}
            >
              <option value="bronce">...</option>
              <option value="gold">Gold</option>
              <option value="silver">Silver</option>
              <option value="bronce">Bronce</option>
            </select>
          </div>
          <div className="form-group-admin">
            <button type="submit" className="form-button-admin">
              Enviar
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminPanel;
