import React from "react";
import Business from "../data/Business";
import { useTranslation } from "react-i18next";

function BusinessGallery() {
  const { t } = useTranslation();
  const cardClasses = [
    "business1",
    "business2",
    "business3",
    "business4",
    "business5",
  ];

  return (
    <div className="container">
      <ul className="image-gallery">
        {Business.slice(0, 20).map((business, index) => (
          <li key={business.value}>
            <div className="wrapper-business">
              <div
                className={`clash-card ${
                  cardClasses[index % cardClasses.length]
                }`}
              >
                <div
                  className={`clash-card__image clash-card__image--${
                    cardClasses[index % cardClasses.length]
                  }`}
                >
                  <img src={business.img} alt="business" />
                </div>
                <div
                  className={`clash-card__level clash-card__level--${
                    cardClasses[index % cardClasses.length]
                  }`}
                >
                  {t("investment")}
                </div>
                <div class="clash-card__unit-name">{t(`${business.name}`)}</div>
                <div class="clash-card__unit-description">
                  {/* {t(`business_description_${business.id}`)} */}
                </div>
                <div
                  class={`clash-card__unit-stats clash-card__unit-stats--${
                    cardClasses[index % cardClasses.length]
                  } clearfix`}
                >
                  <div class="one-third">
                    <div class="stat">{business.cost}</div>
                    <div class="stat-value">{t("cost")}</div>
                  </div>

                  <div class="one-third">
                    <div class="stat">{business.minReturn}</div>
                    <div class="stat-value">{t("return_min")}</div>
                  </div>

                  <div class="one-third no-border">
                    <div class="stat">{business.maxReturn}</div>
                    <div class="stat-value">{t("return_max")}</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        {Business.slice(21, 30).map((business, index) => (
          <li key={business.value}>
            <div className="wrapper-business">
              <div
                className={`clash-card ${
                  cardClasses[index % cardClasses.length]
                }`}
              >
                <div
                  className={`clash-card__image clash-card__image--${
                    cardClasses[index % cardClasses.length]
                  }`}
                >
                  <img src={business.img} alt="business" />
                </div>
                <div
                  className={`clash-card__level clash-card__level--${
                    cardClasses[index % cardClasses.length]
                  }`}
                >
                  {t("investment")}
                </div>
                <div class="clash-card__unit-name">{t(`${business.name}`)}</div>
                <div class="clash-card__unit-description">
                  {/* {t(`business_description_${business.id}`)} */}
                </div>
                <div
                  class={`clash-card__unit-stats clash-card__unit-stats--${
                    cardClasses[index % cardClasses.length]
                  } clearfix`}
                >
                  <div class="one-third">
                    <div class="stat-value">{t("lootboxes")}</div>
                  </div>

                  <div class="one-third">
                    <div class="stat">{business.minReturn}</div>
                    <div class="stat-value">{t("return_min")}</div>
                  </div>

                  <div class="one-third no-border">
                    <div class="stat">{business.maxReturn}</div>
                    <div class="stat-value">{t("return_max")}</div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BusinessGallery;
