const starterExchangesEs = [
  {
    id: 0,
    name: "Escoger una canción",
    emoji: "🎶",
    points: 1,
  },
  {
    id: 1,
    name: "Cambiar de nick",
    emoji: "📝",
    points: 5,
  },
  {
    id: 2,
    name: "Invocar el pergamino del destino",
    emoji: "📜",
    points: 5,
  },
  {
    id: 3,
    name: "Cambiar de chibit",
    emoji: "🎭",
    points: 5,
  },
  {
    id: 4,
    name: "Cambiar de lugar durante una semana",
    emoji: "🔄",
    points: 15,
  },
  {
    id: 5,
    name: "Mirar vídeos durante el desayuno",
    emoji: "🎬",
    points: 10,
  },
  {
    id: 6,
    name: "Hacer una exposición del tema que un alummno quiera",
    emoji: "📖",
    points: 15,
  },
  {
    id: 7,
    name: "Escoger qué haremos en clase",
    emoji: "💡",
    points: 99,
  },
];

export default starterExchangesEs;
