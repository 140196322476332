const starterDailySentenceEs = [
  { id: 0, text: "La vida comienza cada cinco minutos" },
  { id: 1, text: "Encontrarás significado en la vida si lo creas" },
  { id: 2, text: "Donde una puerta se cierra, otra se abre" },
  { id: 3, text: "Mis errores fueron entonces" },
  { id: 4, text: "Si la vida te da un limón, haz limonada" },
  { id: 5, text: "La felicidad no es algo hecho, proviene de tus propias acciones" },
  { id: 6, text: "La gente positiva cambia el mundo, mientras que la negativa lo mantiene como está" },
  { id: 7, text: "Una actitud fuertemente positiva va a crear más milagros que una negativa" },
  { id: 8, text: "Todo puede tener belleza, incluso lo más horrible" },
  { id: 9, text: "Nadie que haya dado lo mejor de sí mismo lo ha lamentado" },
  { id: 10, text: "Enamórate de tu existencia" },
  { id: 11, text: "Toda persona tiene capacidad para cambiarse a sí misma" },
  { id: 12, text: "Existe una fuerza motriz más poderosa que el vapor, la electricidad y la energía atómica: la voluntad" },
  { id: 13, text: "Nunca eres viejo para tener otra meta u otro sueño" },
  { id: 14, text: "Seamos realistas y hagamos lo imposible" },
  { id: 15, text: "El triunfo del verdadero hombre surge de las cenizas del error" },
  { id: 16, text: "No llores porque se acabó, sonríe porque pasó" },
  { id: 17, text: "El optimismo es la fe que conduce al éxito; nada se puede hacer sin esperanza" },
  { id: 18, text: "La paciencia es amarga, pero su fruto es dulce" },
  { id: 19, text: "Cada problema tiene en tus manos un regalo para ti" },
  { id: 20, text: "Incluso la noche más oscura acabará con la salida del Sol" },
  { id: 21, text: "Si miras al Sol, no verás las sombras" },
  { id: 22, text: "La felicidad suele colarse por una puerta que no sabías que habías dejado abierta" },
  { id: 23, text: "El aprendizaje es un regalo incluso cuando el dolor es tu maestro" },
  { id: 24, text: "Soy optimista. No parece muy útil ser cualquier otra cosa" },
  { id: 25, text: "Todos piensan en cambiar el mundo, pero nadie piensa en cambiarse a sí mismo" },
  { id: 26, text: "Las personas cambian cuando se dan cuenta del potencial que tienen para cambiar la realidad" },
  { id: 27, text: "Cada día me miro en el espejo y me pregunto: 'Si hoy fuera el último día de mi vida, ¿querría hacer lo que haré hoy?' Si la respuesta es 'No' durante demasiados días consecutivos, sé que tengo que cambiar algo" },
  { id: 28, text: "Ríete cada día y no habrás desperdiciado ni un solo momento de tu vida" },
  { id: 29, text: "Si exageráramos nuestras alegrías, como hacemos con nuestras penas, nuestros problemas perderían importancia" },
  { id: 30, text: "Lo que no te mata te hace más fuerte" },
  { id: 31, text: "La sabiduría más verdadera es una resuelta determinación" },
  { id: 32, text: "El fracaso es una buena oportunidad para empezar de nuevo con más inteligencia" },
  { id: 33, text: "Todos nuestros sueños pueden volverse realidad si tenemos la valentía de perseguirlos" },
  { id: 34, text: "La mejor manera de predecir el futuro es creándolo" },
  { id: 35, text: "Poseer menos llaves permite abrir más puertas" },
  { id: 36, text: "Una actitud sana es contagiosa Deja que otros se impregnen" },
  { id: 37, text: "El optimismo perpetuo es un multiplicador de fuerzas" },
  { id: 38, text: "La mejor manera de olvidar las malas cosas de la vida es aprender a recordar lo bueno" },
  { id: 39, text: "Cuando una puerta se cierra, muchas más se abren" },
  { id: 40, text: "Siempre parece imposible hasta que se hace" },
  { id: 41, text: "La corrección hace mucho, pero la valentía hace más" },
  { id: 42, text: "Cree que la vida merece ser vivida y la creencia ayudará a crear el hecho" },
  { id: 43, text: "Si puedes soñarlo, puedes hacerlo" },
  { id: 44, text: "Estoy agradecido a todos los que me dijeron no Gracias a ellos lo estoy haciendo por mí mismo" },
  { id: 45, text: "El entusiasmo mueve al mundo" },
  { id: 46, text: "El poder de la imaginación nos vuelve infinitos" },
  { id: 47, text: "Sin lluvia no habría arcoiris" },
  { id: 48, text: "Dentro de la dificultad yace la oportunidad" },
  { id: 49, text: "Una vez has elegido la opción de la esperanza, cualquier cosa es posible" },
  { id: 50, text: "El pesimismo lleva a la debilidad y el optimismo al poder" },
  { id: 51, text: "La vida cambia muy rápidamente, y de forma positiva, si la dejas" },
  { id: 52, text: "Lo importante es mantenerse positivo" },
  { id: 53, text: "La única diferencia entre un día malo y uno bueno es tu actitud" },
  { id: 54, text: "El pensamiento positivo te dejará utilizar las habilidades que tienes, y eso es fantástico" },
  { id: 55, text: "Tener una actitud de positiva es preguntarse cómo se puede hacer algo, más que decir que no se puede hacer" },
  { id: 56, text: "Cuando piensas en positivo, las cosas pasan" },
  { id: 57, text: "No importa a quien conozcas a lo largo de tu vida, tomarás algo, ya sea positivo o negativo" },
  { id: 58, text: "Mantiene una mente positiva y ríete de todo" },
  { id: 59, text: "Tu corazón está lleno de entonces esperando germinar" },
  { id: 60, text: "Rodéate de gente positiva" },
  { id: 61, text: "No llores porque ha terminado, sonríe porque ha pasado" },
  { id: 62, text: "Lo negativo es tan importante como lo positivo" },
  { id: 63, text: "Vuelve a intentarlo Fracasa de nuevo Fracasa mejor" },
  { id: 64, text: "Escribe en tu corazón que cada día es el mejor día de tu vida" },
  { id: 65, text: "La felicidad no es la ausencia de problemas sino la capacidad de lidiar con ellos" },
  { id: 66, text: "Todas las cosas son difíciles hasta que son fáciles" },
  { id: 67, text: "La vida no tiene mando a distancia. Levántate y cámbiala por tus propios medios" },
  { id: 68, text: "Un mundo mejor no es sólo posible, sino que está llegando" },
  { id: 69, text: "El mejor tipo de felicidad es el hábito que te apasiona" },
  { id: 70, text: "Aspira a ser la persona a la que más admires" },
  { id: 71, text: "Somos dioses en una crisálida" },
  { id: 72, text: "Mira atrás y sonríe ante los peligros pasados" },
  { id: 73, text: "El amor es ese micro-momento de calidez y conexión que compartimos con otro ser vivo" },
  { id: 74, text: "La buena vida es un proceso, no un estado" },
  { id: 75, text: "No hay fracaso, sólo éxito inacabado" },
  { id: 76, text: "La felicidad no está ahí fuera porque la encontramos, y la razón de esto es que está dentro de nosotros" },
  { id: 77, text: "Camino lentamente, pero nunca camino hacia atrás" },
  { id: 78, text: "Una buena risa cura muchas heridas" },
  { id: 79, text: "No importa la edad, siempre hay algo bueno que superar" },
  { id: 80, text: "La victoria siempre es posible para quien se niega a rendirse" },
  { id: 81, text: "Las cosas más nobles y bellas de la vida no están hechas porque escuchamos historias sobre ellas, por leemos sobre su existencia o porque las veamos, sino que son para ser vividas" },
  { id: 82, text: "La música es la poesía del aire" },
  { id: 83, text: "Las verdaderas historias de amor no terminan nunca" },
  { id: 84, text: "Un amigo es alguien con quien te atreves a ser tú mismo" },
  { id: 85, text: "Si nunca has fallado, nunca has vivido" },
  { id: 86, text: "De las dificultades nacen milagros" },
  { id: 87, text: "La vida es como el jazz, mejor si es improvisada" },
  { id: 88, text: "Para combatir la frustración hay que encontrar un equilibrio entre lo individual y lo colectivo: aprender a gestionar nuestra atención ya compartir el mundo con los demás" },
  { id: 89, text: "Si das luz, la oscuridad se irá por sí misma" },
  { id: 90, text: "El mundo está lleno de magia que espera pacientemente que nuestro ingenio se afine" },
  { id: 91, text: "El hombre nunca ha hecho un material tan resistente como el alma resiliente" },
  { id: 92, text: "¡Alumbra al mañana con el hoy!" },
  { id: 93, text: "Vivimos unos días, y en cada uno de ellos decidimos cambiar nuestra realidad o dejarnos llevar" },
  { id: 94, text: "El pensamiento está sobrevalorado. Hay que pensar menos y sentir más" },
  { id: 95, text: "El duelo es necesario en ciertos momentos de la vida. Igual de necesario que saber devolverle a nuestra existencia ciertos objetivos que nos impulsen a continuar" },
  { id: 96, text: "La vida es lo que pasa mientras estamos ocupados haciendo otros planes" },
  { id: 97, text: "Las oportunidades no son producto del azar, sino de mentes dispuestas a crearlas" },
  { id: 98, text: "El mayor placer de la vida es hacer lo que la gente te dice que no puedes hacer" },
  { id: 99, text: "La vida está llena de desafíos, pero esos desafíos son lo que nos hacen crecer" },
  { id: 100, text: "La vida es demasiado importante como para ser tomada en serio" },
  { id: 101, text: "Cada día es una nueva oportunidad para cambiar tu vida" },
  { id: 102, text: "Nuestra mayor gloria no reside en no haber caído nunca, sino en levantarnos cada vez que caemos" },
  { id: 103, text: "La vida es como una bicicleta. Para mantener el equilibrio, debes seguir adelante" },
  { id: 104, text: "La vida es una oportunidad, aprovéchala al máximo" },
  { id: 105, text: "El secreto de la vida es disfrutar de cada momento" },
];

export default starterDailySentenceEs;
