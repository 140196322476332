import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./styles.css";

import { useTranslation } from "react-i18next";

function Press() {
  const { t, i18n} = useTranslation();
  const language = i18n.language;

  const images = [
    { src: "../../images/press/portada-classbits-1.png", alt: "Portada 1" },
    { src: "../../images/press/portada-classbits-2.png", alt: "Portada 2" },
    { src: "../../images/press/portada-classbits-3.png", alt: "Portada 3" },
    { src: "../../images/press/portada-classbits-4.png", alt: "Portada 4" },
  ];

  const items = t("pressText").split("\n");

  return (
    <>
      <Header />
      <h1 className="pages-h1">{t("press")}</h1>
      <div className="center-paragraph">
        {items.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>

      <h2 className="pages-h1">{t("pressRelease")}</h2>
      <div className="center-paragraph">
      {i18n.language === "es" ? (
        <>
          <p><a href="../../pdf/PR-Classbits-1.pdf" target="_blank">Nota de prensa 1</a></p>
          <p><a href="../../pdf/PR-Classbits-2.pdf" target="_blank">Nota de prensa 2</a></p>
          <p><a href="../../pdf/PR-Classbits-3.pdf" target="_blank">Nota de prensa 3</a></p>
        </>
      ) : (
        <>
          <p><a href="../../pdf/PR-Classbits-3-cat.pdf" target="_blank">Nota de premsa 1</a></p>
        </>
      )}

      </div>

      <h2 className="pages-h1">{t("downloadables")}</h2>
      <p style={{ textAlign: "center", fontStyle: "italic" }}>
        {t("downloadablesSub")}
      </p>
      <div className="container">
        <ul className="image-gallery">
          {images.map((image, index) => (
            <li key={index}>
              <a download={image.alt} href={image.src}>
                <img src={image.src} alt={image.alt} />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
}
export default Press;
