import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const items = t("contactText").split("\n");
  return (
    <>
      <Header />
      <h1 className="pages-h1">{t("contact")}</h1>
      <div className="content">
        <div className="container containerTeam">
          <div className="info">
            {items.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            <img
              className="imagePages"
              src="../../images/chibi_cristian.png"
              alt="Chibi coder"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Contact;
