import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const BusinessAddList = () => {
  const students = useSelector((state) => state.students) || [];

  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(0);

  var statusMsg = "";

  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" }}>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("studentName")}</th>
              <th style={{ textAlign: "center" }}>{t("studentSurname")}</th>
              <th style={{ textAlign: "center" }}>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(students).map((id, index) => {
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{students[id].name}</td>
                  <td>{students[id].surname}</td>
                  <td>
                    <Link to={`/add-business/${students[id].id}`}>
                      <button className="button-add-pet">
                        💼 {t("business")}
                      </button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BusinessAddList;
