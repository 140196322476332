import React from "react";
import { useTranslation } from "react-i18next";

function EmojiGallery() {
  const { t } = useTranslation();
  const emojis = [
    "😂",
    "❤️",
    "😍",
    "🙌",
    "👍",
    "😊",
    "🎉",
    "😎",
    "🥰",
    "🤣",
    "😘",
    "🙏",
    "🤩",
    "🥳",
    "😁",
    "😜",
    "🤗",
    "🤔",
    "😌",
    "🌟",
    "😃",
    "😉",
    "🤭",
    "😅",
    "🤪",
    "🙈",
    "😊",
    "😇",
    "🤫",
    "🎊",
    "😆",
    "😮",
    "🤐",
    "🥺",
    "😥",
    "🤤",
    "🙊",
    "😓",
    "😔",
    "😴",
    "😑",
    "😩",
    "😵",
    "😞",
    "🤓",
    "😭",
    "😕",
    "😟",
    "😖",
    "😤",
    "😳",
    "😷",
    "🤡",
    "😬",
    "😠",
    "😑",
    "🙄",
    "😶",
    "🤯",
    "😱",
    "😪",
    "😓",
    "😕",
    "🤔",
    "🤫",
    "😷",
    "🙃",
    "😑",
    "😒",
    "🤮",
    "😡",
    "😋",
    "👏",
    "🤷",
    "🤢",
    "😘",
    "🙌",
    "😬",
    "🤥",
    "😢",
    "👀",
    "👉",
    "💕",
    "💔",
    "🙈",
    "👌",
    "💖",
    "💪",
    "😶",
    "💃",
    "👊",
    "🔥",
    "😡",
    "👑",
    "😆",
    "🙀",
    "💙",
    "🎶",
    "😨",
    "🙇",
    "🌈",
    "😛",
    "😓",
    "💛",
    "😑",
    "👻",
    "🍀",
    "😚",
    "💘",
    "😮",
    "💜",
    "💓",
    "💗",
    "💚",
    "😻",
    "😝",
    "🙊",
    "💥",
    "💞",
    "🤓",
    "🍻",
    "🙌",
    "💃",
    "😸",
    "💋",
    "😵",
    "🍃",
    "😼",
    "😢",
    "💅",
    "🌹",
    "🙋",
    "🌞",
    "😐",
    "🌺",
    "🌷",
    "😟",
    "🐾",
    "💐",
    "🌼",
    "🔥",
    "💀",
    "🎁",
    "😣",
    "🌴",
    "🎈",
    "😧",
    "🍁",
    "😔",
    "🌻",
    "🌸",
    "😖",
    "🎃",
    "🍂",
    "🌵",
    "🌍",
    "😶",
    "🍓",
    "🍎",
    "🌊",
    "🌴",
    "😦",
    "🍒",
    "😿",
    "🌐",
    "🍉",
    "🌅",
    "🍌",
    "🍇",
  ];

  const copyToClipboard = (emoji) => {
  navigator.clipboard.writeText(emoji)
    .then(() => {
      alert(t("alertEmojiCopied") + emoji);
    })
    .catch(err => {
      console.error('Error:', err);
    });
};

  return (
    <div className="container">
      <ul className="emoji-list">
        {emojis.map((emoji, index) => (
          <li className="emoji-item" key={index}>
            <span
              className="emoji"
              onClick={() => copyToClipboard(emoji)}
              role="button"
              tabIndex={0}
            >
              {emoji}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EmojiGallery;
