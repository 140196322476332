import React from "react";
import "./styles.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import { useTranslation } from "react-i18next";

function Team() {
  const { t } = useTranslation();

  const items = t("teamText").split("\n");
  return (
    <>
      <Header />
      <h1 className="pages-h1">{t("teamH1")}</h1>
      <div className="content">
        <div className="container containerTeam">
          <div className="info">
            <h2>{t("teamH2")}</h2>
            <div></div>
            {items.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
          <img className="imagePages" src="../../images/chibi_cristian.png" />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Team;
