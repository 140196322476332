import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./AddEdit.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  updateStudentPetThunk,
  updateStudentPoints,
} from "../features/students/studentsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const AddPet = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);
  const pets = useSelector((state) => state.pets);
  const petAction = window.location.search.slice(1);
  const [selectedPet, setSelectedPet] = useState(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  function updateReRender() {
    navigate("/Pets");
  }

  async function formId(e) {
    e.preventDefault();
    const formElement = document.getElementById("formId");
    const formData = new FormData(formElement);

    const petName = formData.get("pet");
    const petCost = formData.get("petCost");

    const petImg =
      petName === "Borrar"
        ? ""
        : pets.find((pet) => pet.name === petName)?.img || "";

    const payload = {
      id,
      pet: petImg,
      userEmail,
    };

    try {
      await dispatch(updateStudentPetThunk(payload));

      if (petAction === "buy" && petCost) {
        await dispatch(
          updateStudentPoints(userEmail, id, -petCost, t("petTransaction"))
        );
      }

      correctToast(t("addPetMsg"));
      setTimeout(() => updateReRender(), 1000);
    } catch (error) {
      console.error("Error updating student pet:", error);
      // Manejo de errores opcional
    }
  }

  function handleDelete() {
    setSelectedPet({ value: "Borrar", label: "Borrar" });
    document.getElementById("formId").dispatchEvent(new Event("submit"));
  }

  const petOptions = [
    { value: "Bluy", label: "Bluy", img: "../images/pets/Bluy.png" },
    { value: "Catty", label: "Catty", img: "../images/pets/Catty.png" },
    { value: "Creeper", label: "Froggy", img: "../images/pets/Creeper.png" },
    { value: "Dino", label: "Dino", img: "../images/pets/Dino.png" },
    { value: "Doggy", label: "Doggy", img: "../images/pets/Doggy.png" },
    { value: "Firy", label: "Firy", img: "../images/pets/Firy.png" },
    { value: "Fishy", label: "Fishy", img: "../images/pets/Fishy.png" },
    { value: "Hamster", label: "Hamster", img: "../images/pets/Hamster.png" },
    {
      value: "Hedgehog",
      label: "Hedgehog",
      img: "../images/pets/Hedgehog.png",
    },
    { value: "Parrot", label: "Parrot", img: "../images/pets/Parrot.png" },
    { value: "Pinky", label: "Pinky", img: "../images/pets/Pinky.png" },
    { value: "Unicorn", label: "Unicorn", img: "../images/pets/Unicorn.png" },
    { value: "Horsy", label: "Horsy", img: "../images/pets/Horsy.png" },
    { value: "Lion", label: "Lion", img: "../images/pets/Lion.png" },
    { value: "Octopus", label: "Octopus", img: "../images/pets/Octopus.png" },
    { value: "Penguin", label: "Penguin", img: "../images/pets/Penguin.png" },
    { value: "Rabby", label: "Rabby", img: "../images/pets/Rabby.png" },
    { value: "Robby", label: "Robby", img: "../images/pets/Robby.png" },
    { value: "Sharky", label: "Sharky", img: "../images/pets/Sharky.png" },
    { value: "Torti", label: "Torti", img: "../images/pets/Torti.png" },
    { value: "Cheetah", label: "Cheetah", img: "../images/pets/Cheetah.png" },
    { value: "Girif", label: "Girif", img: "../images/pets/Girif.png" },
    { value: "Happy", label: "Happy", img: "../images/pets/Happy.png" },
    { value: "Koala", label: "Koala", img: "../images/pets/Koala.png" },
    { value: "Komodo", label: "Komodo", img: "../images/pets/Komodo.png" },
    { value: "Phanty", label: "Phanty", img: "../images/pets/Phanty.png" },
    { value: "Rhyno", label: "Rhyno", img: "../images/pets/Rhyno.png" },
    { value: "Snaky", label: "Snaky", img: "../images/pets/Snaky.png" },
    { value: "Tiger", label: "Tiger", img: "../images/pets/Tiger.png" },
  ];

  const handleImageClick = (petValue) => {
    const petOption = petOptions.find((pet) => pet.value === petValue);
    setSelectedPet(petOption);
    topFunction();
  };

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <>
      <div>
        <Header />
        {petAction === "buy" && (
          <h2 style={{ textAlign: "center" }}>
            {t("addPetTo")}{" "}
            {students[id] ? `${students[id].name} ${students[id].surname}` : ""}
          </h2>
        )}
        <form
          onSubmit={formId}
          id="formId"
          style={{
            margin: "auto",
            padding: "15px",
            maxWidth: "400px",
            alignContent: "center",
          }}
        >
          {petAction === "buy" && (
            <>
              <label htmlFor="petInput">{t("pet")}</label>
              <Select
                id="petInput"
                name="pet"
                options={petOptions}
                value={selectedPet}
                onChange={setSelectedPet}
                getOptionLabel={(option) => (
                  <div>
                    <img
                      src={option.img}
                      alt={option.label}
                      style={{ width: "150px", marginRight: "10px" }}
                    />
                  </div>
                )}
                placeholder="Click"
              />
              <input
                name="petCost"
                id="petCost"
                type="number"
                placeholder={t("petPointsCost")}
              />
              <input type="submit" value={t("buy")} />
            </>
          )}
          {petAction === "sell" && (
            <input
              type="submit"
              onClick={handleDelete}
              style={{
                backgroundColor: "red",
                color: "white",
                width: "100%",
                padding: "10px",
              }}
              value={t("removePet")}
            />
          )}
        </form>
      </div>
      {petAction !== "sell" && (
        <div>
          <h2 style={{ textAlign: "center" }}>{t("subtitleAddPet")}</h2>
          <div className="container">
            <ul className="image-gallery">
              {petOptions.map((pet) => (
                <li key={pet.value}>
                  <img
                    src={pet.img}
                    alt={pet.label}
                    onClick={() => handleImageClick(pet.value)}
                  />
                  <div
                    className="overlay"
                    onClick={() => handleImageClick(pet.value)}
                  >
                    <span>{pet.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AddPet;
