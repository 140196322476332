import firebaseApp from "../../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

import {
  addSavedClassroom,
  deleteSavedClassroom,
  deleteEmail,
} from "./inviteSlice";

const firestore = getFirestore(firebaseApp);

export const deleteInviteThunk =
  (emailToRemove, currentEmails) => async (dispatch, getState) => {
    const userEmail = getState().auth.userEmail;

    const updatedEmails = currentEmails.filter(
      (email) => email !== emailToRemove
    );

    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { emails: updatedEmails });

    dispatch(deleteEmail(emailToRemove));
  };

export const deleteSavedClassroomThunk =
  (userEmail, classroomId, currentSavedClassrooms) => async (dispatch) => {
    const updatedSavedClassrooms = currentSavedClassrooms.filter(
      (classroom) => classroom.id !== classroomId
    );

    const docRef = doc(getFirestore(), `usuarios/${userEmail}`);

    try {
      await updateDoc(docRef, { savedClassrooms: updatedSavedClassrooms });

      dispatch(deleteSavedClassroom(classroomId));
    } catch (error) {
      console.error("Error removing saved classroom: ", error);
    }
  };

export const addSavedClassroomThunk =
  (userEmail, savedClassroomData) => async (dispatch, getState) => {
    const lastSavedClassroom = getState().invites.savedClassrooms.slice(-1)[0];
    const newSavedClass = {
      id: lastSavedClassroom ? lastSavedClassroom.id + 1 : 1,
      ...savedClassroomData,
    };

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const currentSavedClassrooms = getState().invites.savedClassrooms;
    // Añade el nuevo exchange a la lista
    const newSavedClassroom = [...currentSavedClassrooms, newSavedClass];

    await updateDoc(docuRef, { savedClassrooms: newSavedClassroom });

    // Despachar acción para actualizar el estado en el store
    dispatch(addSavedClassroom(newSavedClass));
  };
