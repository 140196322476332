import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AddEdit.css";

import Header from "../Components/Header/Header";
import EmojiGallery from "./EmojiGallery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateExchangeThunk } from "../features/behaviours/behavioursThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

import { useTranslation } from "react-i18next";

var idSettings = 0;

const EditExchange = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const exchanges = useSelector((state) => state.behaviours.exchanges);

  const [name, setName] = useState("");
  const [emoji, setEmoji] = useState("");
  const [points, setPoints] = useState("");

  const { id } = useParams();
  const idSettings = parseInt(id);

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/editBehaviourE");
  }

  useEffect(() => {
    async function fetchSettings() {
      // Find the setting that matches the id from the URL
      const setting = exchanges.find((setting) => setting.id === idSettings);
      // Set the form data based on this setting
      if (setting) {
        setName(setting.name);
        setEmoji(setting.emoji);
        setPoints(setting.points);
      }
    }
    fetchSettings();
  }, []);

  async function editStudentForm(e) {
    e.preventDefault();
    const formElement = document.getElementById("addStudentForm");
    const formData = new FormData(formElement);

    const data = {
      id: idSettings,
      name: formData.get("name"),
      emoji: formData.get("emoji"),
      points: parseInt(formData.get("points")),
    };

    // Encuentra el índice del exchange que deseas actualizar
    const index = exchanges.findIndex((exchange) => exchange.id === idSettings);

    if (index !== -1) {
      // Si se encuentra el exchange, actualízalo
      dispatch(updateExchangeThunk(userEmail, index, data));
      correctToast(t("editExchangeMsg"));
      setTimeout(() => updateReRender(), 1000);
    }
  }
  const exchangeId = exchanges.find((p) => p.id === idSettings);

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>
        Editar {exchangeId ? exchangeId.name : ""}
      </h2>
      <form
        onSubmit={editStudentForm}
        id="addStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlfor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlfor="nameInput">{t("exchange")}</label>
        <input
          type="text"
          id="nameInput"
          name="name"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlfor="surnameInput">Emoji</label>
        <input
          type="text"
          id="surnameInput"
          name="emoji"
          value={emoji}
          onChange={(e) => setEmoji(e.target.value)}
        />

        <label htmlfor="pointsInput">{t("points")}</label>
        <input
          type="number"
          id="pointsInput"
          name="points"
          required
          value={points}
          onChange={(e) => setPoints(parseInt(e.target.value))}
        />

        <input type="submit" value="Guardar" />
      </form>
      <h2 style={{ textAlign: "center" }}>Emojis</h2>
      <EmojiGallery />
      <ToastContainer />
    </div>
  );
};

export default EditExchange;
