import React from "react";
import "./AddEdit.css";

import Header from "../Components/Header/Header";

import { useTranslation } from "react-i18next";
import EmojiGallery from "./EmojiGallery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNegativeRedux } from "../features/behaviours/behavioursThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const AddNegative = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const negatives = useSelector((state) => state.behaviours.negatives);

  const { t } = useTranslation();

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/addNegative");
  }

  async function addStudentForm(e) {
    e.preventDefault();
    let lastId = negatives.slice(-1)[0]?.id || 0;
    const formElement = document.getElementById("addStudentForm");
    const formData = new FormData(formElement);
    const newNegative = {
      id: lastId + 1,
      name: formData.get("name"),
      emoji: formData.get("emoji"),
      points: parseInt(formData.get("points")),
    };

    //update redux & db
    dispatch(addNegativeRedux(userEmail, newNegative));
    correctToast(t("addNegativeMsg"));
    setTimeout(function () {
      updateReRender();
      formElement.reset();
    }, 500);
  }

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>{t("addNegativeTitle")}</h2>
      <form
        onSubmit={addStudentForm}
        id="addStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlfor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlfor="nameInput">Conducta negativa</label>
        <input type="text" id="nameInput" name="name" required />

        <label htmlfor="surnameInput">Emoji</label>
        <input type="text" id="surnameInput" name="emoji" />

        <label htmlfor="pictureInput">{t("pointsToSubstract")}</label>
        <input type="number" min="0" id="pictureInput" name="points" required />

        <input type="submit" value="Guardar" />
      </form>
      <h2 style={{ textAlign: "center" }}>Emojis</h2>
      <EmojiGallery />
      <ToastContainer />
    </div>
  );
};

export default AddNegative;
