import React, { useState, useEffect } from "react";
import "./AddEdit.css";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ChibiGallery from "./ChibiGallery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStudents } from "../features/students/studentsSlice";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

import firebaseApp from "../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore(firebaseApp);

const AddStudent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const [selectedImage, setSelectedImage] = useState(null);
  const [numberList, setNumberList] = useState([]);

  const students = useSelector((state) => state.students);

  useEffect(() => {
    const generateNumberList = () => {
      const numbers = [];
      for (let i = 1; i <= 110; i++) {
        numbers.push(i);
      }
      return numbers;
    };

    setNumberList(generateNumberList());
  }, []);

  const navigate = useNavigate();

  function updateReRender() {
    navigate("/add");
  }

  async function addStudentForm(e) {
    e.preventDefault();
    const formElement = document.getElementById("addStudentForm");
    const formData = new FormData(formElement);

    // Generate new student
    const newStudent = {
      id: (students.slice(-1)[0]?.id || 0) + 1,
      name: formData.get("name"),
      surname: formData.get("surname"),
      img: selectedImage
        ? `../../images/chibits/${selectedImage.value}.png`
        : "",
      pet: "",
      points: 0,
    };

    const updatedStudents = [...students, newStudent];
    dispatch(setStudents(updatedStudents));

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    try {
      // Only update the students list
      await updateDoc(docuRef, { students: updatedStudents });
      correctToast(t("studentAddedMsg"));
    } catch (error) {
      console.error("Error actualizando el documento:", error);
      //roll back in redux with previous data if something went wrong
      dispatch(setStudents(students));
    }

    setTimeout(() => {
      updateReRender();
      formElement.reset();
      setSelectedImage(null);
    }, 500);
  }

  const imageOptions = numberList.map((number) => ({
    value: number,
    label: (
      <div>
        <span>
          {number}
          <img
            src={`../../images/chibits/${number}.png`}
            alt={`Image ${number}`}
            style={{ width: "150px", marginRight: "10px" }}
          />
        </span>
      </div>
    ),
  }));

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const handleImageSelect = (selectedOption) => {
    setSelectedImage(selectedOption);
    topFunction();
  };

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>{t("addStudentTitle")}</h2>
      <form
        onSubmit={addStudentForm}
        id="addStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlFor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlFor="nameInput">{t("studentName")}</label>
        <input type="text" id="nameInput" name="name" required />

        <label htmlFor="surnameInput">{t("studentSurname")}</label>
        <input type="text" id="surnameInput" name="surname" />

        <label htmlFor="pictureInput">{t("image")}</label>
        <Select
          styles={{
            dropdownIndicator: (base) => ({
              ...base,
              position: "absolute",
              top: "50%",
              right: "8px",
              transform: "translateY(-50%)",
              width: "40px",
              height: "40px",
            }),
          }}
          options={imageOptions}
          onChange={handleImageSelect}
          value={selectedImage}
        />

        <input type="submit" value="Guardar" />
      </form>
      <ChibiGallery onImageClick={handleImageSelect} />
      <ToastContainer />
    </div>
  );
};

export default AddStudent;
