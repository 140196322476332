import React, { useState, useEffect, useRef } from "react";
import "./AddEdit.css";

import { useTranslation } from "react-i18next";
import Select from "react-select";
import ChibiGallery from "./ChibiGallery";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { publishStudentsToDatabase } from "../features/students/studentsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

function AddClassroom() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [arrayStudents, setArrayStudents] = useState([]);
  const [newStudentData, setNewStudentData] = useState({
    id:
      arrayStudents.length > 0
        ? arrayStudents[arrayStudents.length - 1].id + 1
        : 1,
    name: "",
    surname: "",
    img: "",
    pet: "",
    points: 0,
  });
  const [publishing, setPublishing] = useState(false);
  const [newStudentsToPublish, setNewStudentsToPublish] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [numberList, setNumberList] = useState([]);

  useEffect(() => {
    async function fetchStudents() {
      try {
        const fetchedStudents = students;
        setArrayStudents(fetchedStudents || []);
      } catch (error) {
        setShowErrorMessage("Se produjo un error al obtener los estudiantes.");
      }
    }
    fetchStudents();
  }, []);

  useEffect(() => {
    if (arrayStudents.length > 0) {
      const lastId = arrayStudents[arrayStudents.length - 1].id;
      setNewStudentData((prevData) => ({
        ...prevData,
        id: lastId + 1,
      }));
    }
  }, [arrayStudents]);

  const resetForm = () => {
    setNewStudentData((prevData) => ({
      ...prevData,
      name: "",
      surname: "",
      img: "",
      pet: "",
      points: 0,
    }));
    setSelectedImage(null);
  };

  useEffect(() => {
    const generateNumberList = () => {
      const numbers = [];
      for (let i = 1; i <= 110; i++) {
        numbers.push(i);
      }
      setNumberList(numbers);
    };

    generateNumberList();
  }, []);

  async function addStudentForm(e) {
    e.preventDefault();

    const { name, surname, pet, points } = newStudentData;
    const selectedNumber = selectedImage ? selectedImage.value : "";
    const img = `../../images/chibits/${selectedNumber}.png`;
    const newStudent = {
      id: newStudentData.id,
      name,
      surname,
      img,
      pet,
      points,
    };

    // Asegurarse de que el estudiante no exista ya en arrayStudents o newStudentsToPublish
    const studentAlreadyExists = arrayStudents
      .concat(newStudentsToPublish)
      .some((student) => student.id === newStudent.id);

    if (!studentAlreadyExists) {
      setNewStudentsToPublish((prevStudents) => [...prevStudents, newStudent]);
    }

    setNewStudentData((prevData) => ({
      ...prevData,
      id: prevData.id + 1,
      name: "",
      surname: "",
      img: "",
      pet: "",
      points: 0,
    }));
    resetForm();
  }

  async function publishStudentsToDatabaseWrapper() {
    if (newStudentsToPublish.length === 0) {
      return;
    }

    setPublishing(true);
    const newArrayStudents = [...arrayStudents, ...newStudentsToPublish];
    try {
      // Llama al thunk para actualizar Firebase y Redux.
      dispatch(publishStudentsToDatabase(userEmail, newArrayStudents));
      setArrayStudents(newArrayStudents);
      setNewStudentsToPublish([]);
      setPublishing(false);
      correctToast(t("studentsAddedMsg"));
      return navigate("/game");
    } catch (error) {
      console.error("Error al publicar estudiantes:", error);
      setShowErrorMessage("Se produjo un error al publicar los estudiantes.");
    }
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const handleImageSelect = (selectedOption) => {
    setSelectedImage(selectedOption);
    topFunction();
  };

  return (
    <div>
      <div className="row addClassroom-row">
        <div className="column">
          <h2 style={{ textAlign: "center" }}>{t("addStudentTitle")}</h2>
          {showErrorMessage && (
            <p className="errorWarning">{showErrorMessage}</p>
          )}
          <form
            onSubmit={addStudentForm}
            style={{
              margin: "auto",
              padding: "15px",
              maxWidth: "400px",
              alignContent: "center",
            }}
          >
            <label htmlFor="nameInput">{t("studentName")}</label>
            <input
              type="text"
              id="nameInput"
              name="name"
              required
              value={newStudentData.name}
              onChange={(e) =>
                setNewStudentData((prevData) => ({
                  ...prevData,
                  name: e.target.value,
                }))
              }
            />

            <label htmlFor="surnameInput">{t("studentSurname")}</label>
            <input
              type="text"
              id="surnameInput"
              name="surname"
              value={newStudentData.surname}
              onChange={(e) =>
                setNewStudentData((prevData) => ({
                  ...prevData,
                  surname: e.target.value,
                }))
              }
            />

            <label htmlFor="pictureInput">{t("image")}</label>
            <Select
              value={selectedImage}
              styles={{
                dropdownIndicator: (base) => ({
                  ...base,
                  position: "absolute",
                  top: "50%",
                  right: "8px",
                  transform: "translateY(-50%)",
                  width: "40px", // Ajusta el ancho del SVG
                  height: "40px", // Ajusta la altura del SVG
                }),
              }}
              options={numberList.map((number) => ({
                value: number,
                label: (
                  <div>
                    <span>
                      {number}
                      <img
                        src={`../../images/chibits/${number}.png`}
                        alt={`Image ${number}`}
                        style={{ width: "150px", marginRight: "10px" }}
                      />
                    </span>
                  </div>
                ),
              }))}
              onChange={(selectedOption) => {
                setSelectedImage(selectedOption);
                const selectedNumber = selectedOption?.value || "";
                setNewStudentData((prevData) => ({
                  ...prevData,
                  img: `../../images/chibits/${selectedNumber}.png`,
                }));
              }}
            />

            <input type="submit" value="Guardar" />
          </form>
        </div>
        <div className="column">
          <h2 className="h2StudentsArrayColumn" style={{ textAlign: "center" }}>
            {t("studentsAmountToBePublished")}: {newStudentsToPublish.length}
          </h2>
          {newStudentsToPublish.length > 0 && (
            <>
              <div>
                <h3 style={{ textAlign: "center" }}>
                  {t("studentsToBePublished")}
                </h3>
                <ul className="classListState">
                  {newStudentsToPublish.map((student) => (
                    <li key={student.id}>
                      <img src={student.img} width="30px" /> {student.name}{" "}
                      {student.surname}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ padding: "25px" }}></div>
      {newStudentsToPublish.length > 0 && (
        <button
          className="publishingClassBtn"
          style={{ textAlign: "center", background: "red" }}
          onClick={publishStudentsToDatabaseWrapper}
          disabled={publishing}
        >
          {t("publishClass")}
        </button>
      )}
      {/* Image Grid*/}
      <ChibiGallery onImageClick={handleImageSelect} />

      <ToastContainer />
    </div>
  );
}

export default AddClassroom;
