const starterPergaminoES = [
  {
    id: 0,
    text: "Los reyes de la pista: Tres personas que quieran salen a bailar una canción",
  },
  {
    id: 1,
    text: "De Padawan a Jedi: Alguien aleatorio se prepara una clase de 5 minutos para el próximo día de lo que quiera",
  },
  {
    id: 2,
    text: "La hora cómica: quien quiera que cuente un chiste",
  },
  {
    id: 3,
    text: "The chair is lava: Toda la clase 30 minutos de pie",
  },
  {
    id: 4,
    text: "Dumbledore se ha llevado todas las luces: Haremos una clase a oscuras",
  },
  {
    id: 5,
    text: "Aprender es tan importante como leer. 30 minutos de clase y el resto de lectura",
  },
  {
    id: 6,
    text: "Un voluntario debe bailar la canción que algún compañero aleatorio haya escogido",
  },
  {
    id: 7,
    text: "Tiempo de cine: Toda la clase tendrá que hacer un resumen/reseña de alguna película que hayan visto",
  },
  {
    id: 8,
    text: "Ring ring ring, te ha tocado la bonoloto. Toda la clase ha ganado 2 puntos.",
  },
  {
    id: 9,
    text: "Abriendo el baúl de los recuerdos… El profesor escoge una actividad para repasar lo que se ha hecho durante el curso",
  },
  {
    id: 10,
    text: "Un huracán ha pasado y la voz se nos ha llevado. ¡Durante la siguiente hora sólo podéis hablar con signos!",
  },
  {
    id: 11,
    text: "Hola historiadores, hoy tenéis que escribir la biografía/descripción de alguien (mínimo 5 líneas)",
  },
  {
    id: 12,
    text: "Chiquito de la calzada jooooor... alguien aleatorio/voluntario debe contar alguna historia graciosa/monólogo",
  },
  {
    id: 13,
    text: "La voz kids: La clase cantará el karaoke que el profesor diga",
  },
  {
    id: 14,
    text: "El monstruo de los zapatos ha pasado por la clase. Durante la siguiente hora, ¡todos con los zapatos fuera!",
  },
  {
    id: 15,
    text: "Tiempo de teatro. Alguien aleatorio debe representar con mímica una serie o película y la clase debe adivinarlo",
  },
  {
    id: 16,
    text: "El monstruo de las vocales ha venido. La próxima vez que se pase lista, no se pueden pronunciar las vocales de los nombres",
  },
  {
    id: 17,
    text: "Apadrine a un maestro. Alguien aleatorio pasa lista en vez del profesor",
  },
  {
    id: 18,
    text: "La información está en los detalles… Tienes 30 segundos para dibujar un pequeño objeto de la clase",
  },
  {
    id: 19,
    text: "¡Toca volver a conectar con la naturaleza! Todos 15 minutos sentados en el suelo",
  },
  {
    id: 20,
    text: "¡Mala suerte! Debe hacer un ejercicio extra de deberes o una división",
  },
  {
    id: 21,
    text: "Una aurora boreal ha pasado por encima de la escuela, todos tenemos 5 minutos para relajarnos y observarla",
  },
  {
    id: 22,
    text: "Director de orquesta se le requiere! Alguien aleatorio escoge una canción y toda la clase debe cantarla o bailarla",
  },
  {
    id: 23,
    text: "Dejémonos llevar por los sonidos. Dibújeme la música que el profesor nos ponga",
  },
  {
    id: 24,
    text: "Tres personas aleatorias deben salir a contar un cuento o un chiste",
  },
  {
    id: 25,
    text: "Batidora de puestos: toda la clase se cambia de sitio… ¡nadie puede quedarse donde está!",
  },
  {
    id: 26,
    text: "Ha llegado el hipnotizador… alguien aleatorio tendrá que actuar como una gallina durante la próxima clase",
  },
  {
    id: 27,
    text: "“Al agua patos”. 2 personas aleatorias pueden ir a beber agua en cualquier momento durante todo el día",
  },
  {
    id: 28,
    text: "¡Virtualícenos! Toda la clase puede dibujar el avatar que le gustaría tener en un videojuego",
  },
  {
    id: 29,
    text: "El primero y último de la lista eligen una canción para escuchar en clase",
  },
  {
    id: 30,
    text: "Tiempo era tiempo… El maestro debe contar alguna anécdota divertida",
  },
  {
    id: 31,
    text: "Es tu día de suerte, ¡hoy no hay deberes para nadie!",
  },
  {
    id: 32,
    text: "Dalí y Picasso te necesitan! Haz un dibujo en la pizarra, después la clase tendrá que adivinar qué es",
  },
  {
    id: 33,
    text: "Retos matemáticos… El primero en hacer bien las 3 multiplicaciones que el docente pondrá en la pizarra, puede escoger una canción",
  },
  {
    id: 34,
    text: "Escape room verbal… ¡tengo que solucionar el enigma que su maestro le propondrá!",
  },
  {
    id: 35,
    text: "Los astros se han alineado… ¡5 minutos más de patio para todos!",
  },
  {
    id: 36,
    text: "¡La bruja fresa os ha lanzado un hechizo! Durante 30 minutos nadie puede separar los labios!",
  },
  {
    id: 37,
    text: "¡Matemástico! 5 divisiones y/o multiplicaciones para todos!",
  },
  {
    id: 38,
    text: "Tormenta de neutrones! ¡Todos a bailar el buggy buggy durante 20 segundos!",
  },
  {
    id: 39,
    text: "Alguien ha visto al fantasma de la ópera.. demostémosle qué bien sabemos cantarla!",
  },
  {
    id: 40,
    text: "Payday, lucky day... ¡Alguien aleatorio obtiene 10 puntos de experiencia!",
  },
  {
    id: 41,
    text: "¡La protectora de animales necesita su ayuda! Alguien aleatorio acaba de conseguir una mascota Bits",
  },
  {
    id: 42,
    text: "¡El rey de la simetría acaba de regalar a los números pares de la lista 1 punto extra!",
  },
  {
    id: 43,
    text: "La reina de la simetría acaba de regalar a los números impares de la lista 1 punto extra!",
  },
  {
    id: 44,
    text: "El monstruo de las vocales ha hecho de sus... +2 puntos a todos los que tengan la vocal A, E o I a su nombre!",
  },
  {
    id: 45,
    text: "Tris, tras, trus. El mago Farlomeu dará a alguien aleatorio... 5 puntos!",
  },
];

export default starterPergaminoES;
