import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import StudentGroupHeader from "../Components/StudentGroupHeader";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addGoal } from "../features/goals/goalsSlice";
import { deleteGoalThunk } from "../features/goals/goalsThunk";
import { updateAllStudentPoints } from "../features/students/studentsThunks";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editableToast, correctToast } from "../Components/ToastUtils";

import firebaseApp from "../credentials";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
const firestore = getFirestore(firebaseApp);
function GoalPlanner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const currentGoals = useSelector((state) => state.goals.goals);

  const goals = useSelector((state) => state.goals.goals);

  useEffect(() => {
    async function fetchGoals() {
      const fetchedGoals = goals;
    }
    fetchGoals();
  }, [dispatch]);

  const navigate = useNavigate();

  function updateReRender() {
    navigate("/goal-planner");
  }

  const handleDelete = (goalId) => {
    dispatch(deleteGoalThunk(goalId, currentGoals));
  };

  const handleComplete = async (goalId, points, text) => {
    // Sumar puntos a todos los estudiantes
    dispatch(updateAllStudentPoints(userEmail, parseInt(points, 10), text));
    editableToast("👍" + text);
    handleDelete(goalId);
  };

  async function addGoalForm(e) {
    e.preventDefault();
    const currentGoals = goals || [];
    let lastId = currentGoals.slice(-1)[0]?.id || 0;
    const formElement = document.getElementById("addGoalForm");
    const formData = new FormData(formElement);

    const newGoal = {
      id: lastId + 1,
      text: formData.get("text"),
      number: parseInt(formData.get("number"), 10),
    };

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    if (currentGoals.length === 0) {
      await setDoc(docuRef, { goals: [newGoal] }, { merge: true }); // Usamos setDoc con la opción merge para no sobrescribir otros campos.
    } else {
      await updateDoc(docuRef, { goals: [...currentGoals, newGoal] });
    }

    correctToast(t("goalAddedMsg"));

    dispatch(addGoal(newGoal));

    setTimeout(() => {
      updateReRender();
      formElement.reset();
    }, 500);
  }

  return (
    <>
      <Header />
      <StudentGroupHeader />
      <p className="subtitle">{t("textGoalPlanner")}</p>
      <div>
        <h2 style={{ textAlign: "center" }}>{t("addGoalTitle")}</h2>
        <form
          onSubmit={addGoalForm}
          id="addGoalForm"
          style={{
            margin: "auto",
            padding: "15px",
            maxWidth: "600px",
            alignContent: "center",
          }}
        >
          <label hidden htmlFor="id">
            Id
          </label>
          <input type="hidden" id="id" name="id" placeholder="Id" />

          <label htmlFor="textInput">{t("goalText")}</label>
          <input type="text" id="textInput" name="text" required />

          <label htmlFor="numberInput">{t("goalNumber")}</label>
          <input
            type="number"
            min="0"
            id="numberInput"
            name="number"
            required
          />

          <input type="submit" value={t("addGoal")} />
        </form>
        <div className="spacing"></div>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("goalText")}</th>
              <th style={{ textAlign: "center" }}>{t("points")}</th>
              <th style={{ textAlign: "center" }}>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(goals)
              ? goals.map((goal, index) => {
                  return (
                    <tr key={goal.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{goal.text}</td>
                      <td>{goal.number}</td>
                      <td>
                        <button
                          className="btn-x btn-delete"
                          onClick={() => handleDelete(goal.id)}
                        >
                          X
                        </button>
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            handleComplete(goal.id, goal.number, goal.text)
                          }
                        >
                          {t("completed")}
                        </button>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </>
  );
}

export default GoalPlanner;
