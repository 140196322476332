import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import studentsReducer from "./features/students/studentsSlice";
import behavioursReducer from "./features/behaviours/behavioursSlice";
import groupsReducer from "./features/groups/groupsSlice";
import petsReducer from "./features/pets/petsSlice";
import goalsReducer from "./features/goals/goalsSlice";
import inviteReducer from "./features/invites/inviteSlice";
import toolsReducer from "./features/tools/toolsSlice";
import settingsReducer from "./features/settings/settingsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    students: studentsReducer,
    behaviours: behavioursReducer,
    groups: groupsReducer,
    pets: petsReducer,
    goals: goalsReducer,
    invites: inviteReducer,
    tools: toolsReducer,
    settings: settingsReducer,
  },
});

export default store;
