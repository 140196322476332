import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import firebaseApp from "../../credentials";

import { updateSettings } from "./settingsSlice";

const firestore = getFirestore(firebaseApp);

export const updateNewsletterStatus =
  (userEmail, newsletterStatus) => async (dispatch, getState) => {
    try {
      const docuRef = doc(firestore, `usuarios/${userEmail}`);

      await updateDoc(docuRef, { "settings.newsletter": newsletterStatus });

      const currentSettings = getState().settings;
      dispatch(
        updateSettings({ ...currentSettings, newsletter: newsletterStatus })
      );
    } catch (error) {
      console.error("Error al actualizar el estado del boletín:", error);
    }
  };

export const migratePetRewardData =
  (userEmail) => async (dispatch, getState) => {
    try {
      const currentSettings = getState().settings;

      // Migrar petReward de localStorage si es necesario
      const petRewardLocal = localStorage.getItem("petReward");
      if (petRewardLocal !== null) {
        const petReward = JSON.parse(petRewardLocal);
        const updatedSettings = {
          ...currentSettings,
          petReward,
        };

        const docuRef = doc(firestore, `usuarios/${userEmail}`);

        await updateDoc(docuRef, { settings: updatedSettings });

        dispatch(updateSettings(updatedSettings));

        // Limpia localStorage
        localStorage.removeItem("petReward");
      }
    } catch (error) {
      console.error("Error al migrar datos de localStorage:", error);
    }
  };

export const updatePetRewardStatus =
  (userEmail, petRewardStatus) => async (dispatch) => {
    try {
      const docuRef = doc(firestore, `usuarios/${userEmail}`);

      await updateDoc(docuRef, { "settings.petReward": petRewardStatus });

      dispatch(updateSettings({ petReward: petRewardStatus }));
    } catch (error) {
      console.error("Error al actualizar petReward en Firebase:", error);
    }
  };

export const updateAccountType =
  (userEmail, accountType) => async (dispatch) => {
    try {
      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      await updateDoc(docuRef, { "settings.accountType": accountType });
    } catch (error) {
      console.error("Error al actualizar accountType en Firebase:", error);
    }
  };

export const updateLastPointsAddedDate =
  (userEmail, newDate) => async (dispatch) => {
    try {
      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      await updateDoc(docuRef, { "settings.lastPointsAddedDate": newDate });
      dispatch(updateSettings({ lastPointsAddedDate: newDate }));
    } catch (error) {
      console.error(
        "Error al actualizar lastPointsAddedDate en Firebase:",
        error
      );
    }
  };
