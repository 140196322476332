import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById("year").innerHTML = new Date().getFullYear();
  }, []);

  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-content">
            <div className="row">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <img
                    src="../../logo192.png"
                    style={{ marginRight: "40px" }}
                    alt="logo classbits"
                  />
                </div>
              </div>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>{t("interestLinks")}</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/">{t("home")}</Link>
                  </li>
                  <li>
                    <Link to="/team">{t("whoAreWe")}</Link>
                  </li>
                  <li>
                    <Link to="/game">ClassBits</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("contact")}</Link>
                  </li>
                  <li>
                    <Link to="/tutorial">Tutorial</Link>
                  </li>
                  <li>
                    <Link to="/press">{t("press")}</Link>
                  </li>
                </ul>
              </div>
              <div>
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>ClassBits</h3>
                  </div>
                  <div className="footer-text">
                    <p>{t("footerTextUp")}</p>
                    <p>{t("footerTextDown")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="footer-menu">
                <ul>
                  <li>
                    <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
                  </li>
                  <li>
                    <Link to="/legal-notice">{t("legalNotice")}</Link>
                  </li>
                  <li>
                    <Link to="/cookies">Política de cookies</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright-text">
              <p>
                Copyright &copy; <span id="year"></span>, {t("copyright")}{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
