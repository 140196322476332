const starterExchanges = [
  {
    id: 0,
    name: "Triar una cançó",
    emoji: "🎶",
    points: 1,
  },
  {
    id: 1,
    name: "Canvi de nick",
    emoji: "📝",
    points: 5,
  },
  {
    id: 2,
    name: "Invocar el pergamí del destí",
    emoji: "📜",
    points: 5,
  },
  {
    id: 3,
    name: "Canvi de chibit",
    emoji: "🎭",
    points: 5,
  },
  {
    id: 4,
    name: "Canvi de lloc durant una setmana",
    emoji: "🔄",
    points: 15,
  },
  {
    id: 5,
    name: "Mirar vídeos mentre esmorzem",
    emoji: "🎬",
    points: 10,
  },
  {
    id: 6,
    name: "Fer una exposició del tema que un alumne vulgui",
    emoji: "📖",
    points: 15,
  },
  {
    id: 7,
    name: "Escollir què farem a classe",
    emoji: "💡",
    points: 99,
  },
];

export default starterExchanges;
