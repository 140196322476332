import React, { useState, useEffect } from "react";
import "./AddEdit.css";

import Header from "../Components/Header/Header";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPergaminoRedux } from "../features/tools/toolsThunks";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const AddPergamino = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const pergaminos = useSelector((state) => state.tools.pergaminos);

  const { t } = useTranslation();

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/add-pergam");
  }

  async function addStudentForm(e) {
    e.preventDefault();
    let lastId = pergaminos.slice(-1)[0]?.id || 0;
    const formElement = document.getElementById("addStudentForm");
    const formData = new FormData(formElement);
    const newPergamino = {
      id: lastId + 1,
      text: formData.get("name"),
    };

    //update redux & db
    dispatch(addPergaminoRedux(userEmail, newPergamino));

    correctToast(t("addPergam"));
    setTimeout(function () {
      updateReRender();
      formElement.reset();
    }, 500);
  }

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>{t("addPergam")}</h2>
      <form
        onSubmit={addStudentForm}
        id="addStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlfor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlfor="nameInput">{t("pergam")}</label>
        <textarea
          className="textAreaForm"
          id="nameInput"
          name="name"
          required
        ></textarea>

        <input type="submit" value="Guardar" />
      </form>
      <Link className="btn btn-edit" to="/pergamino">
        {t("goBack")}
      </Link>
      <ToastContainer />
    </div>
  );
};

export default AddPergamino;
