import Header from "../Components/Header/Header";
import "./RandomUserStyles.css";
import Avatar from "../Components/Avatar/Avatar";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function RandomUser() {
  const [randomAvatar, setRandomAvatar] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  function resetQueu() {
    localStorage.removeItem("displayedStudents");
    setRefresh(refresh + 1);
  }

  function refreshPage() {
    const alreadyDisplayedStudents =
      JSON.parse(localStorage.getItem("displayedStudents")) || [];

    // Filter out students that have already been displayed.
    const remainingStudents = students.filter(
      (student) =>
        !alreadyDisplayedStudents.includes(student.id) && student.id !== 0
    );

    if (remainingStudents.length === 0) {
      // If no students are remaining, reset the localStorage
      localStorage.removeItem("displayedStudents");
    } else {
      const selectedStudent =
        remainingStudents[Math.floor(Math.random() * remainingStudents.length)];
      alreadyDisplayedStudents.push(selectedStudent.id);
      localStorage.setItem(
        "displayedStudents",
        JSON.stringify(alreadyDisplayedStudents)
      );
      setRandomAvatar(selectedStudent);
    }

    setRefresh(refresh + 1);
  }

  function displayedStudentsCount() {
    const alreadyDisplayedStudents =
      JSON.parse(localStorage.getItem("displayedStudents")) || [];
    return alreadyDisplayedStudents.length;
  }

  return (
    <>
      <Header />
      <main>
        <div style={{ padding: 70 }}>
          <button className="createRandomButton" onClick={refreshPage}>
            {" "}
            {t("randomUser")}{" "}
          </button>
          <p>
            {displayedStudentsCount()}/
            {students.length - 1 /*subtracting for id:0 student*/}
          </p>
          <button className="btn btn-edit" onClick={resetQueu}>
            Reset
          </button>
          <div class="randAvatar">
            {refresh === 0 || !randomAvatar ? (
              ""
            ) : (
              <Avatar
                id={randomAvatar.id}
                name={randomAvatar.name}
                surname={randomAvatar.surname}
                img={randomAvatar.img}
                pet={randomAvatar.pet === "" ? "" : randomAvatar.pet}
                key={randomAvatar.id}
                correoUsuario={userEmail}
                points={randomAvatar.points}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
}
