// toolsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pergaminos: [],
  dailySentences: [],
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setPergaminos: (state, action) => {
      state.pergaminos = action.payload;
    },
    addPergamino: (state, action) => {
      state.pergaminos.push(action.payload);
    },
    updatePergamino: (state, action) => {
      const { index, data } = action.payload;
      state.pergaminos[index] = data;
    },
    deletePergamino: (state, action) => {
      state.pergaminos = state.pergaminos.filter(
        (pergamino) => pergamino.id !== action.payload
      );
    },
    deleteAllPergaminos: (state) => {
      state.pergaminos = [];
    },
    setSentences: (state, action) => {
      state.dailySentences = action.payload;
    },
    addSentence: (state, action) => {
      state.dailySentences.push(action.payload);
    },
    updateSentence: (state, action) => {
      const { index, data } = action.payload;
      state.dailySentences[index] = data;
    },
    deleteSentence: (state, action) => {
      state.dailySentences = state.dailySentences.filter(
        (sentence) => sentence.id !== action.payload
      );
    },
    deleteAllSentences: (state) => {
      state.dailySentences = [];
    },
  },
});

export const {
  setPergaminos,
  addPergamino,
  updatePergamino,
  deleteAllPergaminos,
  deletePergamino,
  setSentences,
  addSentence,
  updateSentence,
  deleteSentence,
  deleteAllSentences,
} = toolsSlice.actions;

export default toolsSlice.reducer;
