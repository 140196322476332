import React, { useState } from "react";
import "./Lootbox.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  updateStudentThunk,
  updateStudentDiamonds,
  updateStudentPetThunk,
  updateStudentPoints,
  addBusinessToStudentThunk,
} from "../../features/students/studentsThunks";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../ToastUtils";
import { ToastContainer } from "react-toastify";
import { wrongToast } from "../ToastUtils";

function Lootbox({ type }) {
  const [animationStage, setAnimationStage] = useState(0);
  const [contentVisible, setContentVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students) || [];

  const [obtainedReward, setObtainedReward] = useState("");

  const { id } = useParams();
  const idStudent = parseInt(id);

  const handleButtonClick = () => {
    const currentDiamonds = students[idStudent].diamonds || 0;
    const lootboxCost = getLootboxCost();

    if (currentDiamonds + lootboxCost < 0) {
      // Error if not enough diamonds
      wrongToast(t("notEnoughDiamonds"));
      return;
    }

    setContentVisible(false);
    setAnimationStage(1);

    setTimeout(() => {
      setAnimationStage(2);
    }, 500);

    setTimeout(() => {
      setAnimationStage(3);
    }, 1000);

    setTimeout(() => {
      setContentVisible(true);
      setAnimationStage(0);
      handleTypeSpecificAction();
    }, 1500);
  };

  //Function for the Skin Rewards
  async function updateStudentImage(studentId, newImage) {
    const formData = new FormData();

    formData.append("name", students[studentId].name);
    formData.append("surname", students[studentId].surname);
    formData.append("img", `${newImage}.png`);

    try {
      await dispatch(
        updateStudentThunk({
          id: studentId,
          formData: formData,
          selectedImage: {
            value: newImage,
            label: (
              <div>
                <img
                  src={`../../images/chibits/${newImage}.png`}
                  alt={`Image ${newImage}`}
                  style={{ width: "150px", marginRight: "10px" }}
                />
              </div>
            ),
          },
          userEmail: userEmail,
        })
      );
    } catch (error) {
      console.error("Error actualizando la imagen del estudiante:", error);
    }
  }

  // All function rewards from lootboxes

  //Pet rewards
  const rewardPet1 = () => {
    const payload = {
      id,
      pet: "../images/pets/Chameleon.png",
      userEmail,
    };
    dispatch(updateStudentPetThunk(payload));
    return t("rewardPet1");
  };
  const rewardPet2 = () => {
    const payload = {
      id,
      pet: "../images/pets/WhiteG.png",
      userEmail,
    };
    dispatch(updateStudentPetThunk(payload));
    return t("rewardPet2");
  };
  const rewardPet3 = () => {
    const payload = {
      id,
      pet: "../images/pets/Chimera.png",
      userEmail,
    };
    dispatch(updateStudentPetThunk(payload));
    return t("rewardPet3");
  };
  const rewardPet4 = () => {
    const payload = {
      id,
      pet: "../images/pets/Hydra.png",
      userEmail,
    };
    dispatch(updateStudentPetThunk(payload));
    return t("rewardPet4");
  };
  const rewardPet5 = () => {
    const payload = {
      id,
      pet: "../images/pets/Minotaur.png",
      userEmail,
    };
    dispatch(updateStudentPetThunk(payload));
    return t("rewardPet5");
  };

  //Skin rewards
  const rewardSkin1 = () => {
    updateStudentImage(idStudent, 111);
    return t("rewardSkin1");
  };
  const rewardSkin2 = () => {
    updateStudentImage(idStudent, 112);
    return t("rewardSkin2");
  };
  const rewardSkin3 = () => {
    updateStudentImage(idStudent, 113);
    return t("rewardSkin3");
  };
  const rewardSkin4 = () => {
    updateStudentImage(idStudent, 114);
    return t("rewardSkin4");
  };
  const rewardSkin5 = () => {
    updateStudentImage(idStudent, 115);
    return t("rewardSkin5");
  };
  //Business Rewards

  const rewardBusiness1 = () => {
    dispatch(addBusinessToStudentThunk(id, 21, userEmail));
    return t("businessReward1");
  };
  const rewardBusiness2 = () => {
    dispatch(addBusinessToStudentThunk(id, 22, userEmail));
    return t("businessReward2");
  };
  const rewardBusiness3 = () => {
    dispatch(addBusinessToStudentThunk(id, 23, userEmail));
    return t("businessReward3");
  };
  const rewardBusiness4 = () => {
    dispatch(addBusinessToStudentThunk(id, 24, userEmail));
    return t("businessReward4");
  };
  const rewardBusiness5 = () => {
    dispatch(addBusinessToStudentThunk(id, 25, userEmail));
    return t("businessReward5");
  };
  const rewardBusiness6 = () => {
    dispatch(addBusinessToStudentThunk(id, 26, userEmail));
    return t("businessReward6");
  };
  const rewardBusiness7 = () => {
    dispatch(addBusinessToStudentThunk(id, 27, userEmail));
    return t("businessReward7");
  };
  const rewardBusiness8 = () => {
    dispatch(addBusinessToStudentThunk(id, 28, userEmail));
    return t("businessReward8");
  };
  const rewardBusiness9 = () => {
    dispatch(addBusinessToStudentThunk(id, 29, userEmail));
    return t("businessReward9");
  };
  const rewardBusiness10 = () => {
    dispatch(addBusinessToStudentThunk(id, 30, userEmail));
    return t("businessReward10");
  };

  //Points rewards
  const rewardPoints1 = () => {
    dispatch(updateStudentPoints(userEmail, id, 5, t("lootboxReward")));
    return t("rewardPoints1");
  };
  const rewardPoints2 = () => {
    dispatch(updateStudentPoints(userEmail, id, 7, t("lootboxReward")));
    return t("rewardPoints2");
  };
  const rewardPoints3 = () => {
    dispatch(updateStudentPoints(userEmail, id, 10, t("lootboxReward")));
    return t("rewardPoints3");
  };
  const rewardPoints4 = () => {
    dispatch(updateStudentPoints(userEmail, id, 12, t("lootboxReward")));
    return t("rewardPoints4");
  };
  const rewardPoints5 = () => {
    dispatch(updateStudentPoints(userEmail, id, 15, t("lootboxReward")));
    return t("rewardPoints5");
  };

  //Array of rewards
  //Legendary
  const legendaryActions = [
    rewardPet1,
    rewardPet2,
    rewardPet3,
    rewardPet4,
    rewardPet5,
    rewardSkin1,
    rewardSkin2,
    rewardSkin3,
    rewardSkin4,
    rewardSkin5,
    rewardBusiness1,
    rewardBusiness2,
    rewardBusiness3,
    rewardBusiness4,
    rewardBusiness5,
    rewardBusiness6,
    rewardBusiness7,
    rewardBusiness8,
    rewardBusiness9,
    rewardBusiness10,
  ];

  const epicActions = [
    rewardPet1,
    rewardPet2,
    rewardSkin1,
    rewardSkin2,
    rewardBusiness5,
    rewardBusiness6,
    rewardBusiness7,
    rewardBusiness8,
    rewardPoints3,
    rewardPoints4,
    rewardPoints5,
  ];

  const commonActions = [
    rewardPet1,
    rewardSkin1,
    rewardBusiness7,
    rewardBusiness8,
    rewardPoints1,
    rewardPoints2,
    rewardPoints3,
    rewardPoints4,
    rewardPoints5,
    rewardPoints1,
    rewardPoints2,
    rewardPoints3,
    rewardPoints4,
    rewardPoints5,
  ];

  // Functions according to opened lootbox
  const handleTypeSpecificAction = async () => {
    const lootboxCost = getLootboxCost();

    await dispatch(
      updateStudentDiamonds(
        userEmail,
        idStudent,
        lootboxCost,
        t("openLootbox") + " " + type
      )
    );

    let rewardDescription = "";
    if (type === "legendary") {
      const randomAction =
        legendaryActions[Math.floor(Math.random() * legendaryActions.length)];
      rewardDescription = randomAction();
    } else if (type === "epic") {
      const randomAction =
        epicActions[Math.floor(Math.random() * epicActions.length)];
      rewardDescription = randomAction();
    } else if (type === "common") {
      const randomAction =
        commonActions[Math.floor(Math.random() * commonActions.length)];
      rewardDescription = randomAction();
    }

    setObtainedReward(rewardDescription);
  };

  const getButtonText = () => {
    if (type === "legendary") {
      return t("legendaryCost");
    } else if (type === "epic") {
      return t("epicCost");
    } else if (type === "common") {
      return t("commonCost");
    }
  };

  const getLootboxCost = () => {
    if (type === "legendary") {
      return -15;
    } else if (type === "epic") {
      return -10;
    } else if (type === "common") {
      return -5;
    }
    return 0;
  };

  const getLootboxImage = () => {
    if (type === "legendary") {
      return animationStage === 3 || contentVisible
        ? "/images/lootbox/lootbox_legendary_opened.png"
        : "/images/lootbox/lootbox_legendary_closed.png";
    } else if (type === "epic") {
      return animationStage === 3 || contentVisible
        ? "/images/lootbox/lootbox_epic_opened.png"
        : "/images/lootbox/lootbox_epic_closed.png";
    } else {
      return animationStage === 3 || contentVisible
        ? "/images/lootbox/lootbox_default_opened.png"
        : "/images/lootbox/lootbox_default_closed.png";
    }
  };

  return (
    <div className="lootbox-container">
      <h3>{t(type)}</h3>
      <img
        className={`lootbox ${
          animationStage === 1
            ? "shake"
            : animationStage === 2
            ? "illuminate"
            : animationStage === 3
            ? "final-shake"
            : ""
        }`}
        src={getLootboxImage()}
        alt="Lootbox"
      />
      {contentVisible && (
        <div className="lootbox-content">
          <p>
            {t("openedReward")}
            {obtainedReward}
          </p>
        </div>
      )}

      <button onClick={handleButtonClick} className="button-lootbox">
        {getButtonText()}
      </button>
      <ToastContainer />
    </div>
  );
}

export default Lootbox;
