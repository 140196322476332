const starterNegatives = [
  {
    id: 0,
    name: "Molestar als companys",
    emoji: "😤",
    points: 1,
  },
  {
    id: 1,
    name: "No estar atent",
    emoji: "🙄",
    points: 1,
  },
  {
    id: 2,
    name: "No fer els deures",
    emoji: "✍️",
    points: 1,
  },
  {
    id: 3,
    name: "No portar el material",
    emoji: "🤐",
    points: 1,
  },
  {
    id: 4,
    name: "Balancejar-se a la cadira",
    emoji: "🪑",
    points: 1,
  },
  {
    id: 5,
    name: "Faltar el respecte a un company",
    emoji: "🤬",
    points: 2,
  },
  {
    id: 6,
    name: "Pegar-se amb un company",
    emoji: "🤼",
    points: 4,
  },
  {
    id: 7,
    name: "No fer cas del mestre/a",
    emoji: "👨‍🏫",
    points: 5,
  },
];

export default starterNegatives;
