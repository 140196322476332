import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <h1 className="pages-h1">{t("privacyPolicy")}</h1>
      <div className="content">
        <div className="container containerTeam">
          <div className="info">
            <p>
              Si estás aquí es porque quieres saber más sobre las obligaciones y
              derechos que te corresponden como usuario de esta web
              <strong> </strong>y eso está muy bien. Nuestro deber es informarte
              y el tuyo estar debidamente informado.
            </p>

            <p>
              En esta Política de Privacidad te informaremos con total
              transparencia sobre la finalidad de este sitio web y todo lo que
              afecta a los datos que nos facilites, así como de las obligaciones
              y derechos que te corresponden.
            </p>

            <p>
              Para empezar, debes saber que este sitio web se adapta a la
              normativa vigente en relación con la protección de datos, lo que
              afecta a los datos personales que nos facilites con tu
              consentimiento expreso y a las cookies que utilizamos para que
              este sitio web funcione correctamente y pueda desarrollar su
              actividad.
            </p>

            <p>
              Concretamente, esta web se ajusta al cumplimiento de las
              siguientes normativas:
            </p>

            <p>
              El&nbsp;<strong>RGPD&nbsp;</strong>(
              <strong>
                Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de
                27 de abril de 2016 relativo a la protección de las personas
                físicas
              </strong>
              )&nbsp;que es la nueva normativa de la Unión Europea que unifica
              la regulación del tratamiento de los datos personales en los
              distintos países de la UE.
            </p>

            <p>
              La&nbsp;<strong>LOPD</strong>&nbsp;(
              <strong>
                Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos
                de Carácter Personal
              </strong>
              &nbsp;y&nbsp;
              <strong>
                Real Decreto 1720/2007, de 21 de diciembre, el Reglamento de
                desarrollo de la LOPD
              </strong>
              ) que regula el tratamiento de los datos personales y las
              obligaciones que debemos asumir los responsables de una web o un
              blog a la hora de gestionar esta información.
            </p>

            <p>
              La&nbsp;<strong>LSSI</strong>&nbsp;(
              <strong>
                Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                Información y Comercio Electrónico
              </strong>
              )&nbsp;que regula las transacciones económicas mediante medios
              electrónicos, como es el caso de este blog.
            </p>

            <p>
              <strong>DATOS DE IDENTIFICACIÓN</strong>
            </p>

            <p>
              El responsable y titular de este sitio web es{" "}
              <strong>Cristian Viaplana (en adelante CV)</strong>
            </p>

            <ul>
              <li>
                <strong>Nombre:</strong> Cristian Viaplana
              </li>
              <li>
                <strong>Domicilio Social:</strong> C/ Cronista Muntaner 25,
                bajos
              </li>
              <li>
                <strong>Actividad del sitio web</strong>: plataforma de gamificación para entornos educativos.
              </li>
              <li>
                <strong>Correo electrónico</strong>: info@classbits.net
              </li>
            </ul>

            <p>
              Los datos que nos facilites con tu consentimiento, y de acuerdo al
              uso establecido en esta Política de Privacidad, serán incorporados
              a un fichero automatizado debidamente inscrito en la Agencia
              Española de Protección de Datos, en el que el responsable de dicho
              fichero es: CV. Esto quiere decir que tus datos están seguros, de
              acuerdo a lo que establece la ley.
            </p>

            <p>
              Los datos personales que nos facilites, siempre con tu
              consentimiento expreso, se almacenarán y tratarán con los fines
              previstos y descritos a continuación en esta Política de
              Privacidad, hasta el momento en que nos pidas que los eliminemos
              <strong>.</strong>
            </p>

            <p>
              Te informamos de que esta Política de Privacidad puede ser
              modificada en cualquier momento, con el fin de adaptarla a
              novedades legislativas o cambios en nuestras actividades, siendo
              vigente la que en cada momento se encuentre publicada en la web.
              Dicha modificación se te notificará antes de su aplicación.
            </p>

            <p>
              <strong>CONDICIONES DE USO</strong>
            </p>

            <p>
              Debes saber, para tu tranquilidad, que siempre te solicitaremos tu
              consentimiento expreso para recabar tus datos con la
              correspondiente finalidad especificada en cada caso, lo que
              implica que, en caso de otorgar ese consentimiento, has leído y
              aceptado esta Política de Privacidad<strong>.</strong>
            </p>

            <p>
              En el momento en que accedas y utilices esta web, asumes tu
              condición de usuario con tus correspondientes derechos y
              obligaciones.
            </p>

            <p>
              Si eres mayor de 13 años, podrás registrarte como usuario en este
              sitio web sin el previo consentimiento de tus padres o tutores.
            </p>

            <p>
              Si eres menor de 13 años, necesitarás el consentimiento de tus
              padres o tutores para el tratamiento de tus datos personales.
            </p>

            <p>
              <strong>REGISTRO Y FINALIDAD DE TUS DATOS</strong>
            </p>

            <p>
              En función del formulario o sección a la que accedas, te
              solicitaremos exclusivamente los datos necesarios con las
              finalidades descritas a continuación. En todo momento, deberás dar
              tu consentimiento expreso, cuando te solicitemos información
              personal con las siguientes finalidades:
            </p>

            <ul>
              <li>
                Las finalidades concretas que particularmente se indiquen en
                cada una de las páginas o secciones donde aparezca el formulario
                de registro o contacto electrónico.
              </li>
              <li>
                Con carácter general, para atender tus solicitudes, comentarios,
                consultas o cualquier tipo de petición que realices como usuario
                a través de cualquiera de las formas de contacto que ponemos a
                tu disposición
              </li>
              <li>
                Para informarte sobre consultas, peticiones, actividades,
                productos, novedades y/o servicios; vía e-mail.
              </li>
              <li>
                Para enviarte comunicaciones de feedback o actualizaciones a
                través de cualquier otro medio electrónico, que
                posibilite realizar comunicaciones.
              </li>
            </ul>

            <p>
              Estas comunicaciones, siempre serán relacionadas con novedades de Classbits o peticiones para encuestas, así como aquellos
              productos o servicios que podamos considerar de tu interés y que
              puedan ofrecer colaboradores, empresas o “partners” con los que
              mantengamos acuerdos de promoción o colaboración comercial.
            </p>

            <p>
              De ser así, te garantizamos que estos terceros nunca tendrán
              acceso a tus datos personales, con las excepciones reflejadas a
              continuación, siendo en todo caso estas comunicaciones realizadas
              por parte de <strong>CV, como titular de la web.</strong>
            </p>

            <p>
              Estas páginas enlazadas y pertenecientes a terceros no han sido
              revisadas ni son objeto de controles, ni recomendación por nuestra
              parte, por lo que en ningún caso <strong>CV</strong>será
              considerada responsable de los contenidos de estos sitios web, de
              las responsabilidades derivadas de su uso en todos los ámbitos, ni
              por las medidas que se adopten relativas a la privacidad del
              usuario, tratamiento de sus datos de carácter personal u otras que
              pudieran establecerse.
            </p>

            <p>
              Por todo ello, te recomendamos la lectura detenida y anticipada de
              todas las condiciones de uso, condiciones de compra, políticas de
              privacidad, avisos legales y/o similares de estos sitios enlazados
              antes de proceder a la adquisición de estos productos o uso de las
              webs.
            </p>

            <p>
              <strong>EXACTITUD Y VERACIDAD DE LOS DATOS</strong>
            </p>

            <p>
              Como usuario, eres el único responsable de la veracidad y
              modificación de los datos que remitas a <strong>CV</strong>,
              exonerándonos de cualquier responsabilidad al respecto.
            </p>

            <p>
              Es decir, a ti te corresponde garantizar y responder en cualquier
              caso de la exactitud, vigencia y autenticidad de los datos
              personales facilitados, y te comprometes a mantenerlos debidamente
              actualizados.
            </p>

            <p>
              De acuerdo a lo expresado en esta Política de Privacidad, aceptas
              proporcionar información completa y&nbsp;correcta&nbsp;en el
              formulario de contacto o suscripción.
            </p>

            <p>
              <strong>BAJAS DE SUSCRIPCIÓN Y DERECHO DE REVOCACIÓN</strong>
            </p>

            <p>
              Como titular de los datos que nos hayas facilitado, podrás ejercer
              en cualquier momento tus derechos de acceso, rectificación,
              cancelación y oposición, enviándonos un correo electrónico a
              <strong> info@classbits.net </strong>. Con enviar un correo desde la misma dirección
              con la que te has registrado, será suficiente.
            </p>

            <p>
              Igualmente, podrás darte de baja en cualquier momento para dejar
              de recibir nuestra newsletter o cualquier otra comunicación
              comercial, directamente desde el mismo email que hayas recibido o
              enviándonos un correo a <strong>info@classbits.net.</strong>
            </p>

            <p>
              Paralelamente si quieres dejar de usar Classbits, puedes
              solicitarnos que borremos tu cuenta. Para borrarla deberás
              enviarnos un correo desde el mismo email con el que te
              registraste.
            </p>

            <p>
              <strong>ACCESO A DATOS POR CUENTA DE TERCEROS</strong>
            </p>

            <p>
              Para poder prestar servicios estrictamente necesarios para el
              funcionamiento y desarrollo&nbsp;de las actividades de este sitio
              web, te informamos de que compartimos datos con los siguientes
              prestadores de servicio bajo sus correspondientes condiciones de
              privacidad.
            </p>

            <p>
              Puedes tener la total tranquilidad de que estos terceros no podrán
              utilizar dicha información para ningún otro fin que no esté
              regulado específicamente en nuestras relaciones con ellos en
              virtud de la normativa aplicable en materia de protección de datos
              de carácter personal.
            </p>

            {/* <p>
              Nuestra web utiliza servidores publicitarios con el fin de
              facilitar los contenidos comerciales que visualizas en nuestras
              páginas. Dichos servidores publicitarios utilizan cookies que le
              permiten adaptar los contenidos publicitarios a los perfiles
              demográficos de los usuarios:
            </p> */}

            <p>
              <strong>Google Analytics:</strong>
            </p>

            <p>
              Google Analytics es un servicio analítico de web prestado por
              Google, Inc., una compañía de Delaware cuya oficina principal está
              en 1600 Amphitheatre Parkway, Mountain View (California), CA
              94043, Estados Unidos (“Google”).
            </p>

            <p>
              Google Analytics utiliza “cookies”, que son archivos de texto
              ubicados en tu ordenador, para ayudar al website a analizar el uso
              que hacen los usuarios del sitio web.
            </p>

            <p>
              La información que genera la cookie acerca de tu uso del website
              (incluyendo tu dirección IP) será directamente transmitida y
              archivada por Google. Google usará esta información por cuenta
              nuestra con el propósito de seguir la pista de su uso del website,
              recopilando informes de la actividad del website y prestando otros
              servicios relacionados con la actividad del website y el uso de
              Internet.
            </p>

            <p>
              Google podrá transmitir dicha información a terceros cuando así se
              lo requiera la legislación, o cuando dichos terceros procesen la
              información por cuenta de Google. Google no asociará su dirección
              IP con ningún otro dato del que disponga.
            </p>

            <p>
              Como usuario, y en ejercicio de tus derechos, puedes rechazar el
              tratamiento de los datos o la información rechazando el uso de
              cookies mediante la selección de la configuración apropiada de tu
              navegador, sin embargo, debes saber que si lo hace puede que no
              puedas usar la plena funcionabilidad de este sitio web.
            </p>

            <p>
              Al utilizar esta web, de acuerdo a la información facilitada en
              esta Política de Privacidad, aceptas el tratamiento de datos por
              parte de Google en la forma y para los fines indicados.
            </p>

            <p>
              Para más información, puedes consultar la política de privacidad
              de Google<strong>&nbsp;en</strong>&nbsp;
              <a
                href="https://www.google.com/intl/es/policies/privacy/"
                target="_blank"
                rel="noopener"
              >
                https://www.google.com/intl/es/policies/privacy/
              </a>
              .
            </p>

            <p>
              <strong>MEDIDAS DE SEGURIDAD</strong>
            </p>

            <p>
              Como titular de la web<strong>, CV</strong> ha adoptado todas las
              medidas técnicas y de organización necesaria para garantizar la
              seguridad e integridad de los datos de carácter personal que
              trate, así como para evitar su pérdida, alteración y/o acceso por
              parte de terceros no autorizados.
            </p>

            <p>
              Te recordamos que, para más información, puedes consultar nuestras
              páginas de&nbsp;<strong>Aviso Legal</strong>&nbsp;y&nbsp;
              <strong>Política de Cookies.</strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
