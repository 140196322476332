import React, { useEffect } from "react";
import RoutesComponent from "./RoutesComponent";

import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./features/students/studentsThunks";

function MainContainer() {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchUserData(userEmail));
    }
  }, [dispatch, userEmail]);

  return <RoutesComponent />;
}

export default MainContainer;
