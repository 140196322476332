import { getFirestore, doc, updateDoc } from "firebase/firestore";
import firebaseApp from "../../credentials";

import {
  setPositives,
  addPositive,
  updatePositive,
  deletePositive,
  setNegatives,
  addNegative,
  updateNegative,
  deleteNegative,
  setExchanges,
  addExchange,
  updateExchange,
  deleteExchange,
} from "../behaviours/behavioursSlice";

const firestore = getFirestore(firebaseApp);

//Add behaviours

export const addPositiveRedux =
  (userEmail, positiveData) => async (dispatch, getState) => {
    const lastPositive = getState().behaviours.positives.slice(-1)[0];
    const newPositive = {
      id: lastPositive ? lastPositive.id + 1 : 1,
      ...positiveData,
    };

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const currentPositives = getState().behaviours.positives;
    // Añade el nuevo positivo a la lista
    const newPositivesArray = [...currentPositives, newPositive];

    await updateDoc(docuRef, { positives: newPositivesArray });

    // Despachar acción para actualizar el estado en el store
    dispatch(addPositive(newPositive));
  };

export const addNegativeRedux =
  (userEmail, negativeData) => async (dispatch, getState) => {
    const lastNegative = getState().behaviours.negatives.slice(-1)[0];
    const newNegative = {
      id: lastNegative ? lastNegative.id + 1 : 1,
      ...negativeData,
    };

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const currentNegatives = getState().behaviours.negatives;
    // Añade el nuevo negativo a la lista
    const newNegativesArray = [...currentNegatives, newNegative];

    await updateDoc(docuRef, { negatives: newNegativesArray });

    // Despachar acción para actualizar el estado en el store
    dispatch(addNegative(newNegative));
  };

export const addExchangeRedux =
  (userEmail, exchangeData) => async (dispatch, getState) => {
    const lastExchange = getState().behaviours.exchanges.slice(-1)[0];
    const newExchange = {
      id: lastExchange ? lastExchange.id + 1 : 1,
      ...exchangeData,
    };

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    const currentExchanges = getState().behaviours.exchanges;
    // Añade el nuevo exchange a la lista
    const newExchangesArray = [...currentExchanges, newExchange];

    await updateDoc(docuRef, { exchanges: newExchangesArray });

    // Despachar acción para actualizar el estado en el store
    dispatch(addExchange(newExchange));
  };

//Delete behaviours

export const deletePositiveThunk =
  (userEmail, id, currentPositives) => async (dispatch) => {
    const updatedPositives = currentPositives.filter(
      (positive) => positive.id !== id
    );

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { positives: updatedPositives });

    // Despachar la acción para actualizar el estado en Redux
    dispatch(deletePositive(id));
  };

export const deleteNegativeThunk =
  (userEmail, id, currentNegatives) => async (dispatch) => {
    const updatedNegatives = currentNegatives.filter(
      (negative) => negative.id !== id
    );

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { negatives: updatedNegatives });

    // Despachar la acción para actualizar el estado en Redux
    dispatch(deleteNegative(id));
  };

export const deleteExchangeThunk =
  (userEmail, id, currentExchanges) => async (dispatch) => {
    const updatedExchanges = currentExchanges.filter(
      (exchange) => exchange.id !== id
    );

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { exchanges: updatedExchanges });

    // Despachar la acción para actualizar el estado en Redux
    dispatch(deleteExchange(id));
  };

export const deleteAllPositivesThunk = (userEmail) => async (dispatch) => {
  const defaultPositive = {
    id: 0,
    name: "Edita",
    emoji: "🤗",
    points: parseInt(1),
  };
  // Actualizar la base de datos

  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, {
    positives: [defaultPositive],
  });

  // Despachar la acción para actualizar el estado en Redux
  dispatch(setPositives([defaultPositive]));
};

export const deleteAllNegativesThunk = (userEmail) => async (dispatch) => {
  const defaultNegative = {
    id: 0,
    name: "Edita",
    emoji: "🤗",
    points: parseInt(1),
  };
  // Actualizar la base de datos

  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, {
    negatives: [defaultNegative],
  });

  // Despachar la acción para actualizar el estado en Redux
  dispatch(setNegatives([defaultNegative]));
};

export const deleteAllExchangesThunk = (userEmail) => async (dispatch) => {
  const defaultExchange = {
    id: 0,
    name: "Edita",
    emoji: "🤗",
    points: parseInt(1),
  };
  // Actualizar la base de datos

  const docuRef = doc(firestore, `usuarios/${userEmail}`);
  await updateDoc(docuRef, {
    exchanges: [defaultExchange],
  });

  // Despachar la acción para actualizar el estado en Redux
  dispatch(setExchanges([defaultExchange]));
};

//Update behaviours

export const updatePositiveThunk =
  (userEmail, index, data) => async (dispatch, getState) => {
    // Actualiza el estado en Redux
    dispatch(updatePositive({ index, data }));

    // Obtiene el estado actualizado
    const updatedState = getState();

    // Actualiza Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { positives: updatedState.behaviours.positives });
  };

export const updateNegativeThunk =
  (userEmail, index, data) => async (dispatch, getState) => {
    // Actualiza el estado en Redux
    dispatch(updateNegative({ index, data }));

    // Obtiene el estado actualizado
    const updatedState = getState();

    // Actualiza Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { negatives: updatedState.behaviours.negatives });
  };

export const updateExchangeThunk =
  (userEmail, index, data) => async (dispatch, getState) => {
    // Actualiza el estado en Redux
    dispatch(updateExchange({ index, data }));

    // Obtiene el estado actualizado
    const updatedState = getState();

    // Actualiza Firebase
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { exchanges: updatedState.behaviours.exchanges });
  };
