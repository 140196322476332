import firebaseApp from "../../credentials";
import { updateStudent } from "../students/studentsSlice";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

import { deleteGoal } from "./goalsSlice";

const firestore = getFirestore(firebaseApp);

export const deleteGoalThunk =
  (goalId, currentGoals) => async (dispatch, getState) => {
    const userEmail = getState().auth.userEmail;

    const updatedGoals = currentGoals.filter((goal) => goal.id !== goalId);

    // Actualizar la base de datos
    const docuRef = doc(firestore, `usuarios/${userEmail}`);
    await updateDoc(docuRef, { goals: updatedGoals });

    // Despachar la acción para actualizar el estado en Redux
    dispatch(deleteGoal(goalId));
  };
