import React, { useState, useEffect } from "react";
import "./Settings.css";
import Header from "../Components/Header/Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAllStudentPoints } from "../features/students/studentsThunks";
import firebaseApp from "../credentials";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import useRequireAuth from "../hooks/useRequireAuth";

const firestore = getFirestore(firebaseApp);

const Settings = () => {
  const { t } = useTranslation();
  useRequireAuth();
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  const navigate = useNavigate();

  //If there's no students, force to create class first
  useEffect(() => {
    if (students.length === 1) {
      return navigate("/chooseChibit");
    }
  }, [students]);

  function deleteClassroom() {
    if (window.confirm(t("deleteClassMsg"))) {
      const docRef = doc(firestore, `usuarios/${userEmail}`);

      deleteDoc(docRef)
        .then(() => {
          console.log("Entire Document has been deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
        });

      alert(t("classDeletedMsg"));
      setTimeout(() => window.location.reload(), 500);
    }
  }

  const resetPoints = () => {
    if (window.confirm(t("resetClassMsg"))) {
      dispatch(resetAllStudentPoints(userEmail));
      alert(t("resetPointsMsg"));
    }
  };

  //Backup

  async function downloadBackup(idDocument) {
    try {
      // Crea una referencia al documento en Firebase
      const docRef = doc(firestore, `usuarios/${idDocument}`);

      // Obtiene los datos del documento
      const docSnapshot = await getDoc(docRef);

      // Verifica si el documento existe
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();

        // Convierte los datos en formato JSON
        const jsonData = JSON.stringify(docData);

        // Crea un objeto Blob con los datos JSON
        const blob = new Blob([jsonData], { type: "application/json" });

        // Genera un enlace de descarga
        const downloadUrl = URL.createObjectURL(blob);

        // Crea un elemento <a> invisible para la descarga
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "backup.json";

        // Simula el clic en el enlace para iniciar la descarga
        link.click();

        // Libera el enlace y el objeto Blob
        URL.revokeObjectURL(downloadUrl);
      } else {
        console.log("El documento no existe en Firebase");
      }
    } catch (error) {
      console.error("Error al descargar la copia de seguridad", error);
    }
  }
  const handleBackupDownload = async () => {
    await downloadBackup(userEmail);
  };

  //Upload backup
  const [backupFile, setBackupFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setBackupFile(file);
  };

  const handleUploadBackup = async () => {
    if (backupFile) {
      try {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const backupData = JSON.parse(e.target.result);

          // Sobreescribe la información del usuario con los datos de la copia de seguridad
          const docRef = doc(firestore, `usuarios/${userEmail}`);
          await setDoc(docRef, backupData);

          alert(t("successBackupMsg"));
        };

        reader.readAsText(backupFile);
      } catch (error) {
        alert(t("failedBackupMsg"), error);
      }
    } else {
      alert(t("noFileBackupMsg"));
    }
  };

  const showBackupUploader = () => {
    document.getElementById("backupBtns").style.cssText =
      "display:none!important";
    document.getElementById("backupLoader").style.cssText =
      "display:block!important";
  };

  return (
    <div>
      <Header />
      <div className="centerDivs">
        <h2>{t("editClassTitle")}</h2>
        <div className="settings">
          <p>
            <Link to="/add">
              <button className="button-success">{t("addStudentsBtn")}</button>
            </Link>
          </p>
          <p>
            <Link to="/EditClassroom">
              <button className="button-secondary">
                {t("editStudentsBtn")}
              </button>
            </Link>
          </p>
          <p>
            <Link to="/Pets">
              <button className="button-warning">{t("pets")}</button>
            </Link>
          </p>
        </div>
        <div className="settings">
          <p>
            <Link to="/addGroup">
              <button className="button-success">{t("addGroupBtn")}</button>
            </Link>
          </p>
          <p>
            <Link to="/editGroups">
              <button className="button-secondary">{t("editGroupBtn")}</button>
            </Link>
          </p>
        </div>
        <div className="settings settingsRules">
          <p>
            <button className="button-warning" onClick={resetPoints}>
              {t("resetPoints")}
            </button>
          </p>
          <p>
            <button className="button-error" onClick={deleteClassroom}>
              {t("deleteClass")}
            </button>
          </p>
          &nbsp;&nbsp;
        </div>

        {/* Rules */}
        <h2>{t("editRules")}</h2>

        <div className="settings settingsRules">
          <p>
            <Link to="/addPositive">
              <button className="button-success">{t("addPositiveBtn")}</button>
            </Link>
          </p>

          <p>
            <Link to="/addNegative">
              <button className="button-warning">{t("addNegativeBtn")}</button>
            </Link>
          </p>
          <p>
            <Link to="/addExchange">
              <button className="button-secondary">
                {t("addExchangeBtn")}
              </button>
            </Link>
          </p>
        </div>
        <div className="settings settingsRules">
          <p>
            <Link to="/editBehaviourP">
              <button className="button-success">{t("editPositiveBtn")}</button>
            </Link>
          </p>
          <p>
            <Link to="/editBehaviourN">
              <button className="button-warning">{t("editNegativeBtn")}</button>
            </Link>
          </p>

          <p>
            <Link to="/editBehaviourE">
              <button className="button-secondary">
                {t("editExchangeBtn")}
              </button>
            </Link>
          </p>
        </div>
        <h2>{t("backupTitle")}</h2>
        <div className="settings settingsRules" id="backupBtns">
          <p>
            <button className="button-warning" onClick={handleBackupDownload}>
              {t("downloadBackup")}
            </button>
          </p>
          <p>
            <button className="button-secondary" onClick={showBackupUploader}>
              {t("uploadBackup")}
            </button>
          </p>
        </div>
        <div
          style={{ display: "none" }}
          className="settings settingsRules"
          id="backupLoader"
        >
          <p>
            <input type="file" onChange={handleFileChange} />
            <button className="button-error" onClick={handleUploadBackup}>
              {t("uploadBackupBtn")}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Settings;
