import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./AddEdit.css";
import ChibiGallery from "./ChibiGallery";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateStudentThunk } from "../features/students/studentsThunks";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

const EditStudent = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);

  const [arrayStudents, setArrayStudents] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [numberList, setNumberList] = useState([]);
  const { id } = useParams();
  const idStudent = parseInt(id);

  useEffect(() => {
    async function fetchStudents() {
      setArrayStudents(students);
    }
    fetchStudents();

    const generateNumberList = () => {
      const numbers = [];
      for (let i = 1; i <= 110; i++) {
        numbers.push(i);
      }
      return numbers;
    };

    const numbers = generateNumberList();
    setNumberList(numbers);
  }, []);

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/EditClassroom");
  }

  useEffect(() => {
    if (arrayStudents[id]) {
      const currentImage = {
        value: arrayStudents[id].img,
        label: (
          <div>
            <img
              src={`../../images/chibits/${arrayStudents[id].img}`}
              alt={`Image ${arrayStudents[id].img}`}
              style={{ width: "150px", marginRight: "10px" }}
            />
          </div>
        ),
      };
      setSelectedImage(currentImage);
    }
  }, [id, arrayStudents]);

  async function editStudentForm(e) {
    e.preventDefault();

    const formElement = document.getElementById("editStudentForm");
    const formData = new FormData(formElement);

    try {
      await dispatch(
        updateStudentThunk({
          id: idStudent,
          formData: formData,
          selectedImage: selectedImage,
          userEmail: userEmail,
        })
      );

      correctToast(t("editStudentMsg"));
      setTimeout(function () {
        updateReRender();
      }, 1000);
    } catch (error) {
      console.error("Error actualizando estudiante:", error);
    }
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const handleImageSelect = (selectedOption) => {
    setSelectedImage(selectedOption);
    topFunction();
  };

  const handleNameChange = (event) => {
    const updatedStudents = [...arrayStudents];
    const newStudent = {
      ...updatedStudents[idStudent],
      name: event.target.value,
    };
    updatedStudents[idStudent] = newStudent;
    setArrayStudents(updatedStudents);
  };

  const handleSurnameChange = (event) => {
    const updatedStudents = [...arrayStudents];
    const newStudent = {
      ...updatedStudents[idStudent],
      surname: event.target.value,
    };
    updatedStudents[idStudent] = newStudent;
    setArrayStudents(updatedStudents);
  };

  const imageOptions = numberList.map((number) => ({
    value: number,
    label: (
      <div>
        <span>
          {number}
          <img
            src={`../../images/chibits/${number}.png`}
            alt={`Image ${number}`}
            style={{ width: "150px", marginRight: "10px" }}
          />
        </span>
      </div>
    ),
  }));

  return (
    <div>
      <Header />
      <h2 style={{ textAlign: "center" }}>
        Editar{" "}
        {arrayStudents[id]
          ? arrayStudents[id].name + " " + arrayStudents[id].surname
          : ""}
      </h2>
      <form
        onSubmit={editStudentForm}
        id="editStudentForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlFor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" value={idStudent} />

        <label htmlFor="nameInput">{t("studentName")}</label>
        <input
          type="text"
          id="nameInput"
          name="name"
          required
          value={arrayStudents[id] ? arrayStudents[id].name : ""}
          onChange={handleNameChange}
        />

        <label htmlFor="surnameInput">{t("studentSurname")}</label>
        <input
          type="text"
          id="surnameInput"
          name="surname"
          value={arrayStudents[id] ? arrayStudents[id].surname : ""}
          onChange={handleSurnameChange}
        />

        <label htmlFor="pictureInput">{t("image")}</label>
        <Select
          styles={{
            dropdownIndicator: (base) => ({
              ...base,
              position: "absolute",
              top: "50%",
              right: "8px",
              transform: "translateY(-50%)",
              width: "40px", // Ajusta el ancho del SVG
              height: "40px", // Ajusta la altura del SVG
            }),
          }}
          options={imageOptions}
          onChange={handleImageSelect}
          value={selectedImage}
        />

        <input type="submit" value="Guardar" />
      </form>
      <ChibiGallery onImageClick={handleImageSelect} />

      <ToastContainer />
    </div>
  );
};

export default EditStudent;
