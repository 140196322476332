import React from "react";

function ChibiGallery({ onImageClick }) {
  const images = [
    { src: "../../images/chibits/1.png", alt: "1" },
    { src: "../../images/chibits/2.png", alt: "2" },
    { src: "../../images/chibits/3.png", alt: "3" },
    { src: "../../images/chibits/4.png", alt: "4" },
    { src: "../../images/chibits/5.png", alt: "5" },
    { src: "../../images/chibits/6.png", alt: "6" },
    { src: "../../images/chibits/7.png", alt: "7" },
    { src: "../../images/chibits/8.png", alt: "8" },
    { src: "../../images/chibits/9.png", alt: "9" },
    { src: "../../images/chibits/10.png", alt: "10" },
    { src: "../../images/chibits/11.png", alt: "11" },
    { src: "../../images/chibits/12.png", alt: "12" },
    { src: "../../images/chibits/13.png", alt: "13" },
    { src: "../../images/chibits/14.png", alt: "14" },
    { src: "../../images/chibits/15.png", alt: "15" },
    { src: "../../images/chibits/16.png", alt: "16" },
    { src: "../../images/chibits/17.png", alt: "17" },
    { src: "../../images/chibits/18.png", alt: "18" },
    { src: "../../images/chibits/19.png", alt: "19" },
    { src: "../../images/chibits/20.png", alt: "20" },
    { src: "../../images/chibits/21.png", alt: "21" },
    { src: "../../images/chibits/22.png", alt: "22" },
    { src: "../../images/chibits/23.png", alt: "23" },
    { src: "../../images/chibits/24.png", alt: "24" },
    { src: "../../images/chibits/25.png", alt: "25" },
    { src: "../../images/chibits/26.png", alt: "26" },
    { src: "../../images/chibits/27.png", alt: "27" },
    { src: "../../images/chibits/28.png", alt: "28" },
    { src: "../../images/chibits/29.png", alt: "29" },
    { src: "../../images/chibits/30.png", alt: "30" },
    { src: "../../images/chibits/31.png", alt: "31" },
    { src: "../../images/chibits/32.png", alt: "32" },
    { src: "../../images/chibits/33.png", alt: "33" },
    { src: "../../images/chibits/34.png", alt: "34" },
    { src: "../../images/chibits/35.png", alt: "35" },
    { src: "../../images/chibits/36.png", alt: "36" },
    { src: "../../images/chibits/37.png", alt: "37" },
    { src: "../../images/chibits/38.png", alt: "38" },
    { src: "../../images/chibits/39.png", alt: "39" },
    { src: "../../images/chibits/40.png", alt: "40" },
    { src: "../../images/chibits/41.png", alt: "41" },
    { src: "../../images/chibits/42.png", alt: "42" },
    { src: "../../images/chibits/43.png", alt: "43" },
    { src: "../../images/chibits/44.png", alt: "44" },
    { src: "../../images/chibits/45.png", alt: "45" },
    { src: "../../images/chibits/46.png", alt: "46" },
    { src: "../../images/chibits/47.png", alt: "47" },
    { src: "../../images/chibits/48.png", alt: "48" },
    { src: "../../images/chibits/49.png", alt: "49" },
    { src: "../../images/chibits/50.png", alt: "50" },
    { src: "../../images/chibits/51.png", alt: "51" },
    { src: "../../images/chibits/52.png", alt: "52" },
    { src: "../../images/chibits/53.png", alt: "53" },
    { src: "../../images/chibits/54.png", alt: "54" },
    { src: "../../images/chibits/55.png", alt: "55" },
    { src: "../../images/chibits/56.png", alt: "56" },
    { src: "../../images/chibits/57.png", alt: "57" },
    { src: "../../images/chibits/58.png", alt: "58" },
    { src: "../../images/chibits/59.png", alt: "59" },
    { src: "../../images/chibits/60.png", alt: "60" },
    { src: "../../images/chibits/61.png", alt: "61" },
    { src: "../../images/chibits/62.png", alt: "62" },
    { src: "../../images/chibits/63.png", alt: "63" },
    { src: "../../images/chibits/64.png", alt: "64" },
    { src: "../../images/chibits/65.png", alt: "65" },
    { src: "../../images/chibits/66.png", alt: "66" },
    { src: "../../images/chibits/67.png", alt: "67" },
    { src: "../../images/chibits/68.png", alt: "68" },
    { src: "../../images/chibits/69.png", alt: "69" },
    { src: "../../images/chibits/70.png", alt: "70" },
    { src: "../../images/chibits/71.png", alt: "71" },
    { src: "../../images/chibits/72.png", alt: "72" },
    { src: "../../images/chibits/73.png", alt: "73" },
    { src: "../../images/chibits/74.png", alt: "74" },
    { src: "../../images/chibits/75.png", alt: "75" },
    { src: "../../images/chibits/76.png", alt: "76" },
    { src: "../../images/chibits/77.png", alt: "77" },
    { src: "../../images/chibits/78.png", alt: "78" },
    { src: "../../images/chibits/79.png", alt: "79" },
    { src: "../../images/chibits/80.png", alt: "80" },
    { src: "../../images/chibits/81.png", alt: "81" },
    { src: "../../images/chibits/82.png", alt: "82" },
    { src: "../../images/chibits/83.png", alt: "83" },
    { src: "../../images/chibits/84.png", alt: "84" },
    { src: "../../images/chibits/85.png", alt: "85" },
    { src: "../../images/chibits/86.png", alt: "86" },
    { src: "../../images/chibits/87.png", alt: "87" },
    { src: "../../images/chibits/88.png", alt: "88" },
    { src: "../../images/chibits/89.png", alt: "89" },
    { src: "../../images/chibits/90.png", alt: "90" },
    { src: "../../images/chibits/91.png", alt: "91" },
    { src: "../../images/chibits/92.png", alt: "92" },
    { src: "../../images/chibits/93.png", alt: "93" },
    { src: "../../images/chibits/94.png", alt: "94" },
    { src: "../../images/chibits/95.png", alt: "95" },
    { src: "../../images/chibits/96.png", alt: "96" },
    { src: "../../images/chibits/97.png", alt: "97" },
    { src: "../../images/chibits/98.png", alt: "98" },
    { src: "../../images/chibits/99.png", alt: "99" },
    { src: "../../images/chibits/100.png", alt: "100" },
    { src: "../../images/chibits/101.png", alt: "101" },
    { src: "../../images/chibits/102.png", alt: "102" },
    { src: "../../images/chibits/103.png", alt: "103" },
    { src: "../../images/chibits/104.png", alt: "104" },
    { src: "../../images/chibits/105.png", alt: "105" },
    { src: "../../images/chibits/106.png", alt: "106" },
    { src: "../../images/chibits/107.png", alt: "107" },
    { src: "../../images/chibits/108.png", alt: "108" },
    { src: "../../images/chibits/109.png", alt: "109" },
    { src: "../../images/chibits/110.png", alt: "110" },
  ];

  const handleImageClick = (image, number) => {
    onImageClick({
      value: number,
      label: (
        <div>
          <span>
            {number}
            <img
              src={image.src}
              alt={image.alt}
              style={{ width: "150px", marginRight: "10px" }}
            />
          </span>
        </div>
      ),
    });
  };

  return (
    <div className="container">
      <ul className="image-gallery">
        {images.map((image, index) => (
          <li key={index} onClick={() => handleImageClick(image, index + 1)}>
            <img src={image.src} alt={image.alt} />
            <div className="overlay">
              <span>{image.alt}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ChibiGallery;
