import React, { useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import MultiSelectPopup from "../MultiSelectPopup/MultiSelectPopup";
import { Link } from "react-router-dom";
import AttendancePopup from "../AttendancePopup/AttendancePopup";

function FooterTools({ toggleOrder, students, onStudentsUpdate, accountType }) {
  const { t } = useTranslation();
  const isSorted = localStorage.getItem("isSorted") === "true";
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAttendancePopupOpen, setIsAttendancePopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleOpenAttendancePopup = () => {
    setIsAttendancePopupOpen(true);
  };

  const handleCloseAttendancePopup = () => {
    setIsAttendancePopupOpen(false);
  };

  return (
    <div className="footer-tools">
      {accountType === "silver" || accountType === "admin" ? (
        <>
          <button className="footer-button" onClick={handleOpenAttendancePopup}>
            {t("takeAttendance")}
          </button>
        </>
      ) : (
        ""
      )}
      &nbsp;
      {/* Botón deslizante para ordenar */}
      <button className="footer-button" onClick={toggleOrder}>
        <span className="button-text">
          {isSorted ? t("orderOrigin") : t("orderAbc")}
        </span>
      </button>
      &nbsp;
      <button className="footer-button" onClick={handleOpenPopup}>
        <span className="button-text">{t("multipleSelect")}</span>
      </button>
      {students ? (
        <>
          <AttendancePopup
            isOpen={isAttendancePopupOpen}
            onClose={handleCloseAttendancePopup}
            students={students}
          />
          <MultiSelectPopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            students={students}
            onStudentsUpdate={onStudentsUpdate}
          />
        </>
      ) : (
        ""
      )}
      &nbsp;
      <Link className="footer-button" to="/goal-planner">
        {t("goal_planner")}
      </Link>
    </div>
  );
}

export default FooterTools;
