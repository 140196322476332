import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import MainContainer from "./MainContainer";
import store from "./store";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
  <React.StrictMode>
    <BrowserRouter>
      <MainContainer />
    </BrowserRouter>
  </React.StrictMode>
  </Provider>,
);

reportWebVitals();
