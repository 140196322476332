import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Header/styles.css";

function StudentGroupHeader() {
  const { t } = useTranslation();

  return (
    <>
      <div className="centerBtn studentGroupHeader">
        <Link className="button-15" to="/game">
          {t("students")}
        </Link>
        <Link className="button-15" to="/renderGroups">
          {t("groups")}
        </Link>
        <Link className="button-15" to="/renderBusiness">
          {t("business")}
        </Link>
      </div>
    </>
  );
}

export default StudentGroupHeader;
