const starterPets = [
  {
    id: 1,
    name: "Bluy",
    img: "../../images/pets/Bluy.png",
  },
  {
    id: 2,
    name: "Catty",
    img: "../../images/pets/Catty.png",
  },
  {
    id: 3,
    name: "Creeper",
    img: "../../images/pets/Creeper.png",
  },
  {
    id: 4,
    name: "Dino",
    img: "../../images/pets/Dino.png",
  },
  {
    id: 5,
    name: "Doggy",
    img: "../../images/pets/Doggy.png",
  },
  {
    id: 6,
    name: "Firy",
    img: "../../images/pets/Firy.png",
  },
  {
    id: 7,
    name: "Fishy",
    img: "../../images/pets/Fishy.png",
  },
  {
    id: 8,
    name: "Hamster",
    img: "../../images/pets/Hamster.png",
  },
  {
    id: 9,
    name: "Hedgehog",
    img: "../../images/pets/Hedgehog.png",
  },
  {
    id: 10,
    name: "Parrot",
    img: "../../images/pets/Parrot.png",
  },
  {
    id: 11,
    name: "Pinky",
    img: "../../images/pets/Pinky.png",
  },
  {
    id: 12,
    name: "Unicorn",
    img: "../../images/pets/Unicorn.png",
  },
  {
    id: 13,
    name: "Horsy",
    img: "../../images/pets/Horsy.png",
  },
  {
    id: 14,
    name: "Lion",
    img: "../../images/pets/Lion.png",
  },
  {
    id: 15,
    name: "Octopus",
    img: "../../images/pets/Octopus.png",
  },
  {
    id: 16,
    name: "Penguin",
    img: "../../images/pets/Penguin.png",
  },
  {
    id: 17,
    name: "Rabby",
    img: "../../images/pets/Rabby.png",
  },
  {
    id: 18,
    name: "Robby",
    img: "../../images/pets/Robby.png",
  },
  {
    id: 19,
    name: "Sharky",
    img: "../../images/pets/Sharky.png",
  },
  {
    id: 20,
    name: "Torti",
    img: "../../images/pets/Torti.png",
  },
  {
    id: 21,
    name: "Cheetah",
    img: "../../images/pets/Cheetah.png",
  },
  {
    id: 22,
    name: "Girif",
    img: "../../images/pets/Girif.png",
  },
  {
    id: 23,
    name: "Happy",
    img: "../../images/pets/Happy.png",
  },
  {
    id: 24,
    name: "Koala",
    img: "../../images/pets/Koala.png",
  },
  {
    id: 25,
    name: "Komodo",
    img: "../../images/pets/Komodo.png",
  },
  {
    id: 26,
    name: "Phanty",
    img: "../../images/pets/Phanty.png",
  },
  {
    id: 27,
    name: "Rhyno",
    img: "../../images/pets/Rhyno.png",
  },
  {
    id: 28,
    name: "Snaky",
    img: "../../images/pets/Snaky.png",
  },
  {
    id: 29,
    name: "Tiger",
    img: "../../images/pets/Tiger.png",
  },
];

export default starterPets;
