const starterNegativesEs = [
  {
    id: 0,
    name: "Molestar a los compañeros",
    emoji: "😤",
    points: 1,
  },
  {
    id: 1,
    name: "No estar atento",
    emoji: "🙄",
    points: 1,
  },
  {
    id: 2,
    name: "No hacer los deberes",
    emoji: "✍️",
    points: 1,
  },
  {
    id: 3,
    name: "No traer el material",
    emoji: "🤐",
    points: 1,
  },
  {
    id: 4,
    name: "Balancearse en la silla",
    emoji: "🪑",
    points: 1,
  },
  {
    id: 5,
    name: "Faltar el respeto a un compañero",
    emoji: "🤬",
    points: 2,
  },
  {
    id: 6,
    name: "Pegarse con un compañero",
    emoji: "🤼",
    points: 4,
  },
  {
    id: 7,
    name: "No hacer caso al maestro",
    emoji: "👨‍🏫",
    points: 5,
  },
];

export default starterNegativesEs;
