import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Components/Header/Header";
import "./PergaminoStyles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Pergamino() {
  const { t } = useTranslation();
  const pergaminos = useSelector((state) => state.tools.pergaminos) || [];
  const [displayedPergaminoId, setDisplayedPergaminoId] = useState(null);

  const getRandomPergamino = () => {
    let displayedIds =
      JSON.parse(localStorage.getItem("displayedPergaminoIds")) || [];

    const availablePergaminos = pergaminos.filter(
      (pergamino) => !displayedIds.includes(pergamino.id)
    );

    if (availablePergaminos.length === 0) {
      // Resetear si todos los pergaminos han sido mostrados
      localStorage.removeItem("displayedPergaminoIds");
      displayedIds = [];
      return t("endedPergams");
    }

    const randomIndex = Math.floor(Math.random() * availablePergaminos.length);
    const selectedPergamino = availablePergaminos[randomIndex];

    displayedIds.push(selectedPergamino.id);
    localStorage.setItem("displayedPergaminoIds", JSON.stringify(displayedIds));
    setDisplayedPergaminoId(selectedPergamino.id);

    return selectedPergamino.text;
  };

  const showPergamino = () => {
    document.getElementById("frasePergamino").innerHTML = getRandomPergamino();
  };

  const deletePergamino = () => {
    document.getElementById("frasePergamino").innerHTML = "";
  };
  return (
    <>
      <Header />
      <button
        className="createGroupsButton pergaButton"
        type="button"
        onClick={showPergamino}
      >
        {t("openPergam")}
      </button>
      <button
        className="pergamCloseBtn"
        type="button"
        onClick={deletePergamino}
      >
        {t("close")}
      </button>

      <div className="containerPergamino">
        <img
          src="https://recursostic.net/wp-content/uploads/2021/03/pergamino.png"
          className="pergaImg"
          alt="Pergamino del destino"
          style={{ marginBottom: -75 }}
        />

        <div class="containerFraseBonita">
          <div class="fraseBonita-container">
            <h3 id="frasePergamino"></h3>
          </div>
          <div class="enter-options"></div>
        </div>

        <div class="containerPergamino">
          <img
            src="https://recursostic.net/wp-content/uploads/2021/03/pergamino.png"
            className="pergaImg"
            alt="Pergamino del destino"
            style={{ marginTop: -75 }}
          />
        </div>
      </div>
      <div className="footer-pergam-btns">
        <Link className="btn btn-edit" to="/add-pergam">
          {t("addPergam")}
        </Link>
        <Link className="btn btn-edit" to="/edit-pergams">
          {t("editPergams")}
        </Link>
      </div>
    </>
  );
}
