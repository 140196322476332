import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePositiveThunk,
  deleteAllPositivesThunk,
} from "../features/behaviours/behavioursThunks";

const EditBehaviourP = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const positives = useSelector((state) => state.behaviours.positives);

  async function onDelete(idToBeDeleted) {
    dispatch(deletePositiveThunk(userEmail, idToBeDeleted, positives));
  }

  async function deleteAllRules() {
    dispatch(deleteAllPositivesThunk(userEmail));
  }

  return (
    <>
      <Header />
      <div style={{ marginTop: "50px" }}>
        <h2 className="editTitle">{t("positives")}</h2>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>Conducta</th>
              <th style={{ textAlign: "center" }}>Emoji</th>
              <th style={{ textAlign: "center" }}>{t("points")}</th>
              <th style={{ textAlign: "center" }}>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(positives).map((id, index) => {
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{positives[id].name}</td>
                  <td>{positives[id].emoji}</td>
                  <td>{positives[id].points}</td>
                  <td>
                    <Link to={`/updatePositive/${positives[id].id}`}>
                      <button className="btn btn-edit">Editar</button>
                    </Link>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(positives[id].id)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button className="button-error" onClick={deleteAllRules}>
          {t("deleteAll")}
        </button>
      </div>
    </>
  );
};

export default EditBehaviourP;
