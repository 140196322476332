import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header/Header";
import StudentGroupHeader from "../Components/StudentGroupHeader";
import "./react-select-custom.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addGroup } from "../features/groups/groupsSlice";

import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import { ToastContainer } from "react-toastify";

import firebaseApp from "../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
const firestore = getFirestore(firebaseApp);

function AddGroup() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students);
  const groups = useSelector((state) => state.groups.groups);

  const [arraySettings, setArraySettings] = useState([]);

  //fn to force re-rendering
  const navigate = useNavigate();

  function updateReRender() {
    navigate("/settings");
  }

  async function getLastGroupId(userEmail) {
    let lastGroupId = 0; // asumimos 0 si no hay grupos
    if (groups && groups.length > 0) {
      lastGroupId = Math.max(...groups.map((group) => group.id));
    }
    return lastGroupId;
  }

  async function addGroupForm(e) {
    e.preventDefault();
    const selectedStudentIds = selectedStudents.map((option) => option.value);

    const docuRef = doc(firestore, `usuarios/${userEmail}`);

    const lastGroupId = await getLastGroupId(userEmail);
    const newGroupId = lastGroupId + 1; // generamos el siguiente id

    const newArraySettings = [
      ...groups,
      {
        id: newGroupId,
        name: groupName,
        students: selectedStudentIds,
      },
    ];

    // Actualiza la base de datos
    await updateDoc(docuRef, { groups: newArraySettings });

    dispatch(
      addGroup({
        id: newGroupId,
        name: groupName,
        students: selectedStudentIds,
      })
    );

    correctToast(t("groupAddedMsg"));
    setTimeout(() => updateReRender(), 500);
  }

  const options = students.map((student) => ({
    value: student.id,
    label: student.name,
  }));
  const [selectedStudents, setSelectedStudents] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedStudents(selectedOptions || []);
  };

  // Declara una nueva pieza de estado para almacenar el nombre del grupo
  const [groupName, setGroupName] = useState("");

  // Manejador para actualizar el nombre del grupo en el estado cuando cambie el input
  const handleNameChange = (event) => {
    setGroupName(event.target.value);
  };

  return (
    <>
      <Header />
      <StudentGroupHeader />

      <form
        onSubmit={addGroupForm}
        id="addGroupForm"
        style={{
          margin: "auto",
          padding: "15px",
          maxWidth: "400px",
          alignContent: "center",
        }}
      >
        <label hidden htmlFor="id">
          Id
        </label>
        <input type="hidden" id="id" name="id" placeholder="Id" />

        <label htmlFor="nameInput">{t("groupName")}</label>
        <input
          type="text"
          id="nameInput"
          name="name"
          required
          onChange={handleNameChange}
        />

        <label htmlFor="students">{t("students")}</label>
        <Select
          styles={{
            control: (base) => ({
              ...base,
              height: "200px", // Ajusta la altura del input
            }),
          }}
          isMulti
          name="students"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleSelectChange}
        />
        <input type="submit" value="Guardar" />
      </form>
      <ToastContainer />
    </>
  );
}

export default AddGroup;
