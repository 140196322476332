import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import "./DailySentenceStyles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DailySentence() {
  const { t, i18n } = useTranslation();
  const dailySentences =
    useSelector((state) => state.tools.dailySentences) || [];
  const [displayedSentenceId, setDisplayedSentenceId] = useState(null);

  const getRandomSentence = () => {
    let displayedIds =
      JSON.parse(localStorage.getItem("displayedSentenceIds")) || [];

    const availableSentences = dailySentences.filter(
      (sentence) => !displayedIds.includes(sentence.id)
    );

    if (availableSentences.length === 0) {
      // Resetear si todos los Sentences han sido mostrados
      localStorage.removeItem("displayedSentenceIds");
      displayedIds = [];
      return t("endedSentences");
    }

    const randomIndex = Math.floor(Math.random() * availableSentences.length);
    const selectedSentence = availableSentences[randomIndex];

    displayedIds.push(selectedSentence.id);
    localStorage.setItem("displayedSentenceIds", JSON.stringify(displayedIds));
    setDisplayedSentenceId(selectedSentence.id);

    return selectedSentence.text;
  };

  const getSentence = () => {
    document.getElementById("fraseBonita").innerHTML = getRandomSentence();
  };

  return (
    <>
      <Header />
      <main>
        <div style={{ padding: 70 }}>
          <button class="sentenceButton" type="button" onClick={getSentence}>
            {t("niceSentence")}
          </button>
          <div className="sentenceContainer">
            <img
              src="/images/chibi_yoda.png"
              alt="yoda"
              width="350px"
              height="300px"
            />
            <div class="fraseBonitaContainer">
              <h3 id="fraseBonita"> </h3>
            </div>
          </div>
        </div>
      </main>
      <div className="footer-pergam-btns">
        <Link className="btn btn-edit" to="/add-sentence">
          {t("addSentence")}
        </Link>
        <Link className="btn btn-edit" to="/edit-sentences">
          {t("editSentences")}
        </Link>
      </div>
    </>
  );
}
