import Header from "../Components/Header/Header";
import "./ToolsStyles.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useRequireAuth from "../hooks/useRequireAuth";

export default function Tools() {
  const { t } = useTranslation();
  useRequireAuth();
  return (
    <>
      <Header />
      <div>
        <div className="container toolsContainer">
          <div>
            <Link to="/soundMeter">
              <img
                src="../../images/tools/semaforo.png"
                alt="Medidor de ruido"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("soundMeter")}</figcaption>
          </div>
          <div>
            <Link to="/dailySentence">
              <img
                src="../../images/tools/frase_diaria.png"
                alt="Frase diaria"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">
              {t("dailySentence")}
            </figcaption>
          </div>
          <div>
            <Link to="/pergamino">
              <img
                src="../../images/tools/pergamino.png"
                alt="Frase diaria"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("pergamino")}</figcaption>
          </div>
          <div>
            <Link to="/groups">
              <img
                src="../../images/tools/groups.png"
                alt="Generador de grups"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">
              {t("groupGenerator")}
            </figcaption>
          </div>
          <div>
            <Link to="/randomUser">
              <img
                src="../../images/tools/random.png"
                alt="Usuari aleatori"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("randomChibit")}</figcaption>
          </div>
          <div>
            <Link to="/chronometer">
              <img
                src="../../images/tools/chronometer.png"
                alt="Cronòmetre"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("chrono")}</figcaption>
          </div>
          <div>
            <Link to="/exchange">
              <img
                src="../../images/tools/exchange.png"
                alt="Intercanvi"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">
              {t("exchangePoints")}
            </figcaption>
          </div>
          <div>
            <Link to="/todo">
              <img
                src="../../images/tools/todo-list.png"
                alt="ToDo"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("todoTool")}</figcaption>
          </div>
          <div>
            <Link to="/seating-generator">
              <img
                src="../../images/tools/seatgenerator.png"
                alt="Seat generator"
                className="toolsCircle"
              />
            </Link>
            <figcaption className="textCaption">{t("seatTool")}</figcaption>
          </div>
        </div>
      </div>
    </>
  );
}
