import React, { useState, useEffect } from 'react';
import "./CookieBanner.css";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verificar si el usuario ya dio su consentimiento
    const consentCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('cookieConsent='));

    if (!consentCookie) {
      setShowBanner(true);
    }
  }, []);

  const handleCookies = (consent) => {
    // Guardar el consentimiento en una cookie que expira en un año
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    document.cookie = `cookieConsent=${consent}; expires=${date.toUTCString()}; path=/`;
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner-container">
          <p className="cookie-banner-text">
            Este sitio web utiliza cookies para mejorar tu experiencia.
          </p>
          <button className="cookie-accept-button" onClick={() => handleCookies(true)}>
            Aceptar
          </button>
          <button onClick={() => handleCookies(false)}>
            Rechazar
          </button>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
