import React from "react";
import { Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./routes/Home";
import Tools from "./routes/Tools";
import Groups from "./routes/Groups";
import Pergamino from "./routes/Pergamino";
import RandomUser from "./routes/RandomUser";
import SoundMeter from "./routes/SoundMeter";
import Chronometer from "./routes/Chronometer";
import DailySentence from "./routes/DailySentence";
import AddStudent from "./routes/AddStudent";
import AddPet from "./routes/AddPet";
import AddPositive from "./routes/AddPositive";
import AddNegative from "./routes/AddNegative";
import AddExchange from "./routes/AddExchange";
import EditStudent from "./routes/EditStudent";
import EditBehaviourP from "./routes/EditBehaviourP";
import EditBehaviourN from "./routes/EditBehaviourN";
import EditBehaviourE from "./routes/EditBehaviourE";
import EditClassroom from "./routes/EditClassroom";
import EditPositive from "./routes/EditPositive";
import EditNegative from "./routes/EditNegative";
import EditExchange from "./routes/EditExchange";
import Settings from "./routes/Settings";
import ExchangePoints from "./routes/ExchangePoints";
import ChooseChibit from "./routes/ChooseChibit";
import Pets from "./routes/Pets";
import AddGroup from "./routes/AddGroup";
import RenderGroups from "./Components/RenderGroups/RenderGroups";
import EditGroups from "./routes/EditGroups";
import UpdateGroup from "./routes/UpdateGroup";
import AddClassroom from "./routes/AddClassroom";
import Team from "./pages/team/Team";
import Contact from "./pages/contact/contact";
import Press from "./pages/press/press";
import LegalNotice from "./pages/legal/legal-notice";
import PrivacyPolicy from "./pages/legal/privacy";
import Cookies from "./pages/legal/cookies";
import Profile from "./routes/Profile";
import ToDo from "./Components/ToDo/ToDo";
import SeatingGenerator from "./routes/SeatingGenerator";
import GoalPlanner from "./routes/GoalPlanner";
import StudentRecords from "./Components/StudentRecords/StudentRecords";
import AddPergamino from "./routes/AddPergamino";
import EditPergamino from "./routes/EditPergamino";
import EditPergaminoList from "./routes/EditPergaminoList";
import "./i18n";
import Tutorial from "./pages/tutorial/tutorial";
import ThankYou from "./routes/thank-you";
import AddDailySentence from "./routes/AddDailySentence";
import EditSentence from "./routes/EditSentence";
import EditSentenceList from "./routes/EditSentenceList";
import StudentProfile from "./routes/StudentProfile";
import RenderBusiness from "./Components/RenderBusiness";
import BusinessAddList from "./routes/BusinessAddList";
import AddBusiness from "./routes/AddBusiness";
import InfoBusiness from "./routes/InfoBusiness";
import BusinessGallery from "./routes/BusinessGallery";
import AdminPanel from "./routes/AdminPanel";

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="game" element={<App />} />
      <Route path="tools" element={<Tools />} />
      <Route path="groups" element={<Groups />} />
      <Route path="pergamino" element={<Pergamino />} />
      <Route path="randomUser" element={<RandomUser />} />
      <Route path="soundMeter" element={<SoundMeter />} />
      <Route path="chronometer" element={<Chronometer />} />
      <Route path="dailySentence" element={<DailySentence />} />
      <Route path="add" element={<AddStudent />} />
      <Route path="addPositive" element={<AddPositive />} />
      <Route path="addNegative" element={<AddNegative />} />
      <Route path="addExchange" element={<AddExchange />} />
      <Route path="editclassroom" element={<EditClassroom />} />
      <Route path="update/:id" element={<EditStudent />} />
      <Route path="addPet/:id" element={<AddPet />} />
      <Route path="editBehaviourP" element={<EditBehaviourP />} />
      <Route path="editBehaviourN" element={<EditBehaviourN />} />
      <Route path="editBehaviourE" element={<EditBehaviourE />} />
      <Route path="updatePositive/:id" element={<EditPositive />} />
      <Route path="updateNegative/:id" element={<EditNegative />} />
      <Route path="updateExchange/:id" element={<EditExchange />} />
      <Route path="settings" element={<Settings />} />
      <Route path="exchange" element={<ExchangePoints />} />
      <Route path="chooseChibit" element={<ChooseChibit />} />
      <Route path="pets" element={<Pets />} />
      <Route path="addGroup" element={<AddGroup />} />
      <Route path="renderGroups" element={<RenderGroups />} />
      <Route path="editGroups" element={<EditGroups />} />
      <Route path="updateGroup/:id" element={<UpdateGroup />} />
      <Route path="addClassroom" element={<AddClassroom />} />
      <Route path="team" element={<Team />} />
      <Route path="contact" element={<Contact />} />
      <Route path="press" element={<Press />} />
      <Route path="legal-notice" element={<LegalNotice />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="cookies" element={<Cookies />} />
      <Route path="todo" element={<ToDo />} />
      <Route path="tutorial" element={<Tutorial />} />
      <Route path="profile" element={<Profile />} />
      <Route path="seating-generator" element={<SeatingGenerator />} />
      <Route path="goal-planner" element={<GoalPlanner />} />
      <Route path="records/:id" element={<StudentRecords />} />
      <Route path="add-pergam" element={<AddPergamino />} />
      <Route path="edit-pergams" element={<EditPergaminoList />} />
      <Route path="update-pergam/:id" element={<EditPergamino />} />
      <Route path="thank-you" element={<ThankYou />} />
      <Route path="add-sentence" element={<AddDailySentence />} />
      <Route path="edit-sentences" element={<EditSentenceList />} />
      <Route path="update-sentence/:id" element={<EditSentence />} />
      <Route path="student-profile/:id" element={<StudentProfile />} />
      <Route path="renderBusiness" element={<RenderBusiness />} />
      <Route path="business-list" element={<BusinessAddList />} />
      <Route path="business-info" element={<InfoBusiness />} />
      <Route path="business-gallery" element={<BusinessGallery />} />
      <Route path="add-business/:id" element={<AddBusiness />} />
      <Route path="admin-panel" element={<AdminPanel />} />
    </Routes>
  );
}

export default RoutesComponent;
