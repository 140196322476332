import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import { useTranslation } from "react-i18next";

function LegalNotice() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <h1 className="pages-h1">{t("legalNotice")}</h1>
      <div className="content">
        <div className="container containerTeam">
          <div className="info">
            <p>
              Este Aviso Legal tiene el objetivo de informarte de tus derechos y
              obligaciones como usuario de este sitio web. Aquí encontrarás toda
              la información que necesitas sobre este sitio web, su actividad,
              los datos personales que recaba y su finalidad, así como las
              normas de uso que regulan el uso de esta web.
            </p>

            <p>
              En el momento en que accedas a este sitio web&nbsp;
              <strong>https://classbits.net,</strong> asumes la condición de
              usuario, por lo que el contenido de este Aviso Legal te afecta
              directamente. Por eso, es importante que lo leas para disipar
              cualquier duda que puedas tener y tener conocimiento de causa
              sobre las condiciones que estás aceptando.
            </p>

            <p>
              Para empezar, debes saber que este sitio web cumple con las
              normativas vigentes en materia de protección de datos, con el
              objetivo de aportarte las garantías, la seguridad y la
              transparencia que, como usuario te corresponden, a la hora de
              utilizar esta web.
            </p>

            <p>
              El&nbsp;<strong>RGPD&nbsp;</strong>(
              <strong>
                Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de
                27 de abril de 2016 relativo a la protección de las personas
                físicas
              </strong>
              )&nbsp;que es la nueva normativa de la Unión Europea que unifica
              la regulación del tratamiento de los datos personales en los
              distintos países de la UE.
            </p>

            <p>
              La&nbsp;<strong>LOPD</strong>&nbsp;(
              <strong>
                Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos
                de Carácter Personal
              </strong>
              &nbsp;y&nbsp;
              <strong>
                Real Decreto 1720/2007, de 21 de diciembre, el Reglamento de
                desarrollo de la LOPD
              </strong>
              ) que regula el tratamiento de los datos personales y las
              obligaciones que debemos asumir los responsables de una web o un
              blog a la hora de gestionar esta información.
            </p>

            <p>
              La&nbsp;<strong>LSSI</strong>&nbsp;(
              <strong>
                Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
                Información y Comercio Electrónico
              </strong>
              )&nbsp;que regula las transacciones económicas mediante medios
              electrónicos, como es el caso de este blog.
            </p>

            <p>
              <strong>DATOS DE IDENTIFICACIÓN</strong>
            </p>

            <p>
              El responsable y titular de este sitio web es Cristian Viaplana (En
              adelante<strong> CV</strong>)
            </p>

            <ul>
              <li>
                <strong>Nombre:&nbsp;</strong> CV
              </li>

              <li>
                <strong>Domicilio Social:</strong> C/ Cronista Muntaner 25,
                bajos Lleida
              </li>

              <li>
                <strong>Actividad del sitio web</strong>: plataforma de gamificación para entornos educativos.
              </li>

              <li>
                <strong>Correo electrónico</strong>:&nbsp;
                <strong>
                  <a href="mailto:info@classbits.net">
                    info[at]classbits.net
                  </a>
                </strong>
              </li>
            </ul>

            <p>
              Los datos que nos facilites con tu consentimiento, y de acuerdo al
              uso establecido en nuestra Política de Privacidad, serán
              incorporados a un fichero automatizado debidamente inscrito en la
              Agencia Española de Protección de Datos, en el que el responsable
              de dicho fichero es: <strong>CV.&nbsp;</strong>Esto quiere decir
              que tus datos están seguros, de acuerdo a lo que establece la ley.
            </p>

            <p>
              <strong>NORMAS DE ACCESO AL SITIO WEB</strong>
            </p>

            <p>
              Como usuario de nuestra web, también tienes una serie de
              obligaciones:
            </p>

            <p>
              No podrás utilizar este sitio web para la realización de
              actividades contrarias a las leyes, a la moral, al orden público
              y, en general, a hacer un uso conforme a las condiciones
              establecidas en el presente Aviso Legal.
            </p>

            <p>
              No podrás realizar actividades publicitarias o de explotación
              comercial remitiendo mensajes que utilicen una identidad falsa.
            </p>

            <p>
              Tú serás el único responsable de la veracidad y exactitud de los
              contenidos que introduzcas en esta web y de los datos personales
              que nos facilites con las finalidades previstas en nuestra
              Política de Privacidad.
            </p>

            <p>
              También serás el único responsable de&nbsp;la realización de
              cualquier tipo de actuación ilícita, lesiva de derechos, nociva
              y/o perjudicial en los sitios de terceros a los que te podamos
              derivar desde este sitio web para el desarrollo de nuestra
              actividad.
            </p>

            <p>
              Como responsable del sitio web, <strong>CV </strong>podrá
              interrumpir el servicio de la página que esté siendo utilizado por
              el usuario y resolver de modo inmediato la relación si detecta un
              uso de la web o de cualquiera de los servicios que en la mismo se
              ofertan que pueda considerarse contrario a lo expresado en el
              presente Aviso Legal.
            </p>

            <p>
              <strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
            </p>

            <p>
              La totalidad de este sitio web (texto, imágenes, marcas, gráficos,
              logotipos, botones, archivos de software, combinaciones de
              colores, así como la estructura, selección, ordenación y
              presentación de sus contenidos) se encuentra protegida por las
              leyes vigente sobre Propiedad Intelectual e Industrial, quedando
              prohibida su reproducción, distribución, comunicación pública y
              transformación, salvo para uso personal y privado.
            </p>

            <p>
              Como titular de este sitio web,<strong> CV</strong>no garantiza
              que los contenidos sean precisos o libres de error o que el libre
              uso de los mismos por parte de los usuarios no infrinja los
              derechos de terceras partes. El buen o mal uso de esta página y de
              sus contenidos es responsabilidad del usuario.
            </p>

            <p>
              Asimismo, queda prohibida la reproducción, retransmisión, copia,
              cesión o redifusión, total o parcial, de la información contenida
              en la página, cualquiera que fuera su finalidad y el medio
              utilizado para ello, sin autorización previa de{" "}
              <strong>CV.</strong>
            </p>

            <p>
              <strong>ENLACES O LINKS</strong>
            </p>

            <p>
              Este sitio web puede incluir enlaces o links a sitios de terceros. Las
              páginas pertenecientes a estos terceros no han sido revisadas ni
              son objeto de controles por nuestra parte, por lo que{" "}
              <strong>CV </strong>no podrá ser considerado responsable de los
              contenidos de estos sitios web, ni de las medidas que se adopten
              relativas a su privacidad o al tratamiento de sus datos de
              carácter personal u otros que pudieran derivarse.
            </p>

            <p>
              Por todo ello, te recomendamos&nbsp;la lectura detenida de las
              condiciones de uso, política de privacidad, avisos legales y/o
              similares de estos sitios.
            </p>

            <p>
              <strong>LIMITACIÓN DE RESPONSABILIDAD</strong>
            </p>

            <p>
              En ejercicio de su derecho como titular de esta web, te informamos
              de que <strong>CV</strong> no se hace responsable en ningún caso
              de lo siguiente:
            </p>

            <p>
              La calidad del servicio, la velocidad de acceso, el correcto
              funcionamiento ni la disponibilidad ni continuidad de
              funcionamiento de la página.
            </p>

            <p>
              La existencia de virus, malware, programas maliciosos o dañinos en
              los contenidos.
            </p>

            <p>
              El uso ilícito, negligente, fraudulento o contrario a este Aviso
              Legal.
            </p>

            <p>
              La falta de licitud, calidad, fiabilidad, utilidad y
              disponibilidad de los servicios prestados por terceros y puestos a
              disposición de los usuarios en este sitio web.
            </p>

            <p>
              De los daños que pudieran provenir del uso ilegal o indebido de
              este blog.
            </p>

            <p>
              <strong>POLITICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</strong>
            </p>

            <p>
              Este sitio web cumple con la normativa vigente en materia de
              protección de datos, lo que implica que, como
              usuario,&nbsp;deberás dar tu consentimiento expreso antes de
              facilitarnos datos personales a través de los diferentes
              formularios puestos a disposición en las secciones de nuestra
              página.
            </p>

            <p>
              Para ello, en aras de la transparencia y el ejercicio de tu
              derecho, nuestro deber es informarte sobre los datos personales
              que recabamos, almacenamos y tratamos y con qué finalidades,
              teniendo en cualquier momento la posibilidad de revocar libremente
              tu consentimiento.
            </p>

            <p>
              Toda esta información la podrás encontrar en nuestra&nbsp;
              <strong>POLITICA DE PRIVACIDAD.</strong>
            </p>

            <p>
              <strong>POLÍTICA DE COOKIES</strong>
            </p>

            <p>
              Tal y como te informamos nada más acceder a nuestra web, este
              sitio utiliza cookies propias y de terceros con el objetivo de
              proporcionarte la mejor experiencia de usuario y desarrollar
              nuestra actividad.
            </p>

            <p>
              En cualquier momento, tendrás la opción de configurar tu navegador
              para desestimar el uso de estas cookies que, en algún caso,
              afectarán a tu experiencia de usuario.
            </p>

            <p>
              Para acceder a la información completa sobre el uso de cookies en
              este sitio web, su finalidad y su desestimación, puedes consultar
              nuestra&nbsp;<strong>POLÍTICA DE COOKIES</strong>.
            </p>

            <p>
              <strong>LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</strong>
            </p>

            <p>
              El presente Aviso Legal se encuentra sometido a la legislación
              española vigente.
            </p>

            <p>
              En caso necesario, ante cualquier tipo de controversia de carácter
              legal, <strong>CV </strong>y el usuario, con renuncia expresa a
              cualquier otro fuero, se someterán a los Juzgados y Tribunales del
              domicilio del Usuario para cualquier controversia que pudiera
              derivarse.
            </p>

            <p>
              En el caso de que el usuario tenga su domicilio fuera de España,{" "}
              <strong>CV </strong>y el usuario se someterán, con renuncia
              expresa a cualquier otro fuero, a los juzgados y tribunales de
              Barcelona (España).
            </p>

            <p>
              Si tienes cualquier duda sobre este Aviso Legal, puedes enviarnos
              un correo electrónico a&nbsp;
              <strong>
                <a href="mailto:info@classbits.net">
                  info@classbits.net
                </a>
              </strong>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default LegalNotice;
