import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./EditClassroom.css";

import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  migratePetRewardData,
  updatePetRewardStatus,
} from "../features/settings/settingsThunks";

const Pets = () => {
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students) || [];
  const petReward = useSelector((state) => state.settings.petReward);
  const userEmail = useSelector((state) => state.auth.userEmail);

  const { t } = useTranslation();

  // MIGRATION code, to check if there's an user option saved on old localstorage
  useEffect(() => {
    dispatch(migratePetRewardData(userEmail));
  }, [dispatch]);

  const activatePetRewards = () => {
    dispatch(updatePetRewardStatus(userEmail, true));
  };

  const cancelPetRewards = () => {
    dispatch(updatePetRewardStatus(userEmail, false));
  };

  const statusMsg = petReward ? "🟢" : "🔴";

  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" }}>
        <div className="settings">
          <p>
            {t("activatePetRewards")} {statusMsg}
          </p>
        </div>
        <div className="settings">
          <button className="button-success" onClick={activatePetRewards}>
            Activar
          </button>
          <button className="button-error" onClick={cancelPetRewards}>
            Desactivar
          </button>
        </div>
        <table className="styled-table">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>No.</th>
              <th style={{ textAlign: "center" }}>{t("studentName")}</th>
              <th style={{ textAlign: "center" }}>{t("studentSurname")}</th>
              <th style={{ textAlign: "center" }}>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(students).map((id, index) => {
              return (
                <tr key={id}>
                  <th scope="row">{index + 1}</th>
                  <td>{students[id].name}</td>
                  <td>{students[id].surname}</td>
                  <td>
                    <Link to={`/addPet/${students[id].id}?buy`}>
                      <button className="button-add-pet">🐶 Mascota</button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Pets;
