import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../Components/Header/Header";
import "./ChooseChibit.css";
import { useTranslation } from "react-i18next";

export default function SeatingGenerator() {
  const students = useSelector((state) => state.students) || [];
  const filteredStudents = students.filter(student => student.id !== 0);
  const { t } = useTranslation();

  const [divs, setDivs] = useState([]);
  const isDragging = useRef(false);
  const currentDiv = useRef(null);
  const offsetX = useRef(0);
  const offsetY = useRef(0);

  // Usar useRef para mantener el estado entre renderizados sin causar renderizados adicionales
  const shuffledStudents = useRef(shuffle([...filteredStudents]));  // Array mezclado de estudiantes
  const studentIndex = useRef(0);  // Índice del próximo estudiante a mostrar

  // Función para mezclar un array usando el algoritmo de Fisher-Yates
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const handleMouseDown = (event) => {
    let element = event.target.closest('.draggable');
    if (element) {
      isDragging.current = true;
      currentDiv.current = element;
      const rect = currentDiv.current.getBoundingClientRect();
      offsetX.current = event.clientX - rect.left;
      offsetY.current = event.clientY - rect.top;
    }
  };
  

  const handleMouseMove = (event) => {
    if (isDragging.current) {
      const newLeft = event.clientX - offsetX.current;
      const newTop = event.clientY - offsetY.current;
      currentDiv.current.style.left = newLeft + 'px';
      currentDiv.current.style.top = newTop + 'px';
    }
  };


  const handleMouseUp = () => {
    isDragging.current = false;
    currentDiv.current = null;
  };

  const handleClick = (event) => {
    if (studentIndex.current >= shuffledStudents.current.length) {
      return;
    }
    // Obtener el próximo estudiante del array mezclado
    const student = shuffledStudents.current[studentIndex.current];
    studentIndex.current++; 


     // Obtén el desplazamiento del contenedor y el desplazamiento de la página
     const containerRect = event.currentTarget.getBoundingClientRect();
     const scrollLeft = window.scrollX;
     const scrollTop = window.scrollY;
 
     // Calcula las nuevas coordenadas
     const newLeft = event.clientX - containerRect.left + scrollLeft - 50;
     const newTop = event.clientY - containerRect.top + scrollTop - 50;
 

    const newDiv = (
      <div
        className="draggable"
        style={{
          position: 'absolute',
          left: newLeft + 'px',
          top: newTop + 'px',
          width: '100px',
          height: '100px',
          border: '1px solid black',
          cursor: 'pointer',
        }}
        key={student.id}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <img src={student.img} alt={`${student.name} ${student.surname}`} style={{ width: '50px', height: '50px', display: 'block', margin: '0 auto' }} />
        <span style={{ display: 'block', textAlign: 'center' }}>{student.name}</span>
        <span style={{ display: 'block', textAlign: 'center' }}>{student.surname}</span>
      </div>
    );

    setDivs((prevDivs) => [...prevDivs, newDiv]);
  };

  const divStyle = {position: 'fixed', 
  top: '0', 
  left: '0', 
  right: '0', 
  bottom: '0',
  userSelect: 'none',
  zIndex: 1,
}

  return (
    <>
      <Header />
      <p className="subtitle">{t('descriptionSeatingGenerator')}</p>
      <div 
            onClick={handleClick} 
            style={divStyle}
        >
            {divs}
        </div>
    </>
  );
}
