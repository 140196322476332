const starterPositives = [
  {
    id: 0,
    name: "Ser honest",
    emoji: "⚖️",
    points: 1,
  },
  {
    id: 1,
    name: "Treure notable alt",
    emoji: "⬆️",
    points: 1,
  },
  {
    id: 2,
    name: "Fer l'encàrrec molt bé",
    emoji: "🎩",
    points: 1,
  },
  {
    id: 3,
    name: "Participa",
    emoji: "🙋‍♂️",
    points: 1,
  },
  {
    id: 4,
    name: "Pergamí del destí",
    emoji: "📜",
    points: 1,
  },
  {
    id: 5,
    name: "S'esforça molt",
    emoji: "💪",
    points: 1,
  },
  {
    id: 6,
    name: "Ajuda als companys",
    emoji: "🧑‍🤝‍🧑",
    points: 1,
  },
  {
    id: 7,
    name: "Treure un excel·lent",
    emoji: "💯",
    points: 3,
  },
];

export default starterPositives;
